import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
  darken,
} from '@mui/material';
import Delete from '../assets/red-delete.svg';
import { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { downloadRequest, fileRequest, request } from '../API/Request';
import { AppContext } from '../App';
import Clear from '../assets/clear3.svg';
import ClearDialog from '../assets/clear.svg';
import Edit from '../assets/edit4.svg';
import FileUpload from '../assets/file3.svg';
import Search from '../assets/search.svg';
import Teeth from '../assets/teeth.svg';
import HumanTeethTwo from '../components/HumanTeethTwo';
import HumanTeeth from './HumanTeeth';
import RecordTextField from './RecordTextField';
import DeleteConfirmation from './DeleteConfirmation';

const PlanRecordForm = ({ file_id }) => {
  const { setLoading, currentUser, xs, sm } = useContext(AppContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const { t, i18n } = useTranslation();
  const textDirection = i18n.language === 'ar' ? 'rtl' : 'ltr';
  const inputFile = useRef(null);
  const [edit, setEdit] = useState(false);
  const [examinationsDialog, setExaminationsDialog] = useState(false);
  const [allDialog, setAllDialog] = useState(false);
  const [jawsMenu, setJawsMenu] = useState(true);
  const [proceduresMenu, setProceduresMenu] = useState(sm ? true : false);
  const [planRecord, setPlanRecord] = useState({});
  const [teeth, setTeeth] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [examinations, setExaminations] = useState([]);
  const [surgeriesDescriptions, setSurgeriesDescriptions] = useState([]);
  const [description, setDescription] = useState('');
  const [searchText, setSearchText] = useState('');
  const [surgeries, setSurgeries] = useState([]);
  const [clickedSurgery, setClickedSurgery] = useState('');
  const [icon, setIcon] = useState('');
  const [jawsData, setJawsData] = useState([]);
  const [deleteJawDialog, setDeleteJawDialog] = useState(false);
  const [jawId, setJawId] = useState('');

  const jaws = [
    'Mandible',
    'Maxilla',
    'Both',
    'Mandible (child)',
    'Maxilla (child)',
    'Both (child)',
  ];
  const [selectedJaw, setSelectedJaw] = useState('');

  const getSurgeriesDescriptions = async () => {
    const response = await request('get-surgeries-descriptions', 'GET');
    setSurgeriesDescriptions(response.data);
  };
  const getExaminations = async () => {
    const response = await request(`get-examinations/${file_id}`, 'GET');
    setExaminations(response.data);
  };
  const getPlanRecord = async () => {
    setLoading(true);
    const response = await request(`get-plan-record/${file_id}`, 'GET');
    setPlanRecord(response.data);
    reset({
      ...response.data,
      birth_date: response.data?.birth_date.slice(0, 10),
    });
    const toothObjects = [];
    for (let toothIndex = 1; toothIndex <= 52; toothIndex++) {
      const description = response.data
        ? response.data[`tooth_${toothIndex}_description`]
        : '';
      for (let colorIndex = 1; colorIndex <= 5; colorIndex++) {
        const toothID = `tooth${toothIndex}_${colorIndex}`;
        const color = response.data
          ? response.data[`tooth_${toothIndex}_color_${colorIndex}`]
          : '';
        const icon = response.data
          ? response.data[`tooth_${toothIndex}_icon_${colorIndex}`]
          : '';
        toothObjects.push({
          toothID,
          color,
          icon,
          description,
        });
      }
    }
    setTeeth(toothObjects);
    setLoading(false);
  };
  const getJaws = async () => {
    const response = await request(
      `get-plan-jaws/${planRecord?.plan_record_id}`,
      'GET'
    );
    if (response?.success) {
      setJawsData(response?.data);
    }
  };
  useEffect(() => {
    getPlanRecord();
    getExaminations();
    getSurgeriesDescriptions();
  }, [reset]);

  useEffect(() => {
    if (planRecord?.plan_record_id) {
      getJaws();
    }
  }, [planRecord]);

  const addRecord = async (data) => {
    const requestData = {
      ...data,
      file_id: file_id,
    };
    teeth.forEach((tooth, index) => {
      const toothIndex = Math.floor(index / 5) + 1;
      const colorIndex = (index % 5) + 1;
      requestData[`tooth_${toothIndex}_color_${colorIndex}`] = tooth.color;
      requestData[`tooth_${toothIndex}_icon_${colorIndex}`] = tooth.icon;
      requestData[`tooth_${toothIndex}_description`] = tooth.description;
    });
    await request('add-plan-record', 'POST', requestData);
    localStorage.setItem('historical_data', JSON.stringify(data));
    setEdit(false);
    getPlanRecord();
  };
  const confirmEdit = async (data) => {
    const requestData = {
      ...data,
      file_id: file_id,
      plan_record_id: planRecord.plan_record_id,
    };
    teeth.forEach((tooth, index) => {
      const toothIndex = Math.floor(index / 5) + 1;
      const colorIndex = (index % 5) + 1;
      requestData[`tooth_${toothIndex}_color_${colorIndex}`] = tooth.color;
      requestData[`tooth_${toothIndex}_icon_${colorIndex}`] = tooth.icon;
      requestData[`tooth_${toothIndex}_description`] = tooth.description;
    });
    await request('update-plan-record', 'PUT', requestData);
    localStorage.setItem('historical_data', JSON.stringify(data));
    setEdit(false);
  };
  const onFileClicked = () => {
    inputFile.current.click();
  };
  const onFileSelected = async (event) => {
    const maxSizeInBytes = 100 * 1024 * 1024;
    if (event.target.files[0]?.size < maxSizeInBytes) {
      const formData = new FormData();
      formData.append('examination_name', event.target.files[0]?.name);
      formData.append('file_id', file_id);
      formData.append('folder', 'Examinations');
      formData.append('file', event.target.files[0]);
      await fileRequest(`upload/${currentUser?.doctor_id}`, 'POST', formData);
      setErrorMessage('');
      getExaminations();
    } else {
      setErrorMessage('File size is too large (maximum allowed is 100 MB)');
    }
  };
  const downloadFile = async (path) => {
    await downloadRequest(
      `get-examination/${currentUser.doctor_id}/${path.split('/').pop()}`,
      'GET'
    );
  };
  const deleteFile = async (id) => {
    await request(`delete-examination/${id}`, 'DELETE');
    getExaminations();
  };
  const getSurgeryDescription = (surgeryShort) => {
    const surgery = surgeriesDescriptions.find(
      (surgery) => surgery.surgery_short === surgeryShort
    );
    return surgery
      ? textDirection === 'rtl'
        ? surgery?.ar_desc
        : surgery?.en_desc
      : '';
  };
  const applyJaw = async () => {
    // const requestData = {
    //     ...planRecord,
    //     "file_id": file_id, "plan_record_id": planRecord?.plan_record_id,
    // };
    // let start = 0;
    // let end = 0;
    // if (selectedJaw === 'Maxilla') {
    //     start = 1;
    //     end = 16;
    // } else if (selectedJaw === 'Mandible') {
    //     start = 17;
    //     end = 32;
    // } else if (selectedJaw === 'Both') {
    //     start = 1;
    //     end = 32;
    // } else if (selectedJaw === 'Maxilla (child)') {
    //     start = 33;
    //     end = 42;
    // } else if (selectedJaw === 'Mandible (child)') {
    //     start = 43;
    //     end = 52;
    // } else if (selectedJaw === 'Both (child)') {
    //     start = 33;
    //     end = 52;
    // }
    // let flag = false;
    // teeth.forEach((tooth, index) => {
    //     const toothIndex = Math.floor(index / 5) + 1;
    //     const colorIndex = (index % 5) + 1;
    //     if (toothIndex >= start && toothIndex <= end) {
    //         if (!tooth?.icon && !flag) {
    //             requestData[`tooth_${toothIndex}_color_${colorIndex}`] = 'green';
    //             requestData[`tooth_${toothIndex}_icon_${colorIndex}`] = icon;
    //             requestData[`tooth_${toothIndex}_description`] = tooth?.description ? tooth?.description + ', ' + description : description;
    //             flag = true;
    //         }
    //     }
    //     if (!flag) {
    //         requestData[`tooth_${toothIndex}_color_${colorIndex}`] = tooth.color;
    //         requestData[`tooth_${toothIndex}_icon_${colorIndex}`] = tooth.icon;
    //         requestData[`tooth_${toothIndex}_description`] = tooth.description;
    //     }
    //     if (colorIndex === 5) flag = false;
    // });
    // await request('update-plan-record', 'PUT', requestData);
    await request('add-plan-jaw', 'POST', {
      jawName: selectedJaw,
      icon,
      description,
      recordId: planRecord?.plan_record_id,
    });

    setAllDialog(false);
    window.location.reload();
  };
  const getSurgeries = async () => {
    const response = await request('get-jaw-surgeries', 'GET');
    if (response.success) {
      setSurgeries(response.data);
    }
  };
  const handleSurgerySelect = (surgery_short) => {
    if (surgery_short === clickedSurgery) {
      setIcon('');
      setClickedSurgery('');
    } else {
      setIcon(surgery_short);
      setClickedSurgery(surgery_short);
    }
  };
  const filterSurgeries = (surgery) => {
    return surgery.surgery_short
      .toLowerCase()
      .includes(searchText.toLowerCase());
  };
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };
  const deleteJaw = async () => {
    const response = await request(`delete-plan-jaw/${jawId}`, 'DELETE');
    if (response?.success) {
      getJaws();
    }
  };

  return (
    <>
      <DeleteConfirmation
        onClick={deleteJaw}
        open={deleteJawDialog}
        setOpen={setDeleteJawDialog}
        text={'This row will be deleted, This cannot be undone!'}
      />
      <input
        type='file'
        onChange={onFileSelected}
        ref={inputFile}
        style={{ display: 'none' }}
      />
      <Dialog
        sx={{
          '.MuiDialog-paper': {
            maxWidth: 800,
            width: !sm ? 300 : 800,
            borderRadius: 5,
          },
        }}
        open={allDialog}
        onClose={() => {
          setSearchText('');
          setDescription('');
          setClickedSurgery('');
          setSurgeries([]);
          setSelectedJaw('');
          setAllDialog(false);
        }}
        dir={textDirection}
      >
        <DialogContent sx={{ borderBottom: '2px solid #F7F7F7' }}>
          <Grid
            container
            direction={sm ? 'row' : 'column-reverse'}
            spacing={!sm ? 1 : 0}
          >
            <Grid item xs={3.5}>
              <TextareaAutosize
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                value={description}
                style={{
                  backgroundColor: '#F7F7F7',
                  width: '90%',
                  fontSize: 20,
                  color: '#b491cb',
                  border: '2px solid #7c5295',
                  borderRadius: 10,
                  padding: 10,
                  height: sm ? 320 : 270,
                  resize: 'none',
                  outline: 'none',
                }}
                placeholder={'Notes...'}
              />
              {/* {!sm && (
                <Typography color={'white'}>
                  Note: This will apply to all the selected Procedures with
                  green color (as done)
                </Typography>
              )} */}
            </Grid>
            <Grid
              container
              item
              xs={8.5}
              direction={'column'}
              paddingInlineStart={sm && 2}
              spacing={!sm ? 1 : 0}
              justifyContent={'space-between'}
            >
              <Grid
                container
                item
                xs={10}
                rowSpacing={!sm ? 2 : 0}
                direction={sm ? 'row' : 'column'}
              >
                <Grid
                  container
                  item
                  xs={6}
                  spacing={!sm ? 1 : 0}
                  display={'flex'}
                  justifyContent={'center'}
                >
                  <Button
                    disableElevation
                    onClick={() => {
                      if (!sm) setJawsMenu(!jawsMenu);
                    }}
                    sx={{
                      height: 40,
                      width: '90%',
                      fontSize: 15,
                      padding: 0,
                      color: '#b491cb',
                      fontWeight: 'bold',
                      border: '2px solid #7c5295',
                      borderRadius: 2,
                      backgroundColor: '#F7F7F7',
                      '&:hover': {
                        backgroundColor: '#F7F7F7',
                      },
                    }}
                    variant='contained'
                  >
                    Choose Jaw
                  </Button>
                  {jawsMenu && (
                    <div
                      style={{
                        marginTop: !sm && 4,
                        overflowY: 'auto',
                        backgroundColor: '#F7F7F7',
                        width: sm ? '90%' : '85%',
                        height: '240px',
                        overflowX: 'hidden',
                        borderRadius: 8,
                        scrollbarWidth: 'none',
                        border: '2px solid #7c5295',
                      }}
                    >
                      <Grid container item xs={12}>
                        {jaws?.map((jaw, index) => (
                          <Grid
                            key={index}
                            item
                            xs={12}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Button
                              disableElevation
                              onClick={() => {
                                setSelectedJaw(jaw);
                                if (!selectedJaw) getSurgeries();
                              }}
                              sx={{
                                marginY: 0.35,
                                backgroundColor:
                                  jaw === selectedJaw
                                    ? darken('#b491cb', 0.5)
                                    : '#b491cb',
                                color: '#F7F7F7',
                                fontSize: 15,
                                fontWeight: 'bold',
                                border: '2px solid #7c5295',
                                width: '97%',
                                borderRadius: 2,
                                '&:hover': {
                                  backgroundColor:
                                    jaw === selectedJaw
                                      ? darken('#b491cb', 0.5)
                                      : '#b491cb',
                                },
                              }}
                              variant='contained'
                            >
                              {jaw}
                            </Button>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  )}
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  spacing={!sm ? 1 : 0}
                  display={'flex'}
                  justifyContent={'center'}
                >
                  <Button
                    disableElevation
                    onClick={() => {
                      if (!sm) setProceduresMenu(!proceduresMenu);
                    }}
                    sx={{
                      height: 40,
                      width: '90%',
                      fontSize: 15,
                      padding: 0,
                      color: '#b491cb',
                      fontWeight: 'bold',
                      border: '2px solid #7c5295',
                      borderRadius: 2,
                      backgroundColor: '#F7F7F7',
                      '&:hover': {
                        backgroundColor: '#F7F7F7',
                      },
                    }}
                    variant='contained'
                  >
                    Procedure
                  </Button>
                  {proceduresMenu && (
                    <TextField
                      onChange={handleSearchChange}
                      value={searchText}
                      sx={{
                        width: '90%',
                        backgroundColor: '#F7F7F7',
                        borderRadius: 2,
                        input: {
                          '&::placeholder': {
                            color: '#993cd6',
                            fontWeight: 'bold',
                          },
                          color: '#B491CB',
                        },
                        fieldset: { borderWidth: 2, borderColor: '#7C5295' },
                        '&:focus-within fieldset': {
                          borderWidth: 2,
                          borderColor: '#7C5295 !important',
                        },
                        '&:hover fieldset': {
                          borderWidth: 2,
                          borderColor: '#7C5295 !important',
                        },
                        '.MuiInputBase-root': { borderRadius: 2 },
                      }}
                      placeholder={sm ? 'Search Procedures...' : 'Search..'}
                      margin='dense'
                      size='small'
                    />
                  )}
                  {proceduresMenu && (
                    <div
                      style={{
                        display:
                          surgeries?.filter(filterSurgeries)?.length === 0 &&
                          'flex',
                        justifyContent:
                          surgeries?.filter(filterSurgeries)?.length === 0 &&
                          'center',
                        alignItems:
                          surgeries?.filter(filterSurgeries)?.length === 0 &&
                          'center',
                        overflowY: 'auto',
                        backgroundColor: '#F7F7F7',
                        width: sm ? '90%' : '85%',
                        height: '200px',
                        overflowX: 'hidden',
                        borderRadius: 8,
                        scrollbarWidth: 'none',
                        border: '2px solid #7c5295',
                      }}
                    >
                      {surgeries?.filter(filterSurgeries)?.length > 0 ? (
                        <Grid container item xs={12}>
                          {surgeries
                            ?.filter(filterSurgeries)
                            ?.map((surgery, index) => (
                              <Grid
                                key={index}
                                item
                                xs={12}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <Tooltip
                                  title={
                                    i18n.language === 'ar'
                                      ? surgery.ar_desc
                                      : surgery.en_desc
                                  }
                                >
                                  <Button
                                    disableElevation
                                    onClick={() => {
                                      handleSurgerySelect(
                                        surgery.surgery_short
                                      );
                                    }}
                                    sx={{
                                      marginY: 0.44,
                                      backgroundColor:
                                        surgery.surgery_short === clickedSurgery
                                          ? darken(surgery.color, 0.5)
                                          : surgery.color,
                                      color: '#F7F7F7',
                                      fontSize: 15,
                                      fontWeight: 'bold',
                                      border: '2px solid #7c5295',
                                      width: '95%',
                                      borderRadius: 2,
                                      '&:hover': {
                                        backgroundColor:
                                          surgery.surgery_short ===
                                          clickedSurgery
                                            ? darken(surgery.color, 0.5)
                                            : surgery.color,
                                      },
                                    }}
                                    variant='contained'
                                  >
                                    {surgery.surgery_short}
                                  </Button>
                                </Tooltip>
                              </Grid>
                            ))}
                        </Grid>
                      ) : (
                        <h3 style={{ color: '#b491cb' }}>Procedures</h3>
                      )}
                    </div>
                  )}
                </Grid>
              </Grid>
              {/* {sm && (
                <Grid item xs={1}>
                  <Typography color={'white'}>
                    Note: This will apply to all the selected Procedures with
                    green color (as done)
                  </Typography>
                </Grid>
              )} */}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginX: sm ? 2 : 0,
          }}
        >
          <IconButton
            onClick={() => {
              setSearchText('');
              setDescription('');
              setClickedSurgery('');
              setSurgeries([]);
              setSelectedJaw('');
            }}
          >
            <img src={ClearDialog} alt='clear' width={30} />
          </IconButton>
          <Box>
            <Button
              disableElevation
              onClick={() => {
                setSearchText('');
                setDescription('');
                setClickedSurgery('');
                setSurgeries([]);
                setSelectedJaw('');
                setAllDialog(false);
              }}
              sx={{
                height: 40,
                width: 100,
                fontSize: 15,
                color: '#b491cb',
                fontWeight: 'bold',
                border: '2px solid #7c5295',
                borderRadius: 2,
                backgroundColor: '#F7F7F7',
                '&:hover': {
                  backgroundColor: '#F7F7F7',
                },
              }}
              variant='contained'
            >
              Cancel
            </Button>
            <Button
              disableElevation
              onClick={() => {
                applyJaw();
              }}
              sx={{
                height: 40,
                width: 100,
                fontSize: 15,
                color: '#b491cb',
                fontWeight: 'bold',
                border: '2px solid #7c5295',
                borderRadius: 2,
                marginInlineStart: 1,
                backgroundColor: '#F7F7F7',
                '&:hover': {
                  backgroundColor: '#F7F7F7',
                },
              }}
              variant='contained'
            >
              Apply
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        sx={{
          '.MuiDialog-paper': { backgroundColor: '#F7F7F7', borderRadius: 5 },
        }}
        open={examinationsDialog}
        onClose={() => {
          setExaminationsDialog(false);
          setErrorMessage('');
        }}
      >
        <DialogTitle
          color={'#36454F'}
          display={'flex'}
          justifyContent={'center'}
        >
          {planRecord?.plan_record_id && (
            <h3 style={{ margin: 10, textAlign: 'center' }}>
              {t('Patient Examinations')}
            </h3>
          )}
        </DialogTitle>
        <DialogContent
          sx={{ borderBottom: 2, borderColor: '#B2BEB5', textAlign: 'center' }}
        >
          {planRecord?.plan_record_id ? (
            <>
              <Box display={'flex'} flexDirection={'column'}>
                <Box
                  display={'flex'}
                  justifyContent={
                    planRecord?.plan_record_id ? 'space-evenly' : 'center'
                  }
                  alignItems={'center'}
                >
                  <Button
                    onClick={() => {
                      onFileClicked();
                    }}
                    sx={{
                      display: 'flex',
                      flexDirection: xs ? 'row' : 'column',
                      backgroundColor: '#E2E2E2',
                      boxShadow: 0,
                      border: 2,
                      borderColor: '#C9C9C9',
                      padding: 1.5,
                      borderRadius: 2,
                      '&:hover': { backgroundColor: '#C9C9C9', boxShadow: 0 },
                    }}
                    variant='contained'
                  >
                    <img src={FileUpload} alt='File Upload' width={15} />
                    {xs ? (
                      <h3 style={{ margin: 0, marginInlineStart: 10 }}>
                        {t('Upload File')}
                      </h3>
                    ) : (
                      <h4 style={{ margin: 0, marginInlineStart: 10 }}>
                        {t('Upload File')}
                      </h4>
                    )}
                  </Button>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    border={2}
                    borderColor={'#C9C9C9'}
                    height={150}
                    marginX={xs ? 3 : 0.5}
                    width={250}
                    overflow={'auto'}
                    sx={{ scrollbarWidth: 'none' }}
                    padding={0.5}
                  >
                    {examinations?.map((examination) => (
                      <Tooltip title={examination?.examination_name}>
                        <Box
                          sx={{ '&:hover': { cursor: 'pointer' } }}
                          display={'flex'}
                          textAlign={'center'}
                          marginBottom={0.5}
                          height={25}
                          backgroundColor={'#E2E2E2'}
                          border={2}
                          borderRadius={2}
                          borderColor={'#C9C9C9'}
                          paddingX={1}
                        >
                          <IconButton
                            onClick={() => {
                              deleteFile(examination?.examination_id);
                            }}
                          >
                            <img src={Clear} alt='Delete' width={15} />
                          </IconButton>
                          <Box
                            onClick={() => {
                              downloadFile(examination?.path);
                            }}
                            display={'flex'}
                            justifyContent={'center'}
                            width={'100%'}
                            overflow={'hidden'}
                          >
                            <h4 style={{ margin: 0 }}>
                              {examination?.examination_name}
                            </h4>
                          </Box>
                        </Box>
                      </Tooltip>
                    ))}
                  </Box>
                </Box>
                {errorMessage && (
                  <h4 style={{ marginBottom: 0 }}>{errorMessage}</h4>
                )}
              </Box>
            </>
          ) : (
            <h3>You have to add data to plan record and save first</h3>
          )}
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            sx={{
              backgroundColor: '#B491CB',
              boxShadow: 'none',
              '&:hover': { backgroundColor: '#b583d6', boxShadow: 'none' },
              fontWeight: 'bold',
            }}
            onClick={() => {
              setExaminationsDialog(false);
              setErrorMessage('');
            }}
            color='success'
            variant='contained'
          >
            {t('Done')}
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        paddingX={3}
        paddingY={10}
        dir={textDirection}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
      >
        <Grid
          container
          padding={3}
          backgroundColor={'#f0eded'}
          boxShadow={10}
          borderRadius={2}
        >
          <Grid
            item
            xs={12}
            display={'flex'}
            justifyContent={'space-between'}
            paddingX={3}
          >
            <h1>Plan</h1>
            {edit ? (
              <Box display={'flex'} flexDirection={xs ? 'row' : 'column'}>
                <Button
                  onClick={() => {
                    setEdit(false);
                    reset();
                  }}
                  sx={{
                    width: xs ? 250 : 100,
                    height: 50,
                    marginTop: 3,
                    marginInlineEnd: xs && 2,
                  }}
                  color='error'
                  variant='contained'
                >
                  {t('Cancel')}
                </Button>
                <Button
                  onClick={
                    planRecord?.file_id
                      ? handleSubmit(confirmEdit)
                      : handleSubmit(addRecord)
                  }
                  sx={{ width: xs ? 250 : 100, height: 50, marginTop: 3 }}
                  color='success'
                  variant='contained'
                >
                  {t('Confirm')}
                </Button>
              </Box>
            ) : (
              <IconButton
                onClick={() => {
                  setEdit(true);
                }}
              >
                <img src={Edit} alt='Edit' width={50} />
              </IconButton>
            )}
          </Grid>
          <>
            <Grid
              item
              xl={4}
              sm={6}
              xs={12}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <RecordTextField
                readOnly={!edit}
                register={register}
                name={'patient_name'}
                errors={errors}
                placeholder={t('Patient name')}
                autoFocus={true}
              />
            </Grid>
            <Grid
              item
              xl={4}
              sm={6}
              xs={12}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <RecordTextField
                readOnly={!edit}
                register={register}
                name={'national_number'}
                errors={errors}
                placeholder={t('National number')}
              />
            </Grid>
            <Grid
              item
              xl={4}
              sm={6}
              xs={12}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <RecordTextField
                readOnly={!edit}
                register={register}
                name={'birth_date'}
                errors={errors}
                placeholder={t('Birth date')}
                type='date'
              />
            </Grid>
            <Grid
              item
              xl={4}
              sm={6}
              xs={12}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <RecordTextField
                readOnly={!edit}
                register={register}
                name={'email'}
                errors={errors}
                placeholder={t('Email')}
              />
            </Grid>
            <Grid
              item
              xl={4}
              sm={6}
              xs={12}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <RecordTextField
                readOnly={!edit}
                register={register}
                name={'phone_number'}
                errors={errors}
                placeholder={t('Phone number')}
              />
            </Grid>
            <Grid
              item
              xl={4}
              sm={6}
              xs={12}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <RecordTextField
                readOnly={!edit}
                register={register}
                name={'phone_number2'}
                errors={errors}
                placeholder={t('Phone number 2 (Optional)')}
              />
            </Grid>
            <Grid
              item
              xs={12}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <RecordTextField
                readOnly={!edit}
                register={register}
                name={'medical_history'}
                errors={errors}
                placeholder={t('Medical history')}
              />
            </Grid>
            <Grid
              item
              xs={12}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <RecordTextField
                readOnly={!edit}
                register={register}
                name={'notes'}
                errors={errors}
                placeholder={t('Notes')}
              />
            </Grid>
            <Grid
              item
              xs={12}
              display={'flex'}
              flexDirection={xs ? 'row' : 'column'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Button
                onClick={() => {
                  setExaminationsDialog(true);
                }}
                style={{
                  marginTop: xs ? 30 : 20,
                  backgroundColor: '#B491CB',
                  color: '#ffffff',
                  height: 80,
                  width: 250,
                  fontSize: 18,
                  fontWeight: 'bold',
                }}
                endIcon={
                  <img
                    src={Search}
                    alt='Search'
                    width={25}
                    style={{ marginInlineStart: 5 }}
                  />
                }
                variant='contained'
              >
                Show Examinations
              </Button>
              <Button
                onClick={() => {
                  planRecord?.plan_record_id
                    ? setAllDialog(true)
                    : setExaminationsDialog(true);
                }}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: xs ? 30 : 20,
                  marginLeft: xs && 10,
                  backgroundColor: '#B491CB',
                  color: '#ffffff',
                  height: 80,
                  width: 250,
                  fontSize: 18,
                  fontWeight: 'bold',
                }}
                startIcon={
                  <img
                    src={Teeth}
                    alt='Search'
                    width={25}
                    style={{ marginInlineStart: 5 }}
                  />
                }
                variant='contained'
              >
                Mandible/Maxilla
              </Button>
            </Grid>
            {currentUser.speciality === 'Dentist' && teeth?.length > 0 && (
              <Box
                display={'flex'}
                flexDirection={xs ? 'row' : 'column'}
                overflow={'auto'}
                marginTop={xs && -20}
                marginBottom={xs && -20}
                width={'100%'}
              >
                <Grid
                  item
                  xl={12}
                  sm={6}
                  xs={12}
                  display={'flex'}
                  flexDirection={xs ? 'row' : 'column'}
                  overflow={'hidden'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <p
                    style={{
                      marginInlineEnd: 10,
                      fontWeight: 'bold',
                      fontSize: 20,
                    }}
                  >
                    {t('Teeth status')}:
                  </p>
                  <HumanTeethTwo
                    onEdit={edit}
                    teeth={teeth}
                    setTeeth={setTeeth}
                    record={'plan'}
                  />
                </Grid>
                <Grid
                  item
                  xl={12}
                  sm={6}
                  xs={12}
                  display={'flex'}
                  flexDirection={xs ? 'row' : 'column'}
                  overflow={'hidden'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <p style={{ fontWeight: 'bold', fontSize: 20 }}>
                    {t('Teeth status')}:
                  </p>
                  <HumanTeeth
                    onEdit={edit}
                    teeth={teeth}
                    setTeeth={setTeeth}
                    record={'plan'}
                  />
                </Grid>
              </Box>
            )}
            <Grid
              item
              xs={12}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <h1>{t('Teeth Summary')}</h1>
              <TableContainer
                component={Paper}
                style={{
                  margin: '16px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          color: '#fff',
                          fontWeight: 'bold',
                          border: '1px solid #ddd',
                          borderRadius: 50,
                          textAlign: 'center',
                        }}
                      >
                        Jaw Name
                      </TableCell>
                      <TableCell
                        style={{
                          color: '#fff',
                          fontWeight: 'bold',
                          border: '1px solid #ddd',
                          borderRadius: 50,
                          textAlign: 'center',
                        }}
                      >
                        Procedure
                      </TableCell>
                      <TableCell
                        style={{
                          color: '#fff',
                          fontWeight: 'bold',
                          border: '1px solid #ddd',
                          borderRadius: 50,
                          textAlign: 'center',
                        }}
                      >
                        Notes
                      </TableCell>
                      <TableCell
                        style={{
                          color: '#fff',
                          fontWeight: 'bold',
                          border: '1px solid #ddd',
                          borderRadius: 50,
                          textAlign: 'center',
                        }}
                      >
                        Delete
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {jawsData?.map((jaw, index) => (
                      <TableRow key={index}>
                        <TableCell
                          style={{
                            backgroundColor: '#fff',
                            color: '#000',
                            border: '1px solid #ddd',
                            textAlign: 'center',
                          }}
                        >
                          {jaw.jaw_name}
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: '#fff',
                            color: '#000',
                            border: '1px solid #ddd',
                            textAlign: 'center',
                          }}
                        >
                          {jaw.procedure}
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: '#fff',
                            color: '#000',
                            border: '1px solid #ddd',
                            textAlign: 'center',
                          }}
                        >
                          {jaw.notes}
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: '#fff',
                            color: '#000',
                            border: '1px solid #ddd',
                            textAlign: 'center',
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              setDeleteJawDialog(true);
                              setJawId(jaw._id);
                            }}
                          >
                            <img src={Delete} alt='Delete' width={30} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <TableContainer
                component={Paper}
                style={{
                  margin: '16px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          color: '#fff',
                          fontWeight: 'bold',
                          border: '1px solid #ddd',
                          borderRadius: 50,
                          textAlign: 'center',
                        }}
                      >
                        Tooth Number
                      </TableCell>
                      {Array.from({ length: 6 }, (_, rowIndex) => (
                        <TableCell
                          key={rowIndex}
                          style={{
                            color: '#fff',
                            fontWeight: 'bold',
                            border: '1px solid #ddd',
                            borderRadius: 50,
                            textAlign: 'center',
                          }}
                        >
                          {rowIndex <= 4
                            ? t(`Procedure ${rowIndex + 1}`)
                            : 'Notes'}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.from({ length: teeth.length / 5 }, (_, colIndex) => {
                      if (
                        teeth[colIndex * 5]?.icon ||
                        teeth[colIndex * 5 + 1]?.icon ||
                        teeth[colIndex * 5 + 2]?.icon ||
                        teeth[colIndex * 5 + 3]?.icon ||
                        teeth[colIndex * 5 + 4]?.icon
                      )
                        return (
                          <TableRow key={colIndex}>
                            <TableCell
                              key={'tooth-number'}
                              style={{
                                backgroundColor: '#fff',
                                color: '#000',
                                border: '1px solid #ddd',
                                textAlign: 'center',
                              }}
                            >
                              {colIndex + 1}
                            </TableCell>
                            {Array.from({ length: 6 }, (_, rowIndex) => {
                              const toothIndex = colIndex * 5 + rowIndex;
                              return (
                                <Tooltip
                                  title={
                                    rowIndex <= 4
                                      ? getSurgeryDescription(
                                          teeth[toothIndex]?.icon
                                        )
                                      : ''
                                  }
                                >
                                  <TableCell
                                    key={rowIndex}
                                    style={{
                                      backgroundColor: '#fff',
                                      color: '#000',
                                      border: '1px solid #ddd',
                                      textAlign: 'center',
                                    }}
                                  >
                                    <div>
                                      {rowIndex <= 4 ? (
                                        <strong>
                                          {teeth[toothIndex]?.icon
                                            ? teeth[toothIndex]?.icon
                                            : ''}
                                        </strong>
                                      ) : (
                                        <strong>
                                          {teeth[toothIndex - 1]?.description}
                                        </strong>
                                      )}
                                    </div>
                                  </TableCell>
                                </Tooltip>
                              );
                            })}
                          </TableRow>
                        );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>
        </Grid>
      </Box>
    </>
  );
};

export default PlanRecordForm;
