import { Avatar, Box, Button, Divider, Grid, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { AppContext } from "../App"
import Dollar from '../assets/dollar.svg'
import Location from '../assets/location.svg'
import Medkit from '../assets/medkit.svg'
import { useNavigate } from "react-router-dom"
import { request } from "../API/Request"

const ResultCard = ({ name, city, fees, speciality, doctor_id, profilePicture }) => {

    const { t, i18n } = useTranslation()
    const textDirection = i18n.language === 'ar' ? 'rtl' : 'ltr'
    const { xs, sm, md, setLoading } = useContext(AppContext)
    const navigate = useNavigate()
    const [tabs, setTabs] = useState([])
    const [appointments1, setAppointments1] = useState([])
    const [appointments2, setAppointments2] = useState([])
    const [appointments3, setAppointments3] = useState([])
    const [day3, setDay3] = useState('')

    const getTabs = async () => {
        const response = await request(`get-tabs/${doctor_id}`, 'GET')
        setTabs(response.data)
    }
    const getAppointments = async (day, setAppointments) => {
        const response = await request(`get-available-appointments/${day}/${tabs[0]?.tab_id}`, 'GET')
        if (response.success) {
            setAppointments(response.data)
        }
    }
    useEffect(() => {
        getTabs()
        if (tabs.length) {
            const currentDate1 = new Date();
            currentDate1.setDate(currentDate1.getDate());
            const options1 = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            const result1 = currentDate1.toLocaleDateString(undefined, options1);
            getAppointments(result1, setAppointments1)
            const currentDate2 = new Date();
            currentDate2.setDate(currentDate2.getDate() + 1);
            const options2 = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            const result2 = currentDate2.toLocaleDateString(undefined, options2);
            getAppointments(result2, setAppointments2)
            const currentDate3 = new Date();
            currentDate3.setDate(currentDate3.getDate() + 2);
            const options3 = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            const result3 = currentDate3.toLocaleDateString(undefined, options3);
            setDay3(result3.split(',')[0])
            getAppointments(result3, setAppointments3)
        }
    }, [tabs.length])

    return (
        <Grid container width={md ? '70%' : '80%'} border={'1px solid #42159D'} borderRadius={5} boxShadow={'rgb(0, 0, 0) 0 1px 10px'} padding={3} marginBottom={md ? 2 : 4} textAlign={"start"} rowSpacing={!md ? 2 : 0}>
            <Grid item container lg={6} display={"flex"} justifyContent={!sm && 'center'}>
                <Avatar src={profilePicture} sx={{ width: 200, height: 200, marginBottom: !xs && 5 }} />
                <Grid item xs={12} sm={6} display={"flex"} flexDirection={"column"}>
                    <Typography onClick={() => { navigate(`/doctorInfo?doctor_id=${doctor_id}`) }} sx={{ '&:hover': { cursor: 'pointer', textDecoration: 'underline' } }} fontSize={20} fontWeight={'bold'} marginBottom={2} marginLeft={textDirection === 'ltr' && 2} marginRight={textDirection === 'rtl' && 2}>{name}</Typography>
                    <Box display={"flex"} marginLeft={textDirection === 'ltr' && 2} marginRight={textDirection === 'rtl' && 2} marginBottom={2}>
                        <img src={Location} alt="City" width={15} />
                        <Typography marginLeft={textDirection === 'ltr' && 2} marginRight={textDirection === 'rtl' && 2}>{city}</Typography>
                    </Box>
                    <Box display={"flex"} marginLeft={textDirection === 'ltr' && 2} marginRight={textDirection === 'rtl' && 2} marginBottom={2}>
                        <img src={Dollar} alt="Dollar" width={15} />
                        <Typography marginLeft={textDirection === 'ltr' && 2} marginRight={textDirection === 'rtl' && 2}>{fees}</Typography>
                    </Box>
                    <Box display={"flex"} marginLeft={textDirection === 'ltr' && 2} marginRight={textDirection === 'rtl' && 2} marginBottom={2}>
                        <img src={Medkit} alt="Medkit" width={15} />
                        <Typography marginLeft={textDirection === 'ltr' && 2} marginRight={textDirection === 'rtl' && 2}>{speciality}</Typography>
                    </Box>
                </Grid>
            </Grid>
            <Grid item width={'100%'} lg={6} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={'space-evenly'}>
                <Grid container width={'100%'} display={"flex"} justifyContent={'space-between'} alignItems={"center"} border={2} borderColor={'background.paper'} borderRadius={5} marginBottom={2}>
                    <Grid item xs={4}>
                        <h3 style={{ borderInlineEnd: '2px solid', width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 15 }}>{t('Today')}</h3>
                        <Divider />
                    </Grid>
                    <Grid item xs={4}>
                        <h3 style={{ borderInlineEnd: '2px solid', width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 15 }}>{t('Tomorrow')}</h3>
                        <Divider />
                    </Grid>
                    <Grid item xs={4}>
                        <h3 style={{ width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 15 }}>{t(day3)}</h3>
                        <Divider />
                    </Grid>
                    <Grid item xs={4}>
                        <h3 style={{ borderInlineEnd: '2px solid', width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 15 }}>
                            {appointments1[0]?.start_time ? (
                                <>
                                    {appointments1[0].start_time.slice(0, 5)}{' '}
                                    {parseInt(appointments1[0].start_time.slice(0, 5).split(':')[0]) >= 12 ? t('PM') : t('AM')}
                                </>
                            ) : '—'}
                        </h3>
                    </Grid>
                    <Grid item xs={4}>
                        <h3 style={{ borderInlineEnd: '2px solid', width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 15 }}>
                            {appointments2[0]?.start_time ? (
                                <>
                                    {appointments2[0].start_time.slice(0, 5)}{' '}
                                    {parseInt(appointments2[0].start_time.slice(0, 5).split(':')[0]) >= 12 ? t('PM') : t('AM')}
                                </>
                            ) : '—'}
                        </h3>
                    </Grid>
                    <Grid item xs={4}>
                        <h3 style={{ width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 15 }}>
                            {appointments3[0]?.start_time ? (
                                <>
                                    {appointments3[0].start_time.slice(0, 5)}{' '}
                                    {parseInt(appointments3[0].start_time.slice(0, 5).split(':')[0]) >= 12 ? t('PM') : t('AM')}
                                </>
                            ) : '—'}
                        </h3>
                    </Grid>
                    <Grid item xs={4}>
                        <h3 style={{ borderInlineEnd: '2px solid', width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 15 }}>
                            {appointments1[1]?.start_time ? (
                                <>
                                    {appointments1[1].start_time.slice(0, 5)}{' '}
                                    {parseInt(appointments1[1].start_time.slice(0, 5).split(':')[0]) >= 12 ? t('PM') : t('AM')}
                                </>
                            ) : '—'}
                        </h3>
                    </Grid>
                    <Grid item xs={4}>
                        <h3 style={{ borderInlineEnd: '2px solid', width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 15 }}>
                            {appointments2[1]?.start_time ? (
                                <>
                                    {appointments2[1].start_time.slice(0, 5)}{' '}
                                    {parseInt(appointments2[1].start_time.slice(0, 5).split(':')[0]) >= 12 ? t('PM') : t('AM')}
                                </>
                            ) : '—'}
                        </h3>
                    </Grid>
                    <Grid item xs={4}>
                        <h3 style={{ width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 15 }}>
                            {appointments3[1]?.start_time ? (
                                <>
                                    {appointments3[1].start_time.slice(0, 5)}{' '}
                                    {parseInt(appointments3[1].start_time.slice(0, 5).split(':')[0]) >= 12 ? t('PM') : t('AM')}
                                </>
                            ) : '—'}
                        </h3>
                    </Grid>
                    <Grid item xs={4}>
                        <h3 style={{ borderInlineEnd: '2px solid', width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 15 }}>
                            {appointments1[2]?.start_time ? (
                                <>
                                    {appointments1[2].start_time.slice(0, 5)}{' '}
                                    {parseInt(appointments1[2].start_time.slice(0, 5).split(':')[0]) >= 12 ? t('PM') : t('AM')}
                                </>
                            ) : '—'}
                        </h3>
                    </Grid>
                    <Grid item xs={4}>
                        <h3 style={{ borderInlineEnd: '2px solid', width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 15 }}>
                            {appointments2[2]?.start_time ? (
                                <>
                                    {appointments2[2].start_time.slice(0, 5)}{' '}
                                    {parseInt(appointments2[2].start_time.slice(0, 5).split(':')[0]) >= 12 ? t('PM') : t('AM')}
                                </>
                            ) : '—'}
                        </h3>
                    </Grid>
                    <Grid item xs={4}>
                        <h3 style={{ width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 15 }}>
                            {appointments3[2]?.start_time ? (
                                <>
                                    {appointments3[2].start_time.slice(0, 5)}{' '}
                                    {parseInt(appointments3[2].start_time.slice(0, 5).split(':')[0]) >= 12 ? t('PM') : t('AM')}
                                </>
                            ) : '—'}
                        </h3>
                    </Grid>
                    <Grid item xs={4}>
                        <h3 style={{ borderInlineEnd: '2px solid', width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 15 }}>
                            {appointments1[3]?.start_time ? (
                                <>
                                    {appointments1[3].start_time.slice(0, 5)}{' '}
                                    {parseInt(appointments1[3].start_time.slice(0, 5).split(':')[0]) >= 12 ? t('PM') : t('AM')}
                                </>
                            ) : '—'}
                        </h3>
                    </Grid>
                    <Grid item xs={4}>
                        <h3 style={{ borderInlineEnd: '2px solid', width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 15 }}>
                            {appointments2[3]?.start_time ? (
                                <>
                                    {appointments2[3].start_time.slice(0, 5)}{' '}
                                    {parseInt(appointments2[3].start_time.slice(0, 5).split(':')[0]) >= 12 ? t('PM') : t('AM')}
                                </>
                            ) : '—'}
                        </h3>
                    </Grid>
                    <Grid item xs={4}>
                        <h3 style={{ width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 15 }}>
                            {appointments3[3]?.start_time ? (
                                <>
                                    {appointments3[3].start_time.slice(0, 5)}{' '}
                                    {parseInt(appointments3[3].start_time.slice(0, 5).split(':')[0]) >= 12 ? t('PM') : t('AM')}
                                </>
                            ) : '—'}
                        </h3>
                    </Grid>
                    <Grid item xs={4}>
                        <h3 style={{ borderInlineEnd: '2px solid', width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 15 }}>
                            {appointments1[4]?.start_time ? (
                                <>
                                    {appointments1[4].start_time.slice(0, 5)}{' '}
                                    {parseInt(appointments1[4].start_time.slice(0, 5).split(':')[0]) >= 12 ? t('PM') : t('AM')}
                                </>
                            ) : '—'}
                        </h3>
                    </Grid>
                    <Grid item xs={4}>
                        <h3 style={{ borderInlineEnd: '2px solid', width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 15 }}>
                            {appointments2[4]?.start_time ? (
                                <>
                                    {appointments2[4].start_time.slice(0, 5)}{' '}
                                    {parseInt(appointments2[4].start_time.slice(0, 5).split(':')[0]) >= 12 ? t('PM') : t('AM')}
                                </>
                            ) : '—'}
                        </h3>
                    </Grid>
                    <Grid item xs={4}>
                        <h3 style={{ width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 15 }}>
                            {appointments3[4]?.start_time ? (
                                <>
                                    {appointments3[4].start_time.slice(0, 5)}{' '}
                                    {parseInt(appointments3[4].start_time.slice(0, 5).split(':')[0]) >= 12 ? t('PM') : t('AM')}
                                </>
                            ) : '—'}
                        </h3>
                    </Grid>
                </Grid>
                <Button sx={{ backgroundColor: '#B491CB', boxShadow: 'none', color: 'white', '&:hover': { backgroundColor: '#b583d6', boxShadow: 'none' }, fontWeight: 'bold' }} onClick={() => { navigate(`/doctorInfo?doctor_id=${doctor_id}`) }} variant="contained">{t('Book')}</Button>
            </Grid>
        </Grid>
    )
}

export default ResultCard