import { Box, Button, Divider, IconButton, TextField } from "@mui/material"
import { Fragment, useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { request } from "../API/Request"
import Add from '../assets/add.svg'
import Pharmacy from "../components/Pharmacy"
import { AppContext } from "../App"

const Pharmacies = () => {

    const { setLoading } = useContext(AppContext)
    const { register, handleSubmit, formState: { errors }, reset } = useForm()
    const [pharmacies, setPharmacies] = useState([])
    const [limit, setLimit] = useState(1)
    const [searchText, setSearchText] = useState('')

    const getPharmacies = async () => {
        setLoading(true)
        const response = await request(`get-pharmacies-for-admin/${limit}`, 'GET')
        setPharmacies(response.pharmacies)
        setLoading(false)
    }
    useEffect(() => {
        document.title = 'Admin | Pharmacies'
        getPharmacies()
    }, [limit])

    const addPharmacy = async (data) => {
        setLoading(true)
        await request('add-pharmacy', 'POST',
            {
                "pname": data.pname,
                "plocation_url": data.plocation_url,
                "pimage_url": data.pimage_url,
                "open_time": data.open_time,
                "close_time": data.close_time,
                "fopen_time": data.fopen_time,
                "fclose_time": data.fclose_time,
                "phone_number": data.phone_number
            }
        )
        getPharmacies()
        reset()
        setLoading(false)
    }
    const filterPharmacies = (pharmacy) => {
        return pharmacy.pname.toLowerCase().includes(searchText.toLowerCase());
    };
    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    return (
        <Box backgroundColor={'#F7F7F7'} textAlign={'center'} display={"flex"} flexDirection={"column"} alignItems={"center"} paddingBottom={5}>
            <Box backgroundColor={'background.paper'} width={'100%'} display={"flex"} flexDirection={'column'} alignItems={'center'} paddingY={5} marginBottom={5}>
                <h1 style={{ color: "white" }}>Pharmacies</h1>
                <TextField onChange={handleSearchChange} sx={{ backgroundColor: '#F7F7F7', borderRadius: 2, input: { "&::placeholder": { color: "#993cd6", fontWeight: 'bold' }, color: '#B491CB' }, 'fieldset': { borderWidth: 2, borderColor: '#7C5295' }, '&:focus-within fieldset': { borderWidth: 2, borderColor: '#7C5295 !important' }, '&:hover fieldset': { borderWidth: 2, borderColor: '#7C5295 !important' }, '.MuiInputBase-root': { borderRadius: 2 } }} placeholder="Search Pharmacies..." />
            </Box>
            <form onSubmit={handleSubmit(addPharmacy)} style={{ width: '95%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.pname ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.pname ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('pname', { required: true })} error={!!errors.pname} placeholder={'Pharmacy Name'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.plocation_url ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.plocation_url ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('plocation_url', { required: true })} error={!!errors.plocation_url} placeholder={'Location URL'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.pimage_url ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.pimage_url ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('pimage_url', { required: true })} error={!!errors.pimage_url} placeholder={'Image URL'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.open_time ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.open_time ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='time' {...register('open_time', { required: true })} error={!!errors.open_time} placeholder={'Open Time'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.close_time ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.close_time ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='time' {...register('close_time', { required: true })} error={!!errors.close_time} placeholder={'Close Time'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.fopen_time ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.fopen_time ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='time' {...register('fopen_time', { required: true })} error={!!errors.fopen_time} placeholder={'Friday open Time'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.fclose_time ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.fclose_time ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='time' {...register('fclose_time', { required: true })} error={!!errors.fclose_time} placeholder={'Friday close Time'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.phone_number ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.phone_number ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('phone_number', { required: true })} error={!!errors.phone_number} placeholder={'Phone Number'} />
                <IconButton type="submit">
                    <img src={Add} alt="Add" width={30} />
                </IconButton>
            </form>
            {pharmacies?.filter(filterPharmacies)?.map((pharmacy, index) => {
                return <Fragment key={index}>
                    <Divider sx={{ width: '95%', marginTop: 2 }} />
                    <Pharmacy pharmacy={pharmacy} getPharmacies={getPharmacies} />
                </Fragment>
            })}
            <Divider sx={{ width: '95%', marginY: 2 }} />
            <Button sx={{ backgroundColor: '#B491CB', boxShadow: 'none', color: 'white', '&:hover': { backgroundColor: '#b583d6', boxShadow: 'none' }, fontWeight: 'bold' }} onClick={() => { setLimit(limit + 1) }} variant="contained">Load More</Button>
        </Box>
    )
}

export default Pharmacies