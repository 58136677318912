import { Box, Checkbox, Grid, IconButton, TextField } from "@mui/material"
import { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import Edit from '../assets/edit4.svg'
import Remove from '../assets/remove.svg'
import Confirm from '../assets/confirm.svg'
import Cancel from '../assets/cancel.svg'
import Collapse from '../assets/collapse.svg'
import Expand from '../assets/expand.svg'
import DeleteConfirmation from "./DeleteConfirmation"
import InventoryTextField from "./InventoryTextField"
import { request } from "../API/Request"
import { AppContext } from "../App"

const Product = ({ id, index, name, quantity, seller_name, seller_number, price, setSelected, deleteProduct, getProducts, total }) => {

    const { sm } = useContext(AppContext)
    const { register, handleSubmit, formState: { errors }, reset } = useForm()
    const [deleteConfirm, setDeleteConfirm] = useState(false)
    const [edit, setEdit] = useState(false)
    const [hidden, setHidden] = useState(true)
    const [productQuantity, setProductQuantity] = useState(quantity)
    const [productPrice, setProductPrice] = useState(price)
    const [totalPrice, setTotalPrice] = useState(total)

    const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked
        if (isChecked) {
            setSelected((prevSelected) => [...prevSelected, id])
        } else {
            setSelected((prevSelected) => prevSelected.filter((selectedId) => selectedId !== id))
        }
    }
    const updateProduct = async (data) => {
        await request('update-product', 'PUT', {
            ...data,
            product_id: id,
            product_quantity: productQuantity,
            product_price: productPrice,
        })
        setEdit(false)
        getProducts()
    }
    const deleteThisProduct = () => {
        deleteProduct(id)
    }
    const handleProductQuantityChange = (newValue) => {
        const newProductQuantity = parseFloat(newValue) || 0
        setProductQuantity(newProductQuantity)
        updateTotalPrice(newProductQuantity, productPrice)
    }
    const handleProductPriceChange = (newValue) => {
        const newProductPrice = parseFloat(newValue) || 0
        setProductPrice(newProductPrice)
        updateTotalPrice(productQuantity, newProductPrice)
    }
    const updateTotalPrice = (newProductQuantity, newProductPrice) => {
        const totalPrice = newProductQuantity * newProductPrice
        setTotalPrice(totalPrice)
    }

    return (
        <>
            <DeleteConfirmation open={deleteConfirm} setOpen={setDeleteConfirm} onClick={deleteThisProduct} text={'Deleting wil permanently remove this inventory item from the system. This cannot be undone!'} />
            <Grid container item xs={12} display={"flex"} alignItems={"center"} justifyContent={!sm && 'space-between'} marginY={1}>
                {sm ? <>
                    <Grid item xs={1.333333333333333} textAlign={"center"}>
                        <Checkbox onChange={handleCheckboxChange} sx={{
                            '&.Mui-checked': {
                                color: 'black',
                            },
                            '&.MuiCheckbox-root': {
                                color: 'black',
                                '&.Mui-checked': {
                                    color: 'black',
                                },
                            },
                        }} color="secondary" />
                    </Grid>
                    <Grid item xs={1.333333333333333} textAlign={"center"}>{index}</Grid>
                    <Grid item xs={1.333333333333333} textAlign={"center"}>{edit ? <InventoryTextField name={'product_name'} placeholder={'Product Name'} errors={errors} register={register} defaultVlaue={name} autoFocus={true} /> : name}</Grid>
                    <Grid item xs={1.333333333333333} textAlign={"center"}>{edit ? <TextField onChange={(event) => handleProductQuantityChange(event.target.value)} InputProps={{ inputProps: { type: 'number', pattern: '[0-9]*' }, style: { background: 'white' } }} sx={{ '&:focus-within fieldset': { borderColor: 'black !important' }, '& .MuiFormHelperText-root': { color: 'black' }, '& .MuiOutlinedInput-notchedOutline': { borderColor: 'black' }, marginX: 1 }} placeholder={'Product Quantity'} defaultValue={quantity} /> : quantity}</Grid>
                    <Grid item xs={1.333333333333333} textAlign={"center"}>{edit ? <InventoryTextField name={'seller_name'} placeholder={'Seller Name'} errors={errors} register={register} defaultVlaue={seller_name} /> : seller_name}</Grid>
                    <Grid item xs={1.333333333333333} textAlign={"center"}>{edit ? <InventoryTextField name={'seller_number'} placeholder={'Seller Number'} errors={errors} register={register} defaultVlaue={seller_number} /> : seller_number}</Grid>
                    <Grid item xs={1.333333333333333} textAlign={"center"}>{edit ? <TextField onChange={(event) => handleProductPriceChange(event.target.value)} InputProps={{ inputProps: { type: 'number', pattern: '[0-9]*' }, style: { background: 'white' } }} sx={{ '&:focus-within fieldset': { borderColor: 'black !important', }, '& .MuiFormHelperText-root': { color: 'black' }, '& .MuiOutlinedInput-notchedOutline': { borderColor: 'black' }, marginX: 1 }} placeholder={'Product Price'} defaultValue={price} /> : `${price}JD`}</Grid>
                    <Grid item xs={1.333333333333333} textAlign={"center"}>{total}JD</Grid>
                    <Grid item xs={1.333333333333333} textAlign={"center"}>
                        <Box display={"flex"} justifyContent={"center"}>
                            {!edit && <IconButton onClick={() => { setDeleteConfirm(true) }}>
                                <img src={Remove} alt="Remove" width={25} />
                            </IconButton>}
                            {!edit ? <IconButton onClick={() => { setEdit(true) }}>
                                <img src={Edit} alt="Edit" width={30} />
                            </IconButton> : <>
                                <IconButton onClick={() => { setEdit(false) }}>
                                    <img src={Cancel} alt="Cancel" width={30} />
                                </IconButton>
                                <IconButton onClick={handleSubmit(updateProduct)}>
                                    <img src={Confirm} alt="Confirm" width={30} />
                                </IconButton>
                            </>}
                        </Box>
                    </Grid>
                </> : <>
                    <Box display={'flex'} alignItems={'center'}>
                        <Checkbox onChange={handleCheckboxChange} sx={{
                            '&.Mui-checked': {
                                color: 'black',
                            },
                            '&.MuiCheckbox-root': {
                                color: 'black',
                                '&.Mui-checked': {
                                    color: 'black',
                                },
                            },
                        }} color="secondary" />
                        <h4>{index}</h4>
                        <Box display={"flex"} flexDirection={'column'} justifyContent={'center'} marginInlineStart={2}>
                            {edit ? <InventoryTextField name={'product_name'} placeholder={'Product Name'} errors={errors} register={register} defaultVlaue={name} autoFocus={true} /> : <h4 style={{ margin: 0 }}>{name}</h4>}
                            {edit ? <TextField onChange={(event) => handleProductQuantityChange(event.target.value)} InputProps={{ inputProps: { type: 'number', pattern: '[0-9]*' }, style: { background: 'white' } }} sx={{ '&:focus-within fieldset': { borderColor: 'black !important' }, '& .MuiFormHelperText-root': { color: 'black' }, '& .MuiOutlinedInput-notchedOutline': { borderColor: 'black' }, marginX: 1 }} placeholder={'Product Quantity'} defaultValue={quantity} /> : <h4 style={{ margin: 0 }}>{quantity} Pieces</h4>}
                        </Box>
                    </Box>
                    <Box>
                        {!edit && <IconButton onClick={() => { setDeleteConfirm(true) }}>
                            <img src={Remove} alt="Remove" width={25} />
                        </IconButton>}
                        {!edit ? <IconButton onClick={() => { setEdit(true) }}>
                            <img src={Edit} alt="Edit" width={30} />
                        </IconButton> : <>
                            <IconButton onClick={() => { setEdit(false) }}>
                                <img src={Cancel} alt="Cancel" width={30} />
                            </IconButton>
                            <IconButton onClick={handleSubmit(updateProduct)}>
                                <img src={Confirm} alt="Confirm" width={30} />
                            </IconButton>
                        </>}
                        {!hidden ? <IconButton onClick={() => { setHidden(true) }}>
                            <img src={Collapse} alt="Collapse" width={30} />
                        </IconButton> : <IconButton onClick={() => { setHidden(false) }}>
                            <img src={Expand} alt="Expand" width={30} />
                        </IconButton>}
                    </Box>
                    {!hidden && <Grid item container xs={12}>
                        <Grid item xs={3} textAlign={'center'}>Price</Grid>
                        <Grid item xs={3} textAlign={'center'}>Total Price</Grid>
                        <Grid item xs={3} textAlign={'center'}>Seller Name</Grid>
                        <Grid item xs={3} textAlign={'center'}>Seller Number</Grid>
                    </Grid>}
                    {!hidden && <Grid item container xs={12}>
                        <Grid item xs={3} textAlign={'center'}>{edit ? <TextField onChange={(event) => handleProductPriceChange(event.target.value)} InputProps={{ inputProps: { type: 'number', pattern: '[0-9]*' }, style: { background: 'white' } }} sx={{ '&:focus-within fieldset': { borderColor: 'black !important', }, '& .MuiFormHelperText-root': { color: 'black' }, '& .MuiOutlinedInput-notchedOutline': { borderColor: 'black' }, marginX: 1 }} placeholder={'Product Price'} defaultValue={price} /> : `${price}JD`}</Grid>
                        <Grid item xs={3} textAlign={'center'}>{total}</Grid>
                        <Grid item xs={3} textAlign={'center'}>{edit ? <InventoryTextField name={'seller_name'} placeholder={'Seller Name'} errors={errors} register={register} defaultVlaue={seller_name} /> : seller_name}</Grid>
                        <Grid item xs={3} textAlign={'center'}>{edit ? <InventoryTextField name={'seller_number'} placeholder={'Seller Number'} errors={errors} register={register} defaultVlaue={seller_number} /> : seller_number}</Grid>
                    </Grid>}
                </>}
            </Grid>
        </>
    )
}

export default Product