import {
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { downloadRequest, fileRequest, request } from '../API/Request';
import { AppContext } from '../App';
import Cancel from '../assets/cancel2.svg';
import Confirm from '../assets/confirm2.svg';
import Edit from '../assets/edit2.svg';
import EditPicture from '../assets/edit3.svg';
import Remove from '../assets/remove2.svg';
import ChangePasswordDialog from '../components/ChangePasswordDialog';
import ScheduleTable from '../components/ScheduleTable';

const DoctorInfo = () => {
  const {
    currentUser,
    setLoading,
    role,
    xs,
    sm,
    md,
    profilePicture,
    setProfilePicture,
  } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const {
    register: bioRegister,
    handleSubmit: handleBioSubmit,
    formState: { errors: bioErrors },
    reset: resetBioForm,
  } = useForm();
  const {
    register: clinicRegister,
    handleSubmit: handleClinicSubmit,
    formState: { errors: clinicErrors },
    reset: resetClinicForm,
  } = useForm();
  const {
    register: insuranceRegister,
    handleSubmit: handleInsuranceSubmit,
    formState: { errors: insuranceErrors },
    reset: resetInsuranceForm,
  } = useForm();
  const textDirection = i18n.language === 'ar' ? 'rtl' : 'ltr';
  const [clinics, setClinics] = useState([]);
  const [insurances, setInsurances] = useState([]);
  const [doctor, setDoctor] = useState({});
  const [editBio, setEditBio] = useState(false);
  const [bookDialog, setBookDialog] = useState(false);
  const location = useLocation();
  const inputFile = useRef(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [tabs, setTabs] = useState([]);
  const [tab, setTab] = useState(0);
  const [changePasswordDialog, setChangePasswordDialog] = useState(false);

  const getClinicsAndHospitals = async () => {
    const response = await request(
      `get-clinics_and_hospitals/${
        role === 'doctor' ? currentUser.doctor_id : doctor.doctor_id
      }`,
      'GET'
    );
    setClinics(response);
  };
  const getInsurances = async () => {
    const response = await request(
      `get-insurances/${
        role === 'doctor' ? currentUser.doctor_id : doctor.doctor_id
      }`,
      'GET'
    );
    setInsurances(response);
  };
  const getTabs = async () => {
    const response = await request(
      `get-tabs/${
        role === 'doctor' ? currentUser.doctor_id : doctor.doctor_id
      }`,
      'GET'
    );
    setTabs(response.data);
  };
  const getDoctor = async (id) => {
    setLoading(true);
    const response = await request(`get-doctor-by-id/${id}`, 'GET');
    if (response.success) {
      setDoctor(response.data);
      const profilePicture = await downloadRequest(
        `get-profile-picture/${response.data.doctor_id}`,
        'GET',
        true
      );
      if (!profilePicture.error) {
        setProfilePicture(window.URL.createObjectURL(profilePicture));
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    document.title =
      role === 'doctor'
        ? `Weqayah | Profile`
        : `Weqayah | ${doctor.first_name} ${doctor.last_name}`;
    if (role !== 'doctor') {
      if (role && doctor.doctor_id) {
        getClinicsAndHospitals();
        getInsurances();
        getTabs();
      }
    } else {
      if (role && currentUser.doctor_id) {
        getClinicsAndHospitals();
        getInsurances();
        getTabs();
      }
    }
    window.scrollTo(0, 0);
  }, [role, doctor, currentUser]);
  useEffect(() => {
    if (role !== 'doctor') {
      const query = new URLSearchParams(location.search);
      const doctor_id = query.get('doctor_id');
      if (doctor_id) {
        getDoctor(doctor_id);
      }
    }
  }, [role]);

  const addBio = async (data) => {
    setLoading(true);
    currentUser.biography = data.bio;
    await request('update-doctor', 'PUT', currentUser);
    resetBioForm();
    setEditBio(false);
    setLoading(false);
  };
  const addClinic = async (data) => {
    setLoading(true);
    await request('add-clinic_and_hospital', 'POST', {
      ch_name: data.clinic,
      doctor_id: currentUser.doctor_id,
    });
    resetClinicForm();
    getClinicsAndHospitals();
    setLoading(false);
  };
  const deleteClinic = async (id) => {
    setLoading(true);
    await request(`delete-clinic_and_hospital/${id}`, 'DELETE');
    getClinicsAndHospitals();
    setLoading(false);
  };
  const addInsurance = async (data) => {
    setLoading(true);
    await request('add-insurance', 'POST', {
      insurance_name: data.insurance,
      doctor_id: currentUser.doctor_id,
    });
    resetInsuranceForm();
    getInsurances();
    setLoading(false);
  };
  const deleteInsurance = async (id) => {
    setLoading(true);
    await request(`delete-insurance/${id}`, 'DELETE');
    getInsurances();
    setLoading(false);
  };
  const onAvatarClicked = () => {
    inputFile.current.click();
  };
  const onFileSelected = async (event) => {
    const selectedFile = event.target.files[0];
    const formData = new FormData();
    formData.append('folder', 'Profile Picture');
    formData.append('file', selectedFile);
    await fileRequest(`upload/${currentUser.doctor_id}`, 'POST', formData);
    const profilePicture = await downloadRequest(
      `get-profile-picture/${currentUser.doctor_id}`,
      'GET',
      true
    );
    setProfilePicture(window.URL.createObjectURL(profilePicture));
  };
  const next = () => {
    if (sm) setPageIndex(pageIndex + 5);
    else if (xs) setPageIndex(pageIndex + 4);
    else setPageIndex(pageIndex + 3);
  };
  const prev = () => {
    if (sm) {
      if (pageIndex > 4) {
        setPageIndex(pageIndex - 5);
      }
    } else if (xs) {
      if (pageIndex > 3) {
        setPageIndex(pageIndex - 4);
      }
    } else {
      if (pageIndex > 2) {
        setPageIndex(pageIndex - 3);
      }
    }
  };

  return (
    <>
      <Box
        backgroundColor={'background.paper'}
        color={'white'}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        paddingY={5}
        position={'relative'}
        dir={textDirection}
      >
        <h1 style={{ fontSize: 40 }}>{t('Profile')}</h1>
        <input
          type='file'
          onChange={onFileSelected}
          ref={inputFile}
          style={{ display: 'none' }}
        />
        <Grid container>
          {role === 'doctor' ? (
            <Grid
              item
              xs={12}
              md={3}
              display={'flex'}
              justifyContent={'center'}
            >
              <Badge
                overlap='circular'
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                  <IconButton
                    onClick={() => {
                      onAvatarClicked();
                    }}
                    sx={{
                      backgroundColor: 'black',
                      '&:hover': {
                        backgroundColor: '#42159D',
                      },
                      padding: 1.3,
                    }}
                  >
                    <img src={EditPicture} alt='Edit' width={20} />
                  </IconButton>
                }
              >
                <Avatar
                  onClick={() => {
                    window.open(profilePicture, '_blank');
                  }}
                  src={profilePicture}
                  sx={{
                    '&:hover': { cursor: 'pointer' },
                    border: 3,
                    width: md ? 250 : 200,
                    height: md ? 250 : 200,
                  }}
                />
              </Badge>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              md={3}
              display={'flex'}
              justifyContent={'center'}
            >
              <Avatar
                onClick={() => {
                  window.open(profilePicture, '_blank');
                }}
                src={profilePicture}
                sx={{
                  '&:hover': { cursor: 'pointer' },
                  border: 3,
                  width: md ? 250 : 200,
                  height: md ? 250 : 200,
                }}
              />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={4.5}
            display={'flex'}
            flexDirection={'column'}
            alignItems={sm ? 'flex-start' : 'center'}
            fontSize={md ? 25 : 20}
          >
            <h3>
              Name:{' '}
              {role === 'doctor' ? currentUser?.first_name : doctor?.first_name}{' '}
              {role === 'doctor' ? currentUser?.last_name : doctor?.last_name}
            </h3>
            <h3>
              {t('Speciality')}:{' '}
              {role === 'doctor'
                ? t(currentUser?.speciality)
                : t(doctor?.speciality)}
            </h3>
          </Grid>
          <Grid
            item
            xs={12}
            md={4.5}
            display={'flex'}
            flexDirection={'column'}
            alignItems={sm ? 'flex-start' : 'center'}
            fontSize={md ? 25 : 20}
          >
            <h3>
              {t('Clinic Name')}:{' '}
              {role === 'doctor'
                ? t(currentUser?.clinic_name)
                : t(doctor?.clinic_name)}
            </h3>
            <h3>
              {t('Location')}:
              <Link
                to={
                  role === 'doctor'
                    ? currentUser?.clinic_location
                    : doctor?.clinic_location
                }
                target='_blank'
                style={{ color: 'white', marginInlineStart: 5 }}
              >
                {role === 'doctor'
                  ? currentUser?.location_text
                  : doctor?.location_text}
              </Link>
            </h3>
          </Grid>
          {role === 'doctor' && (
            <Grid item xs={12} display={'flex'} justifyContent={'center'}>
              <Button
                onClick={() => {
                  setChangePasswordDialog(true);
                }}
                disableRipple
                disableElevation
                sx={{
                  color: '#B491CB',
                  borderRadius: 5,
                  '&:hover': { backgroundColor: 'white' },
                }}
                variant='contained'
              >
                Reset Password
              </Button>
              <ChangePasswordDialog
                open={changePasswordDialog}
                setOpen={setChangePasswordDialog}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        paddingY={5}
        paddingX={3}
        dir={textDirection}
      >
        {role !== 'doctor' && (
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            paddingX={sm ? 10 : 0}
          >
            <h1 style={{ fontSize: !sm && 20 }}>{t('Schedule')}</h1>
            <FormControl sx={{ width: sm ? 200 : 150 }}>
              <InputLabel
                style={{ color: 'black' }}
                id='demo-simple-select-label'
              >
                Tab
              </InputLabel>
              <Select
                color='secondary'
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={tab}
                label='Tab'
                onChange={(tab) => {
                  setTab(tab.target.value);
                }}
              >
                {tabs?.map((tab, index) => (
                  <MenuItem sx={{ color: 'white' }} key={index} value={index}>
                    {tab?.tab_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
        {role !== 'doctor' && (
          <Box
            display={'flex'}
            alignItems={'center'}
            marginTop={5}
            sx={{ paddingInlineStart: xs ? (pageIndex > 2 ? 0 : 5.4) : 0 }}
          >
            {pageIndex > 2 && xs && (
              <IconButton style={{ height: 50 }} onClick={prev}>
                {<h1>&lt;</h1>}
              </IconButton>
            )}
            {tabs?.length ? (
              <ScheduleTable
                doctor_id={doctor.doctor_id}
                pageIndex={pageIndex}
                tabIndex={tab}
                tabs={tabs}
                bookDialog={bookDialog}
                setBookDialog={setBookDialog}
              />
            ) : (
              <CircularProgress />
            )}
            {xs && (
              <IconButton style={{ height: 50 }} onClick={next}>
                {<h1>&gt;</h1>}
              </IconButton>
            )}
          </Box>
        )}
        {!xs && (
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-evenly'}
            height={60}
          >
            {pageIndex > 2 && (
              <IconButton onClick={prev}>{<h1>&lt;</h1>}</IconButton>
            )}
            <IconButton onClick={next}>{<h1>&gt;</h1>}</IconButton>
          </Box>
        )}
        <Box display={'flex'} justifyContent={'center'}>
          {role !== 'doctor' && (
            <Button
              onClick={() => {
                setBookDialog(true);
              }}
              sx={{
                '&:hover': { backgroundColor: '#b386d1' },
                backgroundColor: '#B491CB',
                borderRadius: 15,
                width: 250,
                marginTop: xs && 4,
              }}
              color='error'
              variant='contained'
            >
              {t('Book')}
            </Button>
          )}
        </Box>
        {role !== 'doctor' ? (
          <Box paddingX={3}>
            <h2>{t('About the doctor')}:</h2>
            <h3>{doctor.biography}</h3>
            <h2 style={{ marginTop: 40 }}>{t('Clinics and hospitals')}</h2>
            <ul style={{ fontSize: 20, fontWeight: 'bold' }}>
              {clinics.map((clinic, index) => (
                <li key={index}>{clinic.ch_name}</li>
              ))}
            </ul>
            <h2 style={{ marginTop: 40 }}>{t('Insurances')}</h2>
            <ul style={{ fontSize: 20, fontWeight: 'bold' }}>
              {insurances.map((insurance, index) => (
                <li key={index}>{insurance.insurance_name}</li>
              ))}
            </ul>
          </Box>
        ) : (
          <Box paddingX={3}>
            <Box display={'flex'}>
              <h2>{t('Bio')}</h2>
              {!editBio ? (
                <IconButton
                  onClick={() => {
                    setEditBio(true);
                  }}
                >
                  <img
                    src={Edit}
                    alt='Edit'
                    width={35}
                    style={{ marginInlineStart: 5 }}
                  />
                </IconButton>
              ) : (
                <>
                  <IconButton
                    onClick={() => {
                      setEditBio(false);
                      resetBioForm();
                    }}
                  >
                    <img
                      src={Cancel}
                      alt='Cancel'
                      width={35}
                      style={{ marginInlineStart: 5 }}
                    />
                  </IconButton>
                  <IconButton onClick={handleBioSubmit(addBio)}>
                    <img
                      src={Confirm}
                      alt='Confirm'
                      width={35}
                      style={{ marginInlineStart: 5 }}
                    />
                  </IconButton>
                </>
              )}
            </Box>
            {currentUser?.biography && !editBio ? (
              <h3>{currentUser?.biography}</h3>
            ) : (
              <Box display={'flex'}>
                <TextField
                  sx={{
                    '&:focus-within fieldset': {
                      borderColor: 'black !important',
                    },
                    '& fieldset': {
                      borderColor: 'black !important',
                    },
                  }}
                  {...bioRegister('bio', { required: 'Please enter bio' })}
                  error={!!bioErrors.bio}
                  fullWidth
                  defaultValue={currentUser?.biography}
                />
                {!currentUser?.biography && (
                  <Button
                    onClick={handleBioSubmit(addBio)}
                    sx={{
                      backgroundColor: '#B491CB',
                      boxShadow: 'none',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#b583d6',
                        boxShadow: 'none',
                      },
                      marginInlineStart: 0.5,
                      width: 150,
                    }}
                    variant='contained'
                  >
                    {t('Add')}
                  </Button>
                )}
              </Box>
            )}
            {bioErrors.bio && (
              <Typography marginTop={2} color={'error.main'}>
                {bioErrors.bio?.message}
              </Typography>
            )}
            <h2 style={{ marginTop: 40 }}>{t('Clinics and hospitals')}</h2>
            <ul style={{ fontSize: 20, fontWeight: 'bold' }}>
              {clinics?.map((clinic, index) => (
                <li key={index}>
                  {clinic.ch_name}
                  <IconButton
                    onClick={() => {
                      deleteClinic(clinic.ch_id);
                    }}
                  >
                    <img
                      src={Remove}
                      alt='Remove'
                      width={20}
                      style={{ marginInlineStart: 5 }}
                    />
                  </IconButton>
                </li>
              ))}
            </ul>
            <Box display={'flex'}>
              <TextField
                sx={{
                  '&:focus-within fieldset': {
                    borderColor: 'black !important',
                  },
                  '& fieldset': {
                    borderColor: 'black !important',
                  },
                }}
                {...clinicRegister('clinic', {
                  required: 'Please enter a clinic or hospital',
                })}
                error={!!clinicErrors.clinic}
                fullWidth
              />
              <Button
                onClick={handleClinicSubmit(addClinic)}
                sx={{
                  marginInlineStart: 0.5,
                  width: 150,
                  backgroundColor: '#B491CB',
                  boxShadow: 'none',
                  color: 'white',
                  '&:hover': { backgroundColor: '#b583d6', boxShadow: 'none' },
                }}
                variant='contained'
              >
                {t('Add')}
              </Button>
            </Box>
            {clinicErrors.clinic && (
              <Typography marginTop={2} color={'error.main'}>
                {clinicErrors.clinic?.message}
              </Typography>
            )}
            <h2 style={{ marginTop: 40 }}>{t('Insurances')}</h2>
            <ul style={{ fontSize: 20, fontWeight: 'bold' }}>
              {insurances?.map((insurance, index) => (
                <li key={index}>
                  {insurance.insurance_name}
                  <IconButton
                    onClick={() => {
                      deleteInsurance(insurance.insurance_id);
                    }}
                  >
                    <img
                      src={Remove}
                      alt='Remove'
                      width={20}
                      style={{ marginInlineStart: 5 }}
                    />
                  </IconButton>
                </li>
              ))}
            </ul>
            <Box display={'flex'}>
              <TextField
                sx={{
                  '&:focus-within fieldset': {
                    borderColor: 'black !important',
                  },
                  '& fieldset': {
                    borderColor: 'black !important',
                  },
                }}
                {...insuranceRegister('insurance', {
                  required: 'Please enter a insurance or hospital',
                })}
                error={!!insuranceErrors.insurance}
                fullWidth
              />
              <Button
                onClick={handleInsuranceSubmit(addInsurance)}
                sx={{
                  marginInlineStart: 0.5,
                  width: 150,
                  backgroundColor: '#B491CB',
                  boxShadow: 'none',
                  color: 'white',
                  '&:hover': { backgroundColor: '#b583d6', boxShadow: 'none' },
                }}
                variant='contained'
              >
                {t('Add')}
              </Button>
            </Box>
            {insuranceErrors.insurance && (
              <Typography marginTop={2} color={'error.main'}>
                {insuranceErrors.insurance?.message}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default DoctorInfo;
