import { TextField } from '@mui/material';

const RecordTextField = ({
  name,
  type = 'text',
  register,
  errors,
  autoFocus,
  helperText,
  readOnly,
  placeholder,
}) => {
  return (
    <TextField
      InputProps={{
        readOnly: readOnly,
        style: { background: 'white' },
      }}
      sx={{
        '&:focus-within fieldset': {
          borderColor: 'black !important',
        },
        '& .MuiFormHelperText-root': {
          color: 'black',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'black',
        },
        '.MuiInputBase-input': {
          color: name === 'medical_history' && 'red !important',
        },
        marginX: 2,
        marginY: 1,
      }}
      type={type}
      defaultValue={
        name === 'visit_date' || name === 'birth_date'
          ? new Date().toISOString().substring(0, 10)
          : ''
      }
      fullWidth
      helperText={helperText}
      placeholder={placeholder}
      {...register(name, {
        required:
          name === 'patient_name' ||
          name === 'medical_history' ||
          name === 'phone_number',
      })}
      error={!!errors[name]}
      autoFocus={autoFocus}
    />
  );
};

export default RecordTextField;
