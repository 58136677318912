import { Box, Button, Grid } from "@mui/material";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../App";
import Doctor from '../assets/doctor3.png';
import Insurance from '../assets/insurance.png';
import Location from '../assets/location.png';
import Speciality from '../assets/speciality.png';
import SearchCard from "./SearchCard";

const SearchSection = () => {

    const { lg } = useContext(AppContext)
    const { register, handleSubmit } = useForm()
    const navigate = useNavigate()
    const location = useLocation()
    const query = new URLSearchParams(location.search)
    let fullName = query.get("fullName")
    let city = query.get("city")
    let insurance = query.get("insurance")
    let speciality = query.get("speciality")

    const search = async (data) => {
        const queryParams = [];
        if (data.fullName) queryParams.push(`fullName=${encodeURIComponent(data.fullName)}`);
        if (data.insurance) queryParams.push(`insurance=${encodeURIComponent(data.insurance)}`);
        if (data.speciality) queryParams.push(`speciality=${encodeURIComponent(data.speciality)}`);
        if (data.city) queryParams.push(`city=${encodeURIComponent(data.city)}`);
        navigate(`/findDoctor?${queryParams.join('&')}`, { replace: window.location.pathname === '/findDoctor' });
    }

    return (
        <Box backgroundColor={'background.paper'} color={'white'} textAlign={'center'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} paddingY={5}>
            <h1 style={{ fontSize: 30, marginBottom: -10 }}>Find a Doctor</h1>
            <p style={{ marginBottom: 60, fontSize: 17 }}>We guarantee that you get the best doctors in Weqayah</p>
            <form onSubmit={handleSubmit(search)} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Grid container columnSpacing={4} rowSpacing={4} width={lg ? '80%' : '90%'} marginBottom={8}>
                    <Grid item lg={3} md={6} xs={12}>
                        <SearchCard image={Doctor} searchBy={'Doctor\'s Name'} example={'Full Name'} register={register} name={'fullName'} defaultValue={fullName} />
                    </Grid>
                    <Grid item lg={3} md={6} xs={12}>
                        <SearchCard image={Insurance} searchBy={'Insurance'} example={'ex: MedNet'} register={register} name={'insurance'} defaultValue={insurance} />
                    </Grid>
                    <Grid item lg={3} md={6} xs={12}>
                        <SearchCard image={Speciality} searchBy={'Speciality'} example={'ex: Tooth Ache'} register={register} name={'speciality'} defaultValue={speciality} />
                    </Grid>
                    <Grid item lg={3} md={6} xs={12}>
                        <SearchCard image={Location} searchBy={'City'} example={'ex: Amman'} register={register} name={'city'} defaultValue={city} />
                    </Grid>
                </Grid>
                <Button type="submit" variant='contained' sx={{
                    '&:hover': {
                        backgroundColor: 'white',
                        boxShadow: 'rgb(0, 0, 0) 0 1px 30px',
                        transition: '0.3s'
                    },
                    transition: '0.3s', width: 200, height: 50, marginBottom: 3, fontSize: 16, fontWeight: 'bold', borderRadius: 5
                }}>Search</Button>
                <p>Note: you can fill any of the 4 boxes or all of them to see results</p>
            </form>
        </Box>
    )
}

export default SearchSection