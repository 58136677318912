import { useEffect } from 'react';
import './style.css';

const Loader = () => {
    useEffect(() => {
        document.body.classList.add('disable-scrolling');
        return () => {
            document.body.classList.remove('disable-scrolling');
        };
    }, []);

    return (
        <div className="center-loader-container">
            <span className="loader"></span>
        </div>
    );
};

export default Loader;
