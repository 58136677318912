import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Pagination,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { request } from '../API/Request';
import { AppContext } from '../App';
import Add from '../assets/add-file.svg';
import File from '../assets/file-info.svg';
import Filter from '../assets/filter.svg';
import Search from '../assets/search.svg';
import Edit from '../assets/edit4.svg';

const Files = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const { currentUser, setLoading, xs } = useContext(AppContext);
  const [page, setPage] = useState(
    JSON.parse(localStorage.getItem('page')) || 0
  );
  const [allowSecrets, setAllowSecrets] = useState(false);
  const [PINDialog, setPINDialog] = useState(false);
  const [newFileDialog, setNewFileDialog] = useState(false);
  const [PIN, setPIN] = useState('');
  const [PINError, setPINError] = useState('');
  const [files, setFiles] = useState([]);
  const [secretFiles, setSecretFiles] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [pagination, setPagination] = useState(1);
  const [results, setResults] = useState(0);
  const [edit, setEdit] = useState(false);
  const [fileId, setFileId] = useState('');

  const getSecretFiles = async () => {
    setLoading(true);
    const response = await request(`get-secret-files`, 'POST', {
      doctor_id: currentUser?.doctor_id,
      page: pagination,
    });
    setSecretFiles(response?.data);
    setResults(response?.totalCount);
    setLoading(false);
  };
  const getFiles = async (doctor_id) => {
    setLoading(true);
    const response = await request(`get-files`, 'POST', {
      doctor_id: doctor_id,
      page: pagination,
    });
    setFiles(response?.data);
    setResults(response?.totalCount);
    setLoading(false);
  };
  useEffect(() => {
    document.title = 'Weqayah | Files';
    if (currentUser?.doctor_id) {
      if (JSON.parse(localStorage.getItem('page')) === 1) {
        getSecretFiles();
        setAllowSecrets(true);
      } else {
        getFiles(currentUser?.doctor_id);
        setAllowSecrets(false);
        setSecretFiles([]);
      }
    }
  }, [currentUser, pagination]);

  const handleTabsChange = (event, newValue) => {
    if (newValue) {
      setPINDialog(true);
      setTimeout(() => {
        if (document.getElementsByClassName('MuiInputBase-input')[0]) {
          document.getElementsByClassName('MuiInputBase-input')[0].focus();
        }
      }, 0);
    } else {
      setAllowSecrets(false);
      setSecretFiles([]);
      getFiles(currentUser?.doctor_id);
      localStorage.setItem('page', JSON.stringify(0));
    }
    setPage(newValue);
  };
  const handlePINChange = (newValue) => {
    setPIN(newValue);
    if (newValue.length === 4) {
      if (newValue === currentUser?.pin) {
        getSecretFiles();
        setAllowSecrets(true);
        setPINDialog(false);
        setPIN('');
        setPINError('');
        localStorage.setItem('page', JSON.stringify(1));
      } else {
        setPINError(t('The entered PIN is not correct'));
        setPIN('');
        document.getElementsByClassName('MuiInputBase-input')[0].focus();
      }
    }
  };
  const validateChar = (value) => {
    return value >= '0' && value <= '9';
  };
  const addNewFile = async (data) => {
    setLoading(true);
    await request('add-file', 'POST', {
      file_name: data.fileName,
      doctor_id: currentUser?.doctor_id,
      total_price: 0,
      total_price_paid: 0,
      total_price_remaining: 0,
      secret: !!page,
    });
    setNewFileDialog(false);
    setLoading(false);
    if (!!page) getSecretFiles();
    else getFiles(currentUser?.doctor_id);
  };
  const updateFileName = async (data) => {
    setLoading(true);
    await request('update-file-name', 'PUT', {
      fileName: data.fileName,
      file_id: fileId,
    });
    setNewFileDialog(false);
    setEdit(false);
    setValue('fileName', '');
    setLoading(false);
    if (!!page) getSecretFiles();
    else getFiles(currentUser?.doctor_id);
  };
  const searchFile = async () => {
    if (allowSecrets) {
      setLoading(true);
      const response = await request(`get-secret-files-by-filter`, 'POST', {
        doctor_id: currentUser?.doctor_id,
        filter: searchText,
        page: pagination,
      });
      setResults(response?.totalCount);
      setSecretFiles(response?.data);
      setLoading(false);
    } else {
      setLoading(true);
      const response = await request(`get-files-by-filter`, 'POST', {
        doctor_id: currentUser?.doctor_id,
        filter: searchText,
        page: pagination,
      });
      setResults(response?.totalCount);
      setFiles(response?.data);
      setLoading(false);
    }
  };
  const handleSearchChange = (event) => {
    setSearchText(event?.target?.value);
  };
  const handleChange = (_, value) => {
    setPagination(value);
  };

  return (
    <>
      <Dialog
        sx={{
          '.MuiPaper-root': {
            backgroundColor: '#F7F7F7',
            borderRadius: 5,
            color: '#36454F',
          },
        }}
        open={newFileDialog}
        onClose={() => {
          setNewFileDialog(false);
          setEdit(false);
          setValue('fileName', '');
          reset();
        }}
      >
        <DialogTitle textAlign={'center'}>
          <h4 style={{ margin: 0 }}>
            {edit ? 'Update File Name' : 'New Patient File'}
          </h4>
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            borderBottom: 2,
            borderColor: '#B2BEB5',
            paddingY: 4,
            marginTop: 2,
          }}
        >
          {xs ? <h2>File Name</h2> : <h4>File Name</h4>}
          <Box display={'flex'} flexDirection={'column'} marginLeft={2}>
            <TextField
              sx={{
                width: !xs ? 150 : 200,
                '& fieldset': {
                  borderWidth: 2,
                  borderColor: '#36454F !important',
                },
                '&:focus-within fieldset': {
                  borderColor: '#36454F !important',
                },
                '& .MuiFormHelperText-root': {
                  color: 'black',
                },
              }}
              inputProps={{ style: { color: '#36454F', fontWeight: 'bold' } }}
              {...register('fileName', { required: 'File Name is required' })}
              error={errors.fileName}
            />
            {errors?.fileName && (
              <Typography color={'error.main'}>
                {errors.fileName?.message}
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              borderWidth: 2,
              borderRadius: 2,
              borderColor: '#EE4B2B',
              color: '#EE4B2B',
              fontWeight: 'bold',
              '&:hover': {
                borderWidth: 2,
                borderRadius: 2,
                borderColor: '#EE4B2B',
                color: '#EE4B2B',
                fontWeight: 'bold',
              },
            }}
            onClick={() => {
              setNewFileDialog(false);
              setEdit(false);
              setValue('fileName', '');
              reset();
            }}
            variant='outlined'
          >
            {t('Cancel')}
          </Button>
          <Button
            sx={{
              borderWidth: 2,
              borderRadius: 2,
              backgroundColor: '#228B22',
              fontWeight: 'bold',
              color: 'white',
              '&:hover': {
                borderWidth: 2,
                borderRadius: 2,
                backgroundColor: '#228B22',
                fontWeight: 'bold',
              },
            }}
            onClick={handleSubmit(edit ? updateFileName : addNewFile)}
            variant='contained'
          >
            {t(edit ? 'Update' : 'Add')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        sx={{ '.MuiDialogContent-root': { backgroundColor: '#F7F7F7' } }}
        open={PINDialog}
        onClose={() => {
          setPINDialog(false);
          setPage(0);
          setPIN('');
          setPINError('');
        }}
      >
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingX: 5,
            paddingTop: 5,
          }}
        >
          <MuiOtpInput
            sx={{
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                {
                  border: '3px black solid !important',
                },
            }}
            TextFieldsProps={{
              placeholder: '—',
              style: { caretColor: 'transparent' },
            }}
            value={PIN}
            onChange={handlePINChange}
            validateChar={validateChar}
          />
          {PINError && (
            <Typography
              fontSize={18}
              color={'error.main'}
              marginTop={3}
              marginBottom={-3}
            >
              {PINError}
            </Typography>
          )}
          <Button
            onClick={() => {
              setPINDialog(false);
              setPage(0);
              setPIN('');
              setPINError('');
            }}
            sx={{
              backgroundColor: '#B491CB',
              boxShadow: 'none',
              '&:hover': { backgroundColor: '#b583d6', boxShadow: 'none' },
              fontWeight: 'bold',
              marginTop: 5,
            }}
            color='secondary'
            variant='contained'
          >
            {t('Cancel')}
          </Button>
        </DialogContent>
      </Dialog>
      <Box
        paddingY={2}
        backgroundColor={'background.paper'}
        display={'flex'}
        justifyContent={'center'}
      >
        <h3 style={{ margin: 0, color: 'white', letterSpacing: 2 }}>Files</h3>
      </Box>
      <Box>
        <Tabs
          value={page}
          onChange={handleTabsChange}
          sx={{
            width: '100%',
            '& .MuiTabs-flexContainer': { width: '100%', display: 'flex' },
          }}
        >
          <Tab sx={{ flex: '9.9', maxWidth: 'none' }} label={''} />
          <Tab
            sx={{ flex: '0.1', maxWidth: 'none' }}
            label={
              allowSecrets ? (
                <Typography color={'black'}>{t('Secret files')}</Typography>
              ) : (
                t('')
              )
            }
          />
        </Tabs>
      </Box>
      <Box
        backgroundColor={'background.paper'}
        paddingY={5}
        textAlign={'center'}
        fontSize={35}
        color={'white'}
      >
        Your Patients Records in one Place
      </Box>
      <Box paddingX={3} paddingY={5}>
        <Grid
          container
          backgroundColor={'#f0eded'}
          boxShadow={10}
          borderRadius={2}
          paddingY={5}
          paddingX={1}
        >
          <Grid
            item
            xs={12}
            display={'flex'}
            flexDirection={xs ? 'row' : 'column'}
            justifyContent={'space-between'}
            alignItems={'center'}
            paddingX={xs ? 13.5 : 5}
          >
            <IconButton
              onClick={() => {
                setNewFileDialog(true);
              }}
            >
              <img src={Add} alt='Add' width={50} />
            </IconButton>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                searchFile();
              }}
            >
              <TextField
                onChange={handleSearchChange}
                placeholder='Search patient name'
                style={{ backgroundColor: '#B491CB', borderRadius: 5 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton type='submit'>
                        <img src={Search} alt='Search' width={30} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& input': {
                    color: 'white',
                  },
                  '&:focus-within fieldset': {
                    borderColor: 'transparent !important',
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent !important',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                }}
              />
            </form>
            <IconButton>
              <img src={Filter} alt='Filter' width={50} />
            </IconButton>
          </Grid>
          {allowSecrets
            ? secretFiles?.map((file, index) => {
                return (
                  <Grid
                    onClick={() => {
                      navigate('file', {
                        state: {
                          file_id: file.file_id,
                          file_name: file.file_name,
                          total_price: file.total_price,
                          total_price_paid: file.total_price_paid,
                          total_price_remaining: file.total_price_remaining,
                        },
                      });
                    }}
                    sx={{
                      '&:hover': {
                        backgroundColor: '#8080804f',
                      },
                      marginTop: 5,
                    }}
                    key={index}
                    item
                    xl={2}
                    lg={2.4}
                    md={3}
                    sm={4}
                    xs={6}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    borderRadius={5}
                    paddingY={2}
                  >
                    <img src={File} alt='File' width={100} />
                    <h3>{file.file_name}</h3>
                  </Grid>
                );
              })
            : files?.map((file, index) => {
                return (
                  <Grid
                    sx={{
                      '&:hover': {
                        backgroundColor: '#8080804f',
                      },
                      marginTop: 5,
                      position: 'relative',
                    }}
                    key={index}
                    item
                    xl={2}
                    lg={2.4}
                    md={3}
                    sm={4}
                    xs={6}
                    borderRadius={5}
                    paddingY={2}
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                      onClick={() => {
                        navigate('file', {
                          state: {
                            file_id: file.file_id,
                            file_name: file.file_name,
                            total_price: file.total_price,
                            total_price_paid: file.total_price_paid,
                            total_price_remaining: file.total_price_remaining,
                          },
                        });
                      }}
                    >
                      <img src={File} alt='File' width={100} />
                      <h3>{file.file_name}</h3>
                    </Box>
                    <IconButton
                      sx={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                      }}
                      onClick={() => {
                        setFileId(file.file_id);
                        setEdit(true);
                        setNewFileDialog(true);
                        setValue('fileName', file.file_name);
                      }}
                    >
                      <img src={Edit} alt='Edit' width={35} />
                    </IconButton>
                  </Grid>
                );
              })}
          <Grid item xs={12} display={'flex'} justifyContent={'center'}>
            <Pagination
              page={pagination}
              count={Math.ceil(results / 24)}
              sx={{
                marginTop: 2,
                '.Mui-selected': { backgroundColor: '#B491CB' },
              }}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Files;
