import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AppContext } from "../App";
import Hero from '../assets/mainbackground.png';
import Footer from "../components/Footer";
import ProfileTextField from "../components/ProfileTextField";
import { request } from "../API/Request";

const Profile = () => {

    const { xs, sm, lg, currentUser } = useContext(AppContext)
    const { register, handleSubmit, formState: { errors }, reset, setError } = useForm({
        defaultValues: currentUser
    })
    const [editInfo, setEditInfo] = useState(false)

    useEffect(() => {
        document.title = 'Weqayah | Profile'
    }, [])

    const cancelEditInfo = () => {
        reset()
        setEditInfo(false)
    }
    const confirmEditInfo = async (data) => {
        const response = await request('update-patient', 'PUT', data)
        if (response.success) {
            setEditInfo(false)
            window.location.reload()
        } else {
            setError('email', { message: response.errorMessage })
        }
    }

    return (
        <>
            <Box sx={{
                backgroundImage: `linear-gradient(rgba(96, 54, 120, 0.65), rgba(49, 20, 66, 0.8)),url(${Hero})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                textAlign: 'center',
            }} width={'100%'} height={'50vh'} color={'text.secondary'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                <h1 style={{ fontSize: sm ? 62 : xs ? 50 : 35 }}>{`Patient ID: P${currentUser.patient_id}`}</h1>
                <h1 style={{ fontSize: sm ? 62 : xs ? 50 : 35 }}>{`${currentUser.first_name} ${currentUser.last_name}`}</h1>
                <h1 style={{ fontSize: sm ? 62 : xs ? 50 : 35 }}>{`Balance: ${currentUser.balance} JD`}</h1>
            </Box>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} paddingY={sm ? 20 : 10}>
                <Grid container width={lg ? '70%' : '80%'}>
                    <Grid item md={6} xs={12}>
                        <img src={Hero} alt="Hero" width={'100%'} />
                    </Grid>
                    <Grid item md={6} xs={12} display={'flex'} justifyContent={sm ? "flex-start" : 'center'} paddingLeft={sm && 5}>
                        <form onSubmit={handleSubmit(confirmEditInfo)} style={{ display: 'flex', flexDirection: 'column', alignItems: sm ? "flex-start" : 'center', width: '100%', paddingTop: !sm && 20 }}>
                            <ProfileTextField editInfo={editInfo} label={'First Name'} name={'first_name'} errors={errors} register={register} type="text" />
                            {(errors.first_name && editInfo) && <Typography marginBottom={1} color={'error.main'}>{errors.first_name?.message}</Typography>}
                            <ProfileTextField editInfo={editInfo} label={'Last Name'} name={'last_name'} errors={errors} register={register} type="text" />
                            {(errors.last_name && editInfo) && <Typography marginBottom={1} color={'error.main'}>{errors.last_name?.message}</Typography>}
                            <ProfileTextField editInfo={editInfo} label={'Email'} name={'email'} errors={errors} register={register} type="email" />
                            {(errors.email && editInfo) && <Typography marginBottom={1} color={'error.main'}>{errors.email?.message}</Typography>}
                            <ProfileTextField editInfo={editInfo} label={'Phone Number'} name={'phone_number'} errors={errors} register={register} type="text" />
                            {(errors.phone_number && editInfo) && <Typography marginBottom={1} color={'error.main'}>{errors.phone_number?.message}</Typography>}
                            <FormControl sx={{ marginLeft: 0.3, display: 'flex', width: '100%' }}>
                                <FormLabel>
                                    <span style={{ color: 'black' }}>Gender</span>
                                </FormLabel>
                                <RadioGroup row defaultValue={currentUser.gender}>
                                    <FormControlLabel value="male" control={<Radio sx={{
                                        color: 'black',
                                        '&.Mui-checked': {
                                            color: 'black',
                                        },
                                    }} {...register('gender', { required: 'Gender is required' })} />} label="Male" />
                                    <FormControlLabel value="female" control={<Radio sx={{
                                        color: 'black',
                                        '&.Mui-checked': {
                                            color: 'black',
                                        },
                                    }} {...register('gender', { required: 'Gender is required' })} />} label="Female" />
                                </RadioGroup>
                            </FormControl>
                            {(errors.gender && editInfo) && <Typography marginBottom={1} color={'error.main'}>{errors.gender?.message}</Typography>}
                            <ProfileTextField editInfo={editInfo} label={'City'} name={'city'} errors={errors} register={register} type="text" />
                            {(errors.city && editInfo) && <Typography marginBottom={1} color={'error.main'}>{errors.city?.message}</Typography>}
                            <ProfileTextField editInfo={editInfo} label={'Age'} name={'age'} errors={errors} register={register} type="text" />
                            {(errors.age && editInfo) && <Typography marginBottom={1} color={'error.main'}>{errors.age?.message}</Typography>}
                            <ProfileTextField editInfo={editInfo} label={'Insurance'} name={'insurance'} errors={errors} register={register} type="text" />
                            {(errors.insurance && editInfo) && <Typography marginBottom={1} color={'error.main'}>{errors.insurance?.message}</Typography>}
                            {editInfo ?
                                <Box>
                                    <Button onClick={() => { cancelEditInfo() }} sx={{
                                        '&:hover': {
                                            backgroundColor: '#f44336',
                                            border: '1px solid #f44336',
                                            color: "white",
                                            transition: '1s'
                                        },
                                        transition: '1s',
                                        marginRight: 1
                                    }} color="error" variant="outlined">Cancel</Button>
                                    <Button type="submit" sx={{
                                        '&:hover': {
                                            backgroundColor: '#42159D',
                                            border: '1px solid #42159D',
                                            color: "white",
                                            transition: '1s'
                                        },
                                        transition: '1s'
                                    }} color="secondary" variant="outlined">Confirm</Button>
                                </Box> : <Button onClick={() => { setEditInfo(true) }} sx={{
                                    '&:hover': {
                                        backgroundColor: '#42159D',
                                        border: '1px solid #42159D',
                                        color: "white",
                                        transition: '1s'
                                    },
                                    transition: '1s',
                                    marginTop: 1
                                }} color="secondary" variant="outlined">Edit Info</Button>}
                        </form>
                    </Grid>
                </Grid>
            </Box>
            <Footer />
        </>
    )
}

export default Profile