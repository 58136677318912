import { Box, IconButton } from "@mui/material"
import { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import { request } from "../API/Request"
import Cancel from '../assets/cancel.svg'
import Confirm from '../assets/confirm.svg'
import Edit from '../assets/edit.svg'
import Remove from '../assets/remove.svg'
import DashboardTextField from "./DashboardTextField"
import { AppContext } from "../App"

const Doctor = ({ doctor, getDoctors }) => {

    const { setLoading } = useContext(AppContext)
    const { register, handleSubmit, formState: { errors }, reset, setError } = useForm({
        values: doctor
    })
    const [editInfo, setEditInfo] = useState(false)

    const confirmEditInfo = async (data) => {
        setLoading(true)
        const response = await request('update-doctor-for-admin', 'PUT',
            {
                "doctor_id": data.doctor_id,
                "clinic_phone_number": data.clinic_phone_number,
                "clinic_name": data.clinic_name,
                "email": data.email,
                "age": data.age,
                "gender": data.gender,
                "first_name": data.first_name,
                "last_name": data.last_name,
                "city": data.city,
                "clinic_location": data.clinic_location,
                "location_text": data.location_text,
                "biography": '',
                "balance": data.balance,
                "speciality": data.speciality,
                "fees": data.fees,
                "pin": data.pin,
            }
        )
        if (response.success) {
            getDoctors()
            setEditInfo(false)
        } else {
            setError('email', { message: response.errorMessage })
        }
        setLoading(false)
    }
    const deleteDoctor = async () => {
        setLoading(true)
        const response = await request(`delete-tabs/${doctor.doctor_id}`, 'DELETE')
        if (response.success) {
            await request(`delete-doctor/${doctor.doctor_id}`, 'DELETE')
        }
        getDoctors()
        setLoading(false)
    }

    return (
        <>
            <Box width={'95%'} display={"flex"} justifyContent={'space-evenly'} alignItems={'center'} marginTop={2}>
                <h2 style={{ marginRight: 5 }}>{doctor.doctor_id}</h2>
                <DashboardTextField editInfo={editInfo} register={register} name={'first_name'} errors={errors} />
                <DashboardTextField editInfo={editInfo} register={register} name={'last_name'} errors={errors} />
                <DashboardTextField editInfo={editInfo} register={register} name={'email'} errors={errors} />
                <DashboardTextField editInfo={editInfo} register={register} name={'gender'} errors={errors} />
                <DashboardTextField editInfo={editInfo} register={register} name={'age'} errors={errors} />
                <DashboardTextField editInfo={editInfo} register={register} name={'city'} errors={errors} />
                <DashboardTextField editInfo={editInfo} register={register} name={'clinic_name'} errors={errors} />
                <DashboardTextField editInfo={editInfo} register={register} name={'clinic_phone_number'} errors={errors} />
                <DashboardTextField editInfo={editInfo} register={register} name={'clinic_location'} errors={errors} />
                <DashboardTextField editInfo={editInfo} register={register} name={'location_text'} errors={errors} />
                <DashboardTextField editInfo={editInfo} register={register} name={'balance'} errors={errors} />
                <DashboardTextField editInfo={editInfo} register={register} name={'speciality'} errors={errors} />
                <DashboardTextField editInfo={editInfo} register={register} name={'fees'} errors={errors} />
                <DashboardTextField editInfo={editInfo} register={register} name={'pin'} errors={errors} />
                {editInfo ?
                    <>
                        <IconButton onClick={handleSubmit(confirmEditInfo)}>
                            <img src={Confirm} alt="Edit" width={40} />
                        </IconButton>
                        <IconButton onClick={() => { reset(); setEditInfo(false) }}>
                            <img src={Cancel} alt="Edit" width={40} />
                        </IconButton>
                    </>
                    : <>
                        <IconButton onClick={() => { setEditInfo(true) }}>
                            <img src={Edit} alt="Edit" width={40} />
                        </IconButton>
                        <IconButton onClick={() => { deleteDoctor() }}>
                            <img src={Remove} alt="Remove" width={30} />
                        </IconButton>
                    </>
                }
            </Box>
            {errors.email?.message && <p style={{ marginTop: -15 }}>{errors.email?.message}</p>}
        </>
    )
}

export default Doctor