import { IconButton } from "@mui/material"
import { Link } from "react-router-dom"

const SocialIcon = ({ to = '', icon, social = '' }) => {
    return (
        <Link to={to} target="_blank">
            <IconButton sx={{
                "&:hover": {
                    backgroundColor: 'background.paper'
                }
            }}>
                <img src={icon} alt={social} width={social === 'YouTube' ? 25 : social === 'Facebook' ? 23 : 20} />
            </IconButton>
        </Link>
    )
}

export default SocialIcon