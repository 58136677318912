import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom"

const NavigationButton = ({ title, goTo }) => {

    const navigate = useNavigate()

    return (
        <Button onClick={() => { navigate(goTo, { replace: window.location.pathname === `${goTo}` }) }} sx={{
            position: 'relative',
            fontWeight: 'bold',
            marginLeft: 2,
            color: '#B491CB',
            '&::after': {
                content: '""',
                position: "absolute",
                bottom: 0,
                width: 0,
                height: '2px',
                backgroundColor: 'black',
                display: 'block',
                margin: 'auto',
                transition: 'width 0.3s',
            },
            '&:hover::after': {
                width: '90%',
            },
        }} disableRipple color="secondary">
            {title}
        </Button>
    )
}

export default NavigationButton