import { Box, Button, Divider, IconButton, TextField } from "@mui/material"
import { Fragment, useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { request } from "../API/Request"
import { AppContext } from "../App"
import Add from '../assets/add.svg'
import Doctor from "../components/Doctor"

const Doctors = () => {

    const { setLoading } = useContext(AppContext)
    const { register, handleSubmit, formState: { errors }, reset, setError } = useForm()
    const [doctors, setDoctors] = useState([])
    const [limit, setLimit] = useState(1)
    const [searchText, setSearchText] = useState('')

    const getDoctors = async () => {
        setLoading(true)
        const response = await request(`get-doctors-for-admin/${limit}`, 'GET')
        setDoctors(response?.doctors)
        setLoading(false)
    }
    useEffect(() => {
        document.title = 'Admin | Doctors'
        getDoctors()
    }, [limit])

    const addDoctor = async (data) => {
        setLoading(true)
        const response = await request('add-doctor', 'POST',
            {
                "clinic_phone_number": data.clinic_phone_number,
                "clinic_name": data.clinic_name,
                "email": data.email,
                "password": data.password,
                "age": data.age,
                "gender": data.gender,
                "first_name": data.first_name,
                "last_name": data.last_name,
                "city": data.city,
                "clinic_location": data.clinic_location,
                "location_text": data.location_text,
                "biography": '',
                "balance": data.balance,
                "speciality": data.speciality,
                "fees": data.fees,
                "pin": data.pin
            }
        )
        if (response.success) {
            await request('add-tab', 'POST', { "tab_name": 'main', "doctor_id": response.data.doctor_id })
            if (data.referralCode) {
                await request('add-balance', 'PUT', { "char": data.referralCode?.slice(0, 1), "id": data.referralCode?.slice(1) })
            }
            getDoctors()
            reset()
        } else {
            setError('email', { message: response.errorMessage })
        }
        setLoading(false)
    }
    const filterDoctors = (doctor) => {
        return doctor.first_name.toLowerCase().includes(searchText.toLowerCase()) || doctor.last_name.toLowerCase().includes(searchText.toLowerCase())
    };
    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    return (
        <Box backgroundColor={'#F7F7F7'} textAlign={'center'} display={"flex"} flexDirection={"column"} alignItems={"center"} paddingBottom={5}>
            <Box backgroundColor={'background.paper'} width={'100%'} display={"flex"} flexDirection={'column'} alignItems={'center'} paddingY={5} marginBottom={5}>
                <h1 style={{ color: "white" }}>Doctors</h1>
                <TextField onChange={handleSearchChange} sx={{ backgroundColor: '#F7F7F7', borderRadius: 2, input: { "&::placeholder": { color: "#993cd6", fontWeight: 'bold' }, color: '#B491CB' }, 'fieldset': { borderWidth: 2, borderColor: '#7C5295' }, '&:focus-within fieldset': { borderWidth: 2, borderColor: '#7C5295 !important' }, '&:hover fieldset': { borderWidth: 2, borderColor: '#7C5295 !important' }, '.MuiInputBase-root': { borderRadius: 2 } }} placeholder="Search Doctors..." />
            </Box>
            <form onSubmit={handleSubmit(addDoctor)} style={{ width: '95%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.first_name ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.first_name ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('first_name', { required: true })} error={!!errors.first_name} placeholder={'first_name'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.last_name ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.last_name ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('last_name', { required: true })} error={!!errors.last_name} placeholder={'last_name'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.email ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.email ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('email', { required: true })} error={!!errors.email} placeholder={'Email'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.password ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.password ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('password', { required: true })} error={!!errors.password} placeholder={'password'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.gender ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.gender ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('gender', { required: true })} error={!!errors.gender} placeholder={'gender'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.age ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.age ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('age', { required: true })} error={!!errors.age} placeholder={'age'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.city ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.city ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('city', { required: true })} error={!!errors.city} placeholder={'city'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.clinic_name ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.clinic_name ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('clinic_name', { required: true })} error={!!errors.clinic_name} placeholder={'clinic_name'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.clinic_phone_number ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.clinic_phone_number ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('clinic_phone_number', { required: true })} error={!!errors.clinic_phone_number} placeholder={'clinic_phone_number'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.clinic_location ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.clinic_location ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('clinic_location', { required: true })} error={!!errors.clinic_location} placeholder={'clinic_location'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.location_text ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.location_text ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('location_text', { required: true })} error={!!errors.location_text} placeholder={'location_text'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.balance ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.balance ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('balance', { required: true })} error={!!errors.balance} placeholder={'balance'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.speciality ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.speciality ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('speciality', { required: true })} error={!!errors.speciality} placeholder={'speciality'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.fees ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.fees ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('fees', { required: true })} error={!!errors.fees} placeholder={'fees'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.pin ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.pin ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('pin', { required: true })} error={!!errors.pin} placeholder={'pin'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: '2px solid black !important',
                    },
                    '& fieldset': {
                        border: '1px solid black !important',
                    },
                }} size="small" type='text' {...register('referralCode')} placeholder={'referralCode'} />
                <IconButton type="submit">
                    <img src={Add} alt="Add" width={30} />
                </IconButton>
            </form>
            {errors?.email?.message && <p style={{ marginTop: 20 }}>{errors.email?.message}</p>}
            {doctors?.filter(filterDoctors)?.map((doctor, index) => {
                return <Fragment key={index}>
                    <Divider sx={{ width: '95%', marginTop: 2 }} />
                    <Doctor doctor={doctor} getDoctors={getDoctors} />
                </Fragment>
            })}
            <Divider sx={{ width: '95%', marginY: 2 }} />
            <Button sx={{ backgroundColor: '#B491CB', boxShadow: 'none', color: 'white', '&:hover': { backgroundColor: '#b583d6', boxShadow: 'none' }, fontWeight: 'bold' }} onClick={() => { setLimit(limit + 1) }} variant="contained">Load More</Button>
        </Box>
    )
}

export default Doctors