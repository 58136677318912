import { Box, Button, IconButton } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { request } from '../API/Request';
import { AppContext } from '../App';
import Logo from '../assets/logo.png';
import MenuIcon from '../assets/menu.svg';
import NavigationButton from './NavigationButton';

const NavigationBar = () => {
  const {
    xs,
    md,
    lg,
    setOpenMenu,
    isSigned,
    role,
    currentUser,
    setIsSigned,
    setRole,
    setCurrentUser,
    setLoading,
    setProfilePicture,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const logOut = async () => {
    setLoading(true);
    const response = await request(
      'log-out',
      'POST',
      role === 'patient'
        ? {
            patient_id: currentUser?.patient_id,
            role: role,
            email: currentUser?.email,
            token: localStorage.getItem('token'),
          }
        : role === 'doctor'
        ? {
            doctor_id: currentUser?.doctor_id,
            role: role,
            email: currentUser?.email,
            token: localStorage.getItem('token'),
          }
        : {
            role: role,
            email: currentUser?.email,
            token: localStorage.getItem('token'),
          }
    );
    if (response.success) {
      setIsSigned(false);
      setRole('guest');
      setCurrentUser({});
      setProfilePicture('');
      navigate('/', { replace: true });
      localStorage.removeItem('token');
    }
    setLoading(false);
  };

  if (
    window.location.pathname === '/patientSignup' ||
    window.location.pathname === '/doctorSignup' ||
    window.location.pathname === '/login' ||
    window.location.pathname === '/forgotPassword' ||
    window.location.pathname === '/otpPage' ||
    window.location.pathname === '/setPassword'
  )
    return <></>;
  else if (role === 'admin')
    return (
      <Box height={70} display={'flex'} justifyContent={'center'}>
        <Box
          width={lg ? '80%' : '90%'}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <IconButton
            disableRipple
            onClick={() => {
              navigate('/adminDashboard', {
                replace: window.location.pathname === '/adminDashboard',
              });
            }}
            sx={{ height: '100%' }}
          >
            <img src={Logo} alt='Logo' height={'60'} />
            {xs && <h4 style={{ color: '#9157b7b2' }}>ADMIN DASHBOARD</h4>}
          </IconButton>
          {md ? (
            <Box>
              <NavigationButton title={t('Home')} goTo={'/adminDashboard'} />
              <NavigationButton
                title={t('Doctors')}
                goTo={'/adminDashboard/doctors'}
              />
              <NavigationButton
                title={t('Patients')}
                goTo={'/adminDashboard/patients'}
              />
              <NavigationButton
                title={t('Pharmacies')}
                goTo={'/adminDashboard/pharmacies'}
              />
              <NavigationButton
                title={t('Surgeries')}
                goTo={'/adminDashboard/surgeries'}
              />
              <Button
                sx={{
                  backgroundColor: '#B491CB',
                  boxShadow: 'none',
                  '&:hover': { backgroundColor: '#b583d6', boxShadow: 'none' },
                  fontWeight: 'bold',
                  marginLeft: 2,
                }}
                onClick={() => {
                  logOut();
                }}
                color='secondary'
                variant='contained'
              >
                {t('Logout')}
              </Button>
            </Box>
          ) : (
            <IconButton
              onClick={() => {
                setOpenMenu(true);
              }}
              disableRipple
            >
              <img src={MenuIcon} alt='Menu' width={30} />
            </IconButton>
          )}
        </Box>
      </Box>
    );
  else
    return (
      <Box height={70} display={'flex'} justifyContent={'center'}>
        <Box
          width={lg ? '80%' : '90%'}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <IconButton
            disableRipple
            onClick={() => {
              navigate('/', { replace: window.location.pathname === '/' });
            }}
            sx={{ height: '100%' }}
          >
            <img src={Logo} alt='Logo' height={'60'} />
            <h4 style={{ color: '#9157b7b2' }}>WEQAYAH</h4>
          </IconButton>
          {md ? (
            <Box>
              <NavigationButton title={t('Home')} goTo={'/'} />
              {role !== 'doctor' && (
                <NavigationButton
                  title={t('Find a Doctor')}
                  goTo={'/findDoctor'}
                />
              )}
              {/* {role !== 'doctor' && <NavigationButton title={t('Find a Pharmacy')} goTo={'/findPharmacies'} />} */}
              {role === 'patient' && (
                <NavigationButton
                  title={t('My Bookings')}
                  goTo={'/patientBookings'}
                />
              )}
              {role === 'doctor' && (
                <NavigationButton
                  title={t('Bookings')}
                  goTo={'/doctorBookings'}
                />
              )}
              {role !== 'doctor' && (
                <NavigationButton title={t('About Us')} goTo={'/aboutUs'} />
              )}
              {role === 'doctor' && (
                <NavigationButton title={t('Files')} goTo={'/files'} />
              )}
              {role === 'doctor' && (
                <NavigationButton title={t('Inventory')} goTo={'/inventory'} />
              )}
              {isSigned ? (
                <>
                  <NavigationButton
                    title={t('Profile')}
                    goTo={role === 'doctor' ? '/doctorInfo' : '/profile'}
                  />
                  <Button
                    sx={{
                      backgroundColor: '#B491CB',
                      boxShadow: 'none',
                      '&:hover': {
                        backgroundColor: '#b583d6',
                        boxShadow: 'none',
                      },
                      fontWeight: 'bold',
                      marginLeft: 2,
                    }}
                    onClick={() => {
                      logOut();
                    }}
                    color='secondary'
                    variant='contained'
                  >
                    {t('Logout')}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    sx={{
                      borderColor: '#B491CB',
                      color: '#B491CB',
                      boxShadow: 'none',
                      '&:hover': {
                        backgroundColor: '#B491CB',
                        border: '1px solid #B491CB',
                        color: 'white',
                        transition: '1s',
                      },
                      transition: '1s',
                      marginRight: 1,
                      marginLeft: 2,
                      fontWeight: 'bold',
                    }}
                    onClick={() => {
                      navigate('/login');
                    }}
                    color='secondary'
                    variant='outlined'
                  >
                    {t('Sign-in')}
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: '#B491CB',
                      boxShadow: 'none',
                      '&:hover': {
                        backgroundColor: '#b583d6',
                        boxShadow: 'none',
                      },
                      fontWeight: 'bold',
                    }}
                    onClick={() => {
                      navigate('/patientSignup');
                    }}
                    color='secondary'
                    variant='contained'
                  >
                    {t('Sign-up')}
                  </Button>
                </>
              )}
              {i18n.language === 'en' ? (
                <Button
                  onClick={() => {
                    i18n.changeLanguage('ar');
                    localStorage.setItem('language', JSON.stringify('ar'));
                  }}
                  sx={{
                    color: 'black',
                    fontWeight: 'bold',
                    border: '2px black solid',
                    borderRadius: 0,
                    marginLeft: 2,
                  }}
                  disableRipple
                >
                  ع
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    i18n.changeLanguage('en');
                    localStorage.setItem('language', JSON.stringify('en'));
                  }}
                  sx={{
                    color: 'black',
                    fontWeight: 'bold',
                    border: '2px black solid',
                    borderRadius: 0,
                    marginLeft: 2,
                  }}
                  disableRipple
                >
                  En
                </Button>
              )}
            </Box>
          ) : (
            <IconButton
              onClick={() => {
                setOpenMenu(true);
              }}
              disableRipple
            >
              <img src={MenuIcon} alt='Menu' width={30} />
            </IconButton>
          )}
        </Box>
      </Box>
    );
};

export default NavigationBar;
