import { Card } from "@mui/material"
import { useContext } from "react"
import { AppContext } from "../App"

const ServiceCard = ({ title, content }) => {

    const { xs } = useContext(AppContext)

    return (
        <Card sx={{ borderRadius: 5, paddingX: xs ? 5 : 3, paddingBottom: 2, overflowX: 'auto' }}>
            <h2>{title}</h2>
            <p>{content}</p>
        </Card>
    )
}

export default ServiceCard