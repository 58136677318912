import { Box, Button, Dialog, DialogContent, Grid, Typography } from "@mui/material"
import { MuiTelInput, matchIsValidTel } from "mui-tel-input"
import { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { request } from "../../API/Request"
import { AppContext } from "../../App"
import CustomTextField from "../../components/CustomTextField"

const DoctorSignup = () => {

    const navigate = useNavigate()
    const { xs, setLoading } = useContext(AppContext)
    const { register, handleSubmit, formState: { errors }, setError } = useForm()
    const [phoneNumber, setPhoneNumber] = useState()
    const [successDialog, setSuccessDialog] = useState(false)

    useEffect(() => {
        document.title = 'Weqayah | Doctor Sign-up'
    }, [])

    const signup = async (data) => {
        if (!matchIsValidTel(data.phone_number)) {
            setError('phone_number')
        } else {
            setLoading(true)
            const response = await request('doctor-request', 'POST', data)
            if (response.success) {
                setSuccessDialog(true)
            }
            setLoading(false)
        }
    }

    return (
        <>
            <Dialog open={successDialog}>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: "center", textAlign: 'center' }}>
                    <h1>Your request has been sent successfully</h1>
                    <Button sx={{ width: 200 }} onClick={() => { setSuccessDialog(false) }} color="secondary" variant="contained">Ok</Button>
                </DialogContent>
            </Dialog>
            <Box width={xs ? '98.2vw' : '100vw'} height={xs ? '96.5vh' : '100vh'} padding={xs ? 2 : 0}>
                <Box backgroundColor={'#e2e2e5'} width={'100%'} height={'100%'} display={"flex"} alignItems={"center"} justifyContent={"center"} paddingY={xs ? 0 : 15}>
                    <Grid container width={xs ? 900 : 300} display={"flex"} alignItems={'center'}>
                        <Grid item xs={12} sm={6} height={200} display={"flex"} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} paddingX={xs ? 2 : 0} textAlign={!xs && 'center'}>
                            <Box display={"flex"} flexDirection={"column"} alignItems={xs ? 'start' : 'center'} justifyContent={'space-evenly'} height={'100%'} width={'100%'}>
                                <Typography sx={{ color: '#B491CB', fontSize: 30, fontWeight: 900, fontFamily: `'Inter UI', sans-serif` }}>Weqayah</Typography>
                                <Typography sx={{ color: 'black', fontSize: 22 }}>Manage your files and appointments in one place.</Typography>
                                <Box display={"flex"} alignItems={'start'}>
                                    <Button disableRipple onClick={() => { navigate('/login') }} sx={{ fontSize: xs && 20, color: '#B491CB', justifyContent: 'flex-start', padding: 0, textAlign: 'start', "&:hover": { backgroundColor: 'transparent' } }}>Sign-in</Button>
                                    <Typography fontSize={xs && 20} marginX={1}>or</Typography>
                                    <Button disableRipple onClick={() => { navigate('/patientSignup') }} sx={{ fontSize: xs && 20, color: '#B491CB', justifyContent: 'flex-start', padding: 0, "&:hover": { backgroundColor: 'transparent' } }}>Patient Sign-up</Button>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} display={"flex"} flexDirection={'column'} justifyContent={'space-evenly'} backgroundColor={'#B491CB'} color={'white'} padding={4}>
                            <h2 style={{ textAlign: 'center', margin: 0, marginBottom: 25 }} >Doctor Sign-up Request</h2>
                            <form onSubmit={handleSubmit(signup)} style={{ display: "flex", flexDirection: 'column' }}>
                                <label style={{ fontSize: 14 }}>First Name</label>
                                <CustomTextField name={'first_name'} label={'First Name'} register={register} errors={errors} autoFocus={true} />
                                <label style={{ fontSize: 14 }}>Last Name</label>
                                <CustomTextField name={'last_name'} label={'Last Name'} register={register} errors={errors} />
                                <label style={{ fontSize: 14 }}>Email</label>
                                <CustomTextField name={'email'} label={'Email'} type="email" register={register} errors={errors} />
                                <label style={{ fontSize: 14 }}>Phone Number</label>
                                <MuiTelInput size="small"
                                    sx={{
                                        marginBottom: 1,
                                        ".MuiInputAdornment-root": {
                                            marginLeft: -1,
                                        },
                                        ".MuiInputBase-root": {
                                            color: '#B491CB',
                                            backgroundColor: 'white',
                                        },
                                        "& fieldset": {
                                            borderColor: "white",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: "white !important",
                                            borderWidth: 0,
                                        },
                                        "&:focus-within fieldset": {
                                            borderWidth: "0px !important",
                                        }
                                    }} MenuProps={{
                                        classes: {
                                            paper: 'custom-country-dropdown'
                                        }
                                    }} {...register('phone_number', { validate: () => !phoneNumber ? 'Phone Number is required' : phoneNumber?.length <= 9 ? 'Please enter a valid phone number' : true })} error={!!errors.phone_number} defaultCountry="JO" preferredCountries={['JO']} excludedCountries={['IL']} flagSize="medium" placeholder="Select Your Country" value={phoneNumber} onChange={(newPhone) => { setPhoneNumber(newPhone) }} />
                                {errors.phone_number && <Typography marginBottom={1} color={'error.main'}>{errors.phone_number?.message}</Typography>}
                                <label style={{ fontSize: 14 }}>Referral Code</label>
                                <CustomTextField name={'referral_code'} label={'Referral Code (Optional)'} register={register} errors={errors} helperText={'The id of patient or doctor told you about us'} />
                                <Button sx={{ height: 40, marginTop: 2 }} type="submit" variant="contained">Sign-up</Button>
                            </form>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {/* <Box width={'100vw'} display={"flex"} alignItems={"center"} justifyContent={"center"} paddingTop={md ? 20 : sm ? 15 : xs && 10} paddingBottom={xs && 10}>
                <Card sx={{ width: xs ? 500 : '100%', height: xs ? 'auto' : '100%', display: "flex", flexDirection: 'column', alignItems: 'center', paddingBottom: xs ? 5 : 15, overflowY: 'auto' }} elevation={8}>
                    <h1 style={{ marginBottom: !xs && 50, marginTop: !xs && 100 }}>Doctor Sign-up</h1>
                    <form onSubmit={handleSubmit(signup)} style={{ display: "flex", flexDirection: 'column', width: '80%' }}>
                        <CustomTextField name={'first_name'} label={'First Name'} register={register} errors={errors} autoFocus={true} />
                        {errors.first_name && <Typography marginBottom={1} color={'error.main'}>{errors.first_name?.message}</Typography>}
                        <CustomTextField name={'last_name'} label={'Last Name'} register={register} errors={errors} />
                        {errors.last_name && <Typography marginBottom={1} color={'error.main'}>{errors.last_name?.message}</Typography>}
                        <CustomTextField name={'email'} label={'Email'} type="email" register={register} errors={errors} />
                        {errors.email && <Typography marginBottom={1} color={'error.main'}>{errors.email?.message}</Typography>}
                        <MuiTelInput sx={{
                            marginBottom: 2,
                            '&:focus-within fieldset': {
                                borderColor: 'black !important',
                            },
                        }} MenuProps={{
                            classes: {
                                paper: 'custom-country-dropdown'
                            }
                        }} {...register('phone_number', { validate: () => !phoneNumber ? 'Phone Number is required' : phoneNumber?.length <= 9 ? 'Please enter a valid phone number' : true })} error={!!errors.phone_number} defaultCountry="JO" preferredCountries={['JO']} excludedCountries={['IL']} flagSize="medium" placeholder="Select Your Country" value={phoneNumber} onChange={(newPhone) => { setPhoneNumber(newPhone) }} />
                        {errors.phone_number && <Typography marginBottom={1} color={'error.main'}>{errors.phone_number?.message}</Typography>}
                        <CustomTextField name={'referral_code'} label={'Referral Code (Optional)'} register={register} errors={errors} helperText={'The id of patient or doctor told you about us'} />
                        <Button sx={{ height: 56, marginTop: 2 }} type="submit" variant="contained">Sign-up</Button>
                        <Box display={"flex"} justifyContent={"center"} marginTop={3}>
                            <Typography>Do you have account?</Typography>
                            <Button onClick={() => { navigate('/login') }} sx={{ height: 23, fontWeight: 'bold', fontSize: 15 }} color="secondary">Sign-in</Button>
                        </Box>
                        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                            <Typography>Or</Typography>
                            <Button onClick={() => { navigate('/patientSignup') }} sx={{ fontWeight: 'bold', fontSize: 15 }} color="secondary">Sign-up as patient</Button>
                        </Box>
                    </form>
                </Card>
            </Box> */}
        </>
    )
}

export default DoctorSignup