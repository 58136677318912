import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { request } from '../API/Request';
import { AppContext } from '../App';
import Accept from '../assets/accept.svg';
import Reject from '../assets/reject.svg';
import Notes from '../assets/notes.svg';
import { useTranslation } from 'react-i18next';

const PatientBookingCard = ({
  time,
  date,
  appointment_id,
  booking_id,
  getBookings,
  patient_name,
  patient_age,
  description,
  tab,
}) => {
  const { setLoading, xs } = useContext(AppContext);
  const { t } = useTranslation();
  const [descriptionDialog, setDescriptionDialog] = useState(false);

  const accept = async () => {
    setLoading(true);
    await request('update-booking', 'PUT', {
      booking_id: booking_id,
      booking_status: 'Accepted',
    });
    await request('update-appointment', 'PUT', {
      appointment_id: appointment_id,
      booking_status: true,
      patient_name: patient_name,
    });
    setLoading(false);
    getBookings();
  };
  const reject = async () => {
    setLoading(true);
    await request('update-booking', 'PUT', {
      booking_id: booking_id,
      booking_status: 'Rejected',
    });
    await request('update-appointment', 'PUT', {
      appointment_id: appointment_id,
      booking_status: false,
      patient_name: '',
    });
    setLoading(false);
    getBookings();
  };

  return (
    <>
      <Dialog
        sx={{
          '.MuiDialog-paper': {
            backgroundColor: '#F7F7F7',
            borderRadius: 5,
          },
        }}
        open={descriptionDialog}
        onClose={() => {
          setDescriptionDialog(false);
        }}
      >
        <DialogTitle
          color={'#36454F'}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          sx={{ borderBottom: 2, borderColor: '#B2BEB5' }}
        >
          <img src={Notes} alt='Notes' width={35} />
          <h4 style={{ margin: 0 }}>Notes</h4>
        </DialogTitle>
        <DialogContent
          sx={{
            marginTop: 2,
            borderBottom: 2,
            borderColor: '#B2BEB5',
            display: 'flex',
            flexDirection: 'column',
            color: '#808080',
            overflowWrap: 'break-word',
            paddingX: xs ? 6 : 4,
          }}
        >
          <Typography>Name: {patient_name}</Typography>
          <Typography>Phone: {patient_age}</Typography>
          <Typography>Tab: {tab}</Typography>
          <Typography>Notes: {description}</Typography>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            sx={{
              backgroundColor: '#B491CB',
              boxShadow: 'none',
              '&:hover': { backgroundColor: '#b583d6', boxShadow: 'none' },
              fontWeight: 'bold',
            }}
            onClick={() => {
              setDescriptionDialog(false);
            }}
            color='success'
            variant='contained'
          >
            {t('Done')}
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        width={'95%'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        border={2}
        borderColor={'background.paper'}
        borderRadius={5}
        paddingX={1}
        marginBottom={2}
        paddingY={1}
      >
        <h3
          style={{
            fontSize: xs ? 18 : 14,
            borderInlineEnd: '2px solid',
            borderColor: '#B491CB',
            width: '100%',
            height: '80%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: 2,
            paddingRight: 2,
          }}
        >
          {patient_name}
        </h3>
        <h3
          style={{
            fontSize: xs ? 18 : 14,
            borderInlineEnd: '2px solid',
            borderColor: '#B491CB',
            width: '100%',
            height: '80%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: 2,
            paddingRight: 2,
          }}
        >
          {patient_age}
        </h3>
        <h3
          style={{
            fontSize: xs ? 18 : 14,
            borderInlineEnd: '2px solid',
            borderColor: '#B491CB',
            width: '100%',
            height: '80%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: 2,
            paddingRight: 2,
          }}
        >
          {date?.slice(0, 10)}
        </h3>
        <h3
          style={{
            fontSize: xs ? 18 : 14,
            borderInlineEnd: '2px solid',
            borderColor: '#B491CB',
            width: '100%',
            height: '80%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: 2,
            paddingRight: 2,
          }}
        >
          {+time?.slice(0, 2) > 12
            ? +time?.slice(0, 2) - 12 + ':' + time?.slice(3, 5)
            : time?.slice(0, 5)}{' '}
          {+time?.slice(0, 2) >= 12 ? 'PM' : 'AM'}
        </h3>
        <h3
          style={{
            fontSize: xs ? 18 : 14,
            borderInlineEnd: xs && '2px solid #B491CB',
            width: '100%',
            height: '80%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: 2,
            paddingRight: 2,
          }}
        >
          {tab}
        </h3>
        <Box display={'flex'} flexDirection={'column'}>
          <IconButton
            onClick={() => {
              setDescriptionDialog(true);
            }}
          >
            <img src={Notes} alt='Notes' />
          </IconButton>
          <IconButton
            onClick={() => {
              accept();
            }}
          >
            <img src={Accept} alt='Accept' />
          </IconButton>
          <IconButton
            onClick={() => {
              reject();
            }}
          >
            <img src={Reject} alt='Reject' />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default PatientBookingCard;
