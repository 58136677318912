import { Box, Button, Grid } from "@mui/material";
import Hero from '../assets/mainbackground.png';
import Footer from "../components/Footer";
import { useContext, useEffect } from "react";
import { AppContext } from "../App";

const AboutUs = () => {

    const { sm, xs } = useContext(AppContext)

    useEffect(() => {
        document.title = 'Weqayah | About Us'
    }, [])

    return (
        <>
            <Box sx={{
                backgroundImage: `linear-gradient(rgba(96, 54, 120, 0.65), rgba(49, 20, 66, 0.8)),url(${Hero})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                textAlign: "center"
            }} width={'100%'} height={'50vh'} color={'text.secondary'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                <h1 style={{ fontSize: sm ? 62 : xs ? 50 : 35 }}>About Weqayah</h1>
            </Box>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} paddingY={sm ? 20 : 10}>
                <Grid container width={'70%'}>
                    <Grid item md={6} display={'flex'} flexDirection={'column'} alignItems={sm ? "flex-start" : 'center'} paddingRight={sm && 5} textAlign={!sm && 'center'} marginBottom={!sm && 8}>
                        <h1>We're the biggest private medical system in the Middle East</h1>
                        <p style={{ fontSize: 18, marginBottom: 30 }}>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Et eveniet magni tempora adipisci ad eaque debitis, quia commodi eum earum beatae vitae, atque neque fugit delectus ullam! Voluptatem, qui cum.
                        </p>
                        <Button sx={{
                            borderColor: '#B491CB',
                            color: '#B491CB',
                            boxShadow: 'none',
                            '&:hover': {
                                backgroundColor: '#B491CB',
                                border: '1px solid #B491CB',
                                color: "white",
                                transition: '1s'
                            },
                            transition: '1s'
                        }} color="secondary" variant="outlined">Explore Now</Button>
                    </Grid>
                    <Grid item md={6}>
                        <img src={Hero} alt="Hero" width={'100%'} />
                    </Grid>
                </Grid>
            </Box>
            <Footer />
        </>
    )
}

export default AboutUs