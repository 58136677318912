import { Box, Button, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { Fragment, useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { request } from "../API/Request"
import { AppContext } from "../App"
import Add from '../assets/add.svg'
import Surgery from "../components/Surgery"

const Surgeries = () => {

    const { setLoading } = useContext(AppContext)
    const { register, handleSubmit, formState: { errors }, reset } = useForm()
    const [surgeries, setSurgeries] = useState([])
    const [limit, setLimit] = useState(1)
    const [searchText, setSearchText] = useState('')

    const getSurgeries = async () => {
        setLoading(true)
        const response = await request('get-surgeries', 'GET')
        setSurgeries(response.data)
        setLoading(false)
    }
    useEffect(() => {
        document.title = 'Admin | Surgeries'
        getSurgeries()
    }, [])

    const addSurgery = async (data) => {
        setLoading(true)
        await request('add-surgery', 'POST',
            {
                "surgery_short": data.surgery_short,
                "en_desc": data.en_desc,
                "ar_desc": data.ar_desc,
                "speciality": data.speciality,
                "color": data.color,
                "jaw": data.jaw == 'yes' ? true : false,
            }
        )
        getSurgeries()
        reset()
        setLoading(false)
    }
    const filterSurgeries = (surgery) => {
        return surgery.surgery_short.toLowerCase().includes(searchText.toLowerCase());
    };
    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    return (
        <Box backgroundColor={'#F7F7F7'} textAlign={'center'} display={"flex"} flexDirection={"column"} alignItems={"center"} paddingBottom={5}>
            <Box backgroundColor={'background.paper'} width={'100%'} display={"flex"} flexDirection={'column'} alignItems={'center'} paddingY={5} marginBottom={5}>
                <h1 style={{ color: "white" }}>Surgeries</h1>
                <TextField onChange={handleSearchChange} sx={{ backgroundColor: '#F7F7F7', borderRadius: 2, input: { "&::placeholder": { color: "#993cd6", fontWeight: 'bold' }, color: '#B491CB' }, 'fieldset': { borderWidth: 2, borderColor: '#7C5295' }, '&:focus-within fieldset': { borderWidth: 2, borderColor: '#7C5295 !important' }, '&:hover fieldset': { borderWidth: 2, borderColor: '#7C5295 !important' }, '.MuiInputBase-root': { borderRadius: 2 } }} placeholder="Search Surgeries..." />
            </Box>
            <form onSubmit={handleSubmit(addSurgery)} style={{ width: '95%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.surgery_short ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.surgery_short ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('surgery_short', { required: true })} error={!!errors.surgery_short} placeholder={'Surgery Short'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.en_desc ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.en_desc ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('en_desc', { required: true })} error={!!errors.en_desc} placeholder={'English Description'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.ar_desc ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.ar_desc ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('ar_desc', { required: true })} error={!!errors.ar_desc} placeholder={'Arabic Description'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.speciality ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.speciality ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('speciality', { required: true })} error={!!errors.speciality} placeholder={'Speciality'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.color ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.color ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('color', { required: true })} error={!!errors.color} placeholder={'Color'} />
                <FormControl sx={{ '.Mui-focused': { color: 'black !important' } }}>
                    <InputLabel sx={{ color: 'black' }} id="demo-simple-select-label">Jaw</InputLabel>
                    <Select
                        size="small"
                        sx={{ '& fieldset': { borderColor: !!errors.jaw ? '#f44336 !important' : 'black !important' }, width: 200 }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Jaw"
                        {...register('jaw', { required: true })}
                        error={!!errors.jaw}
                    >
                        <MenuItem value={'yes'}>Yes</MenuItem>
                        <MenuItem value={'no'}>No</MenuItem>
                    </Select>
                </FormControl>
                <IconButton type="submit">
                    <img src={Add} alt="Add" width={30} />
                </IconButton>
            </form>
            {surgeries?.filter(filterSurgeries)?.map((surgery, index) => {
                return <Fragment key={index}>
                    <Divider sx={{ width: '95%', marginTop: 2 }} />
                    <Surgery surgery={surgery} getSurgeries={getSurgeries} />
                </Fragment>
            })}
            <Divider sx={{ width: '95%', marginY: 2 }} />
            <Button sx={{ backgroundColor: '#B491CB', boxShadow: 'none', color: 'white', '&:hover': { backgroundColor: '#b583d6', boxShadow: 'none' }, fontWeight: 'bold' }} onClick={() => { setLimit(limit + 1) }} variant="contained">Load More</Button>
        </Box>
    )
}

export default Surgeries