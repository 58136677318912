import { TextField } from "@mui/material"

const DashboardTextField = ({ editInfo, name, type = 'text', register, errors }) => {

    const capitalized = name.charAt(0).toUpperCase() + name.slice(1)

    return (
        <TextField inputProps={
            { readOnly: !editInfo }
        } sx={{
            '&:focus-within fieldset': {
                border: (!!errors[name] && editInfo) ? '2px solid #f44336 !important' : '2px solid black !important',
            },
            '& fieldset': {
                border: editInfo ? !!errors[name] ? '1px solid #f44336 !important' : '1px solid black !important' : '2px solid black !important',
            },

        }} size="small" type={type} {...register(name, { required: true })} error={!!errors[name] && editInfo} label={<span style={{ color: 'black' }}>{capitalized}</span>} />
    )
}

export default DashboardTextField