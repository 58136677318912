import {
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { request } from '../API/Request';
import { AppContext } from '../App';
import Calendar from '../assets/calendar.svg';
import Doctor from '../assets/doctor.svg';
import Files from '../assets/files.svg';
import Home from '../assets/home.svg';
import Info from '../assets/info.svg';
import Pharmacy from '../assets/pharmacy.svg';
import Profile from '../assets/profile.svg';
import Inventory from '../assets/inventory.svg';

const SideMenu = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const {
    openMenu,
    setOpenMenu,
    role,
    setRole,
    isSigned,
    setIsSigned,
    currentUser,
    setCurrentUser,
    setProfilePicture,
    setLoading,
  } = useContext(AppContext);

  const items =
    role === 'patient'
      ? [
          { text: 'Home', icon: Home, navigate: '/' },
          { text: 'Find a Doctor', icon: Doctor, navigate: '/findDoctor' },
          // { text: 'Find a Pharmacy', icon: Pharmacy, navigate: '/findPharmacies' },
          { text: 'My Bookings', icon: Calendar, navigate: '/patientBookings' },
          { text: 'About Us', icon: Info, navigate: '/aboutUs' },
          { text: 'Profile', icon: Profile, navigate: '/profile' },
        ]
      : role === 'doctor'
      ? [
          { text: 'Home', icon: Home, navigate: '/' },
          { text: 'Bookings', icon: Calendar, navigate: '/doctorBookings' },
          { text: 'Files', icon: Files, navigate: '/files' },
          { text: 'Inventory', icon: Inventory, navigate: '/inventory' },
          { text: 'Profile', icon: Profile, navigate: '/doctorInfo' },
        ]
      : role === 'admin'
      ? [
          { text: 'Home', icon: Home, navigate: '/adminDashboard' },
          { text: 'Doctors', icon: Home, navigate: '/adminDashboard/doctors' },
          {
            text: 'Patients',
            icon: Home,
            navigate: '/adminDashboard/patients',
          },
          {
            text: 'Pharmacies',
            icon: Home,
            navigate: '/adminDashboard/pharmacies',
          },
          {
            text: 'Surgeries',
            icon: Home,
            navigate: '/adminDashboard/surgeries',
          },
        ]
      : [
          { text: 'Home', icon: Home, navigate: '/' },
          { text: 'Find a Doctor', icon: Doctor, navigate: '/findDoctor' },
          // { text: 'Find a Pharmacy', icon: Pharmacy, navigate: '/findPharmacies' },
          { text: 'About Us', icon: Info, navigate: '/aboutUs' },
        ];

  const logOut = async () => {
    setLoading(true);
    const response = await request(
      'log-out',
      'POST',
      role === 'patient'
        ? {
            patient_id: currentUser?.patient_id,
            role: role,
            email: currentUser?.email,
            token: localStorage.getItem('token'),
          }
        : role === 'doctor'
        ? {
            doctor_id: currentUser?.doctor_id,
            role: role,
            email: currentUser?.email,
            token: localStorage.getItem('token'),
          }
        : {
            role: role,
            email: currentUser?.email,
            token: localStorage.getItem('token'),
          }
    );
    if (response.success) {
      setIsSigned(false);
      setRole('guest');
      setCurrentUser({});
      setProfilePicture('');
      navigate('/', { replace: true });
      localStorage.removeItem('token');
    }
    setOpenMenu(false);
    setLoading(false);
  };

  return (
    <Drawer
      transitionDuration={700}
      PaperProps={{ sx: { width: 250, backgroundColor: 'white' } }}
      open={openMenu}
      anchor='right'
      onClose={() => {
        setOpenMenu(false);
      }}
    >
      <List
        disablePadding
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        {items.map((item, index) => {
          return (
            <Fragment key={index}>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate(item.navigate, {
                      replace: window.location.pathname === item.navigate,
                    });
                    setOpenMenu(false);
                  }}
                  sx={{ paddingY: 2 }}
                >
                  <ListItemIcon>
                    <img src={item.icon} alt={item.text} width={30} />
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
              <Divider />
            </Fragment>
          );
        })}
        {isSigned ? (
          <>
            <Button
              sx={{
                backgroundColor: '#B491CB',
                boxShadow: 'none',
                '&:hover': { backgroundColor: '#b583d6', boxShadow: 'none' },
                fontWeight: 'bold',
                width: '90%',
                marginTop: 2,
              }}
              onClick={() => {
                logOut();
              }}
              color='secondary'
              variant='contained'
            >
              {t('Logout')}
            </Button>
          </>
        ) : (
          <>
            <Button
              sx={{
                borderColor: '#B491CB',
                color: '#B491CB',
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: '#B491CB',
                  border: '1px solid #B491CB',
                  color: 'white',
                  transition: '1s',
                },
                transition: '1s',
                fontWeight: 'bold',
                width: '90%',
                marginTop: 2,
              }}
              onClick={() => {
                navigate('/login');
                setOpenMenu(false);
              }}
              color='secondary'
              variant='outlined'
            >
              {t('Sign-in')}
            </Button>
            <Button
              sx={{
                backgroundColor: '#B491CB',
                boxShadow: 'none',
                '&:hover': { backgroundColor: '#b583d6', boxShadow: 'none' },
                fontWeight: 'bold',
                width: '90%',
                marginTop: 2,
              }}
              onClick={() => {
                navigate('/patientSignup');
                setOpenMenu(false);
              }}
              color='secondary'
              variant='contained'
            >
              {t('Sign-up')}
            </Button>
          </>
        )}
        {i18n.language === 'en' ? (
          <Button
            onClick={() => {
              i18n.changeLanguage('ar');
              localStorage.setItem('language', JSON.stringify('ar'));
            }}
            sx={{
              color: 'black',
              fontWeight: 'bold',
              border: '2px black solid',
              borderRadius: 0,
              width: '90%',
              marginTop: 2,
            }}
            disableRipple
          >
            ع
          </Button>
        ) : (
          <Button
            onClick={() => {
              i18n.changeLanguage('en');
              localStorage.setItem('language', JSON.stringify('en'));
            }}
            sx={{
              color: 'black',
              fontWeight: 'bold',
              border: '2px black solid',
              borderRadius: 0,
              width: '90%',
              marginTop: 2,
            }}
            disableRipple
          >
            En
          </Button>
        )}
      </List>
    </Drawer>
  );
};

export default SideMenu;
