import { Box, Button, Card, Grid, IconButton, InputAdornment, Menu, MenuItem, TextField } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { request } from "../API/Request"
import { AppContext } from "../App"
import Add from '../assets/add2.svg'
import Filter from '../assets/filter.svg'
import Remove from '../assets/remove.svg'
import Search from '../assets/search.svg'
import DeleteConfirmation from "../components/DeleteConfirmation"
import InventoryTextField from "../components/InventoryTextField"
import Product from "../components/Product"

const Inventory = () => {

    const { currentUser, setLoading, sm } = useContext(AppContext)
    const { register, handleSubmit, formState: { errors }, reset } = useForm()
    const [removeConfirm, setRemoveConfirm] = useState(false)
    const [add, setAdd] = useState(false)
    const [products, setProducts] = useState([])
    const [productQuantity, setProductQuantity] = useState(0)
    const [productPrice, setProductPrice] = useState(0)
    const [totalPrice, setTotalPrice] = useState(0)
    const [searchText, setSearchText] = useState('')
    const [selected, setSelected] = useState([])
    const [anchorEl, setAnchorEl] = useState(null)
    const [sort, setSort] = useState(0)
    const open = Boolean(anchorEl)

    const getProducts = async () => {
        setLoading(true)
        const response = await request(`get-products/${currentUser?.doctor_id}/${sort}`, 'GET')
        if (response?.success) {
            setProducts(response?.data)
        }
        setLoading(false)
    }
    useEffect(() => {
        document.title = 'Weqayah | Inventory'
        getProducts()
    }, [sort])

    const addProduct = async (data) => {
        setLoading(true)
        await request('add-product', 'POST', {
            product_name: data.product_name,
            product_quantity: productQuantity,
            seller_name: data.seller_name,
            seller_number: data.seller_number,
            product_price: productPrice,
            doctor_id: currentUser?.doctor_id
        })
        setLoading(false)
        getProducts()
        setAdd(false)
    }
    const deleteProduct = async (id) => {
        await request(`delete-product/${id}`, 'DELETE')
        getProducts()
    }
    const deleteProducts = async () => {
        await request('delete-products', 'DELETE', { ids: selected })
        getProducts()
    }
    const handleProductQuantityChange = (newValue) => {
        const newProductQuantity = parseFloat(newValue) || 0
        setProductQuantity(newProductQuantity)
        updateTotalPrice(newProductQuantity, productPrice)
    }
    const handleProductPriceChange = (newValue) => {
        const newProductPrice = parseFloat(newValue) || 0
        setProductPrice(newProductPrice)
        updateTotalPrice(productQuantity, newProductPrice)
    }
    const updateTotalPrice = (newProductQuantity, newProductPrice) => {
        const totalPrice = newProductQuantity * newProductPrice
        setTotalPrice(totalPrice)
    }
    const filterProducts = (product) => {
        return product.product_name.toLowerCase().includes(searchText.toLowerCase())
    }
    const handleSearchChange = (event) => {
        setSearchText(event.target.value)
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = (event) => {
        if (event.target.value !== sort && event.target.value !== undefined)
            setSort(event.target.value || 0)
        setAnchorEl(null)
    }

    return (
        <>
            <DeleteConfirmation open={removeConfirm} setOpen={setRemoveConfirm} onClick={deleteProducts} text={selected?.length === products?.length ? 'Deleting wil permanently remove all inventory items from the system. This cannot be undone!' : 'Deleting wil permanently remove item(s) from the system. This cannot be undone!'} />
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose} >
                <MenuItem sx={{ color: 'white' }} value={0} selected={sort === 0} onClick={handleClose}>Default</MenuItem>
                <MenuItem sx={{ color: 'white' }} value={1} selected={sort === 1} onClick={handleClose}>By Product Name</MenuItem>
                <MenuItem sx={{ color: 'white' }} value={2} selected={sort === 2} onClick={handleClose}>By Seller Name</MenuItem>
                <MenuItem sx={{ color: 'white' }} value={3} selected={sort === 3} onClick={handleClose}>By Product Price</MenuItem>
                <MenuItem sx={{ color: 'white' }} value={4} selected={sort === 4} onClick={handleClose}>By Total Price</MenuItem>
            </Menu>
            <Box backgroundColor={'background.paper'} display={"flex"} justifyContent={"center"} paddingY={5}>
                <h1 style={{ color: "white" }}>Inventory</h1>
            </Box>
            <Box paddingX={sm ? 3 : 0} paddingBottom={3}>
                <Box display={"flex"} alignItems={"center"} justifyContent={'flex-end'} marginY={2}>
                    <Button variant="contained" style={{ backgroundColor: '#B491CB', color: '#ffffff', paddingRight: 50, paddingLeft: 50, fontSize: 25, fontWeight: 'bold', marginInlineEnd: !sm && 10 }}>Export</Button>
                </Box>
                <Card sx={{ display: 'flex', flexDirection: 'column', backgroundColor: sm ? '#f0eded' : 'inherit', boxShadow: sm ? 10 : 0, borderRadius: 2 }}>
                    <Box display={"flex"} flexDirection={!sm ? 'column-reverse' : 'row'} justifyContent={'space-between'} alignItems={"center"} paddingTop={2} paddingX={sm ? 2 : 0}>
                        <TextField
                            onChange={handleSearchChange}
                            placeholder="Search Product.."
                            style={{ backgroundColor: "#B491CB", borderRadius: 5 }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <img src={Search} alt="Search" width={30} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} sx={{
                                '& input': {
                                    color: 'white',
                                },
                                '&:focus-within fieldset': {
                                    borderColor: 'transparent !important',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'transparent !important',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'transparent',
                                },
                            }}
                        />
                        <Box marginBottom={!sm ? 3 : 0}>
                            <IconButton onClick={() => { setAdd(!add); setTotalPrice(0); reset() }}>
                                <img src={Add} alt="Add" width={50} />
                            </IconButton>
                            <IconButton onClick={() => { setRemoveConfirm(true) }}>
                                <img src={Remove} alt="Remove" width={35} />
                            </IconButton>
                            <IconButton onClick={handleClick}>
                                <img src={Filter} alt="Filter" width={50} />
                            </IconButton>
                        </Box>
                    </Box>
                    <Grid container marginY={2}>
                        {sm && <Grid item xs={1.333333333333333} textAlign={"center"}>Select</Grid>}
                        {sm && <Grid item xs={1.333333333333333} textAlign={"center"}>Product ID</Grid>}
                        {sm && <Grid item xs={1.333333333333333} textAlign={"center"}>Product Name</Grid>}
                        {sm && <Grid item xs={1.333333333333333} textAlign={"center"}>Product Quantity</Grid>}
                        {sm && <Grid item xs={1.333333333333333} textAlign={"center"}>Seller Name</Grid>}
                        {sm && <Grid item xs={1.333333333333333} textAlign={"center"}>Seller Number</Grid>}
                        {sm && <Grid item xs={1.333333333333333} textAlign={"center"}>Product Price</Grid>}
                        {sm && <Grid item xs={1.333333333333333} textAlign={"center"}>Total Price</Grid>}
                        {sm && <Grid item xs={1.333333333333333} textAlign={"center"}>Action</Grid>}
                        <Grid container item xs={12} backgroundColor={'white'} marginX={2} marginTop={2} boxShadow={6} borderRadius={2}>
                            {add && <Grid container item xs={12} display={"flex"} alignItems={"center"} marginY={1}>
                                <Grid item xs={1.333333333333333} textAlign={"center"}>-</Grid>
                                <Grid item xs={1.333333333333333} textAlign={"center"}>-</Grid>
                                <Grid item xs={1.333333333333333} textAlign={"center"}><InventoryTextField register={register} name={'product_name'} errors={errors} placeholder={'Product Name'} autoFocus={true} /></Grid>
                                <Grid item xs={1.333333333333333} textAlign={"center"}><TextField onChange={(event) => handleProductQuantityChange(event.target.value)} InputProps={{ inputProps: { type: 'number', pattern: '[0-9]*' }, style: { background: 'white' } }} sx={{ '&:focus-within fieldset': { borderColor: 'black !important' }, '& .MuiFormHelperText-root': { color: 'black' }, '& .MuiOutlinedInput-notchedOutline': { borderColor: 'black' }, marginX: 1 }} placeholder={'Product Quantity'} /></Grid>
                                <Grid item xs={1.333333333333333} textAlign={"center"}><InventoryTextField register={register} name={'seller_name'} errors={errors} placeholder={'Seller Name'} /></Grid>
                                <Grid item xs={1.333333333333333} textAlign={"center"}><InventoryTextField register={register} name={'seller_number'} errors={errors} placeholder={'Seller Number'} /></Grid>
                                <Grid item xs={1.333333333333333} textAlign={"center"}><TextField onChange={(event) => handleProductPriceChange(event.target.value)} InputProps={{ inputProps: { type: 'number', pattern: '[0-9]*' }, style: { background: 'white' } }} sx={{ '&:focus-within fieldset': { borderColor: 'black !important', }, '& .MuiFormHelperText-root': { color: 'black' }, '& .MuiOutlinedInput-notchedOutline': { borderColor: 'black' }, marginX: 1 }} placeholder={'Product Price'} /></Grid>
                                <Grid item xs={1.333333333333333} textAlign={"center"}>{totalPrice}JD</Grid>
                                <Grid item xs={1.333333333333333} textAlign={"center"}>
                                    <Box display={"flex"} justifyContent={"center"}>
                                        <IconButton onClick={handleSubmit(addProduct)}>
                                            <img src={Add} alt="Add" width={50} />
                                        </IconButton>
                                    </Box>
                                </Grid>
                            </Grid>}
                            {products?.filter(filterProducts)?.map((product, index) => <Product key={index} index={index + 1} getProducts={getProducts} deleteProduct={deleteProduct} setSelected={setSelected} id={product.product_id} name={product.product_name} quantity={product.product_quantity} seller_name={product.seller_name} seller_number={product.seller_number} price={product.product_price} total={product.product_quantity * product.product_price} />)}
                        </Grid>
                    </Grid>
                </Card>
            </Box>
        </>
    )
}

export default Inventory