import { Box, Button, Card, TextField, Typography } from "@mui/material"
import { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import { request } from "../../API/Request"
import { AppContext } from "../../App"
import { useNavigate } from "react-router-dom"

const ForgotPassword = () => {

    const navigate = useNavigate()
    const { xs, setLoading } = useContext(AppContext)
    const { register, handleSubmit, formState: { errors } } = useForm()
    const [errorMesage, setErrorMessage] = useState('')

    const sendRecoveryEmail = async (data) => {
        const otp = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
        setLoading(true)
        const response = await request('forgot-password', 'POST', { "email": data.email, "otp": otp })
        if (response.success) {
            navigate('/otpPage', { state: { "otp": otp, "email": data.email } })
        } else {
            setErrorMessage('Something went wrong')
        }
        setLoading(false)
    }

    return (
        <Box width={'100vw'} height={'100vh'} display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Card sx={{ width: xs ? 500 : '100%', height: xs ? 'auto' : '100%', display: "flex", flexDirection: 'column', alignItems: 'center', paddingTop: !xs && 60, paddingBottom: 3 }} elevation={8}>
                <h2>Please Enter Your Email</h2>
                <TextField sx={{
                    '&:focus-within fieldset': {
                        borderColor: 'black !important',
                    },
                    '& .MuiFormHelperText-root': {
                        color: 'black',
                    },
                    marginBottom: 2,
                    width: 300
                }} {...register('email', { required: 'Email is required' })} error={!!errors.email} />
                {errors.email && <Typography color={'error.main'} marginBottom={2}>{errors.email?.message}</Typography>}
                {errorMesage && <Typography color={'error.main'} marginBottom={2}>{errorMesage}</Typography>}
                <Button onClick={handleSubmit(sendRecoveryEmail)} color="secondary" variant="contained">Submit</Button>
            </Card>
        </Box>
    )
}

export default ForgotPassword