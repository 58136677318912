import { Box, Grid } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { request } from "../API/Request"
import { AppContext } from "../App"
import PatientBookingCard from "../components/PatientBookingCard"

const PatientBookings = () => {

    const { currentUser, setLoading, sm } = useContext(AppContext)
    const { t, i18n } = useTranslation()
    const textDirection = i18n.language === 'ar' ? 'rtl' : 'ltr'
    const [bookings, setBookings] = useState([])
    const [expiredBookings, setExpiredBookings] = useState([])

    const getBookings = async () => {
        setLoading(true)
        const response = await request(`get-patient-bookings/${currentUser.patient_id}`, 'GET')
        if (response.success) {
            setBookings(response.bookings)
            setExpiredBookings(response.expiredBookings)
        }
        setLoading(false)
    }
    useEffect(() => {
        document.title = 'Weqayah | My Bookings'
        if (currentUser.patient_id) {
            getBookings()
        }
    }, [currentUser])

    return (
        <>
            <Box backgroundColor={'background.paper'} color={'white'} display={"flex"} justifyContent={"center"} paddingY={5}>
                <h1>{t('Bookings Info')}</h1>
            </Box>
            <Grid container dir={textDirection} paddingTop={1}>
                <Grid item xs={12} md={6} display={"flex"} flexDirection={"column"} alignItems={"center"} borderRight={(textDirection === 'ltr' && sm) && 1} borderBottom={!sm && 1} paddingBottom={5}>
                    <h2>{t('Bookings in progress')}</h2>
                    <Box width={'90%'} display={"flex"} justifyContent={'space-between'} alignItems={"center"} border={2} borderColor={'background.paper'} borderRadius={5} paddingX={1} marginBottom={2}>
                        <h3 style={{ borderInlineEnd: '2px solid', width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 5, paddingRight: 5 }}>{'Clinic'}</h3>
                        <h3 style={{ borderInlineEnd: '2px solid', width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 5, paddingRight: 5 }}>{'Location'}</h3>
                        <h3 style={{ borderInlineEnd: '2px solid', width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 5, paddingRight: 5 }}>{'Time'}</h3>
                        <h3 style={{ borderInlineEnd: '2px solid', width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 5, paddingRight: 5 }}>{'Date'}</h3>
                        <h3 style={{ width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 5, paddingRight: 5 }}>{'Status'}</h3>
                    </Box>
                    {bookings.map((booking, index) => <PatientBookingCard key={index} getBookings={getBookings} appointment_id={booking.appointment_id} booking_id={booking.booking_id} time={booking.booking_start_time} date={booking.booking_date} status={booking.booking_status} clinic_name={booking.clinic_name} clinic_location={booking.location_text} />)}
                </Grid>
                <Grid item xs={12} md={6} display={"flex"} flexDirection={"column"} alignItems={"center"} borderRight={(textDirection === 'rtl' && sm) && 1} paddingBottom={5}>
                    <h2>{t('Expired bookings')}</h2>
                    <Box width={'90%'} display={"flex"} justifyContent={'space-between'} alignItems={"center"} border={2} borderColor={'background.paper'} borderRadius={5} paddingX={1} marginBottom={2}>
                        <h3 style={{ borderInlineEnd: '2px solid', width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 5, paddingRight: 5 }}>{'Clinic'}</h3>
                        <h3 style={{ borderInlineEnd: '2px solid', width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 5, paddingRight: 5 }}>{'Location'}</h3>
                        <h3 style={{ borderInlineEnd: '2px solid', width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 5, paddingRight: 5 }}>{'Time'}</h3>
                        <h3 style={{ borderInlineEnd: '2px solid', width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 5, paddingRight: 5 }}>{'Date'}</h3>
                        <h3 style={{ width: '100%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 5, paddingRight: 5 }}>{'Status'}</h3>
                    </Box>
                    {expiredBookings.map((booking, index) => <PatientBookingCard key={index} getBookings={getBookings} time={booking.booking_start_time} date={booking.booking_date} status={booking.booking_status} clinic_name={booking.clinic_name} clinic_location={booking.location_text} expired={true} />)}
                </Grid>
            </Grid>
        </>
    )
}

export default PatientBookings