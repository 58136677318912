import { Button, Card } from "@mui/material"
import { useNavigate } from "react-router-dom"

const DashboardCard = ({ title, number }) => {

    const navigate = useNavigate()

    return (
        <Card elevation={10} sx={{ width: '80%', height: 300, backgroundColor: '#F7F7F7', color: '#B491CB', borderRadius: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <h2>Number of {title}</h2>
            <h2>{number}</h2>
        </Card>
    )
}

export default DashboardCard