import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AppContext } from "../App";

const ProtectedRoute = ({ path, children }) => {

    const { isSigned, role } = useContext(AppContext)

    if (!role) {
        return
    }

    if (path === '/patientSignup' || path === '/doctorSignup' || path === '/login') {
        if (isSigned) {
            return <Navigate to="/" replace />;
        }
    } else if (path.slice(0, 15) === '/adminDashboard') {
        if (role !== 'admin') {
            return <Navigate to="/" replace />;
        }
    } else if (path === '/patientBookings' || path === '/profile') {
        if (role !== 'patient') {
            return <Navigate to="/" replace />;
        }
    } else if (path === '/doctorBookings' || path === '/inventory' || path.slice(0, 6) === '/files') {
        if (role !== 'doctor') {
            return <Navigate to="/" replace />;
        }
    } else {
        if (!isSigned) {
            return <Navigate to="/" replace />;
        }
    }
    return children;
}

export default ProtectedRoute