import { Button, Dialog, DialogContent, DialogTitle, IconButton, InputAdornment, TextField } from "@mui/material"
import { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import { AppContext } from "../App"
import { request } from "../API/Request"
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const ChangePasswordDialog = ({ open, setOpen }) => {

    const { register, handleSubmit, formState: { errors }, reset, setError } = useForm()
    const { currentUser } = useContext(AppContext)
    const [showPassword1, setShowPassword1] = useState(false)
    const [showPassword2, setShowPassword2] = useState(false)
    const [showPassword3, setShowPassword3] = useState(false)

    const resetPassword = async (data) => {
        if (data.newPassword === data.confirmPassword) {
            const result = await request('reset-password', 'PUT', { doctor_id: currentUser?.doctor_id, oldPassword: data.oldPassword, newPassword: data.newPassword })
            if (result.success) {
                reset()
                setOpen(false)
            } else {
                setError('oldPassword', { message: 'Old password is not correct' })
            }
        } else {
            setError('newPassword', { message: 'Passwords do not match' })
            setError('confirmPassword', { message: 'Passwords do not match' })
        }
    }

    return (
        <Dialog sx={{ '.MuiPaper-root': { backgroundColor: '#F7F7F7', borderRadius: 4, padding: 2 } }} open={open} onClose={() => { setOpen(false); reset({ oldPassword: '', newPassword: '', confirmPassword: '' }); setShowPassword1(false); setShowPassword2(false); setShowPassword3(false) }}>
            <DialogTitle>
                <h3 style={{ color: '#B491CB', margin: 0 }}>Reset Password</h3>
                <h5 style={{ margin: 0, fontWeight: 'normal' }}>Please enter and confirm your new password.</h5>
                <h5 style={{ margin: 0, fontWeight: 'normal' }}>Minimum of 8 characters.</h5>
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
                <label>Old Password</label>
                <TextField sx={{
                    '&:focus-within fieldset': {
                        borderColor: 'black !important',
                    },
                    '& fieldset': {
                        borderColor: 'black !important',
                    },
                    marginY: 1
                }} InputProps={{
                    endAdornment:
                        <InputAdornment position="end">
                            <IconButton onClick={() => { setShowPassword1(!showPassword1) }} edge="end">
                                {showPassword1 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                        </InputAdornment>
                }} type={showPassword1 ? 'text' : 'password'} {...register('oldPassword', { required: 'Old password is required' })} error={errors?.oldPassword && true} />
                {errors?.oldPassword && <p style={{ textAlign: 'end', color: '#f44336', margin: 0 }}>{errors?.oldPassword.message}.</p>}
                <label>New Password</label>
                <TextField sx={{
                    '&:focus-within fieldset': {
                        borderColor: 'black !important',
                    },
                    '& fieldset': {
                        borderColor: 'black !important',
                    },
                    marginY: 1
                }} InputProps={{
                    endAdornment:
                        <InputAdornment position="end">
                            <IconButton onClick={() => { setShowPassword2(!showPassword2) }} edge="end">
                                {showPassword2 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                        </InputAdornment>
                }} type={showPassword2 ? 'text' : 'password'} {...register('newPassword', { required: 'New password is required' })} error={errors?.newPassword && true} />
                {errors?.newPassword && <p style={{ textAlign: 'end', color: '#f44336', margin: 0 }}>{errors?.newPassword.message}.</p>}
                <label>Confirm New Password</label>
                <TextField sx={{
                    '&:focus-within fieldset': {
                        borderColor: 'black !important',
                    },
                    '& fieldset': {
                        borderColor: 'black !important',
                    },
                    marginY: 1
                }} InputProps={{
                    endAdornment:
                        <InputAdornment position="end">
                            <IconButton onClick={() => { setShowPassword3(!showPassword3) }} edge="end">
                                {showPassword3 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                        </InputAdornment>
                }} type={showPassword3 ? 'text' : 'password'} {...register('confirmPassword', { required: 'Confirm password is required' })} error={errors?.confirmPassword && true} />
                {errors?.confirmPassword && <p style={{ textAlign: 'end', color: '#f44336', margin: 0 }}>{errors?.confirmPassword.message}.</p>}
                <Button sx={{ marginTop: 2, backgroundColor: '#B491CB', boxShadow: 'none', color: 'white', padding: 2, '&:hover': { backgroundColor: '#b583d6', boxShadow: 'none' }, fontWeight: 'bold', fontSize: 16 }} onClick={handleSubmit(resetPassword)} variant="contained">Reset Password</Button>
            </DialogContent>
        </Dialog>
    )
}

export default ChangePasswordDialog