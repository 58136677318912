import { Box, IconButton } from "@mui/material"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { request } from "../API/Request"
import Cancel from '../assets/cancel.svg'
import Confirm from '../assets/confirm.svg'
import Edit from '../assets/edit.svg'
import Remove from '../assets/remove.svg'
import DashboardTextField from "./DashboardTextField"

const Pharmacy = ({ pharmacy, getPharmacies }) => {

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        values: pharmacy
    })
    const [editInfo, setEditInfo] = useState(false)

    const confirmEditInfo = async (data) => {
        await request('update-pharmacy', 'PUT',
            {
                "pharmacy_id": data.pharmacy_id,
                "pname": data.pname,
                "plocation_url": data.plocation_url,
                "pimage_url": data.pimage_url,
                "open_time": data.open_time,
                "close_time": data.close_time,
                "fopen_time": data.fopen_time,
                "fclose_time": data.fclose_time,
                "phone_number": data.phone_number
            }
        )
        getPharmacies()
        setEditInfo(false)
    }
    const deletePharmacy = async () => {
        await request(`delete-pharmacy/${pharmacy.pharmacy_id}`, 'DELETE')
        getPharmacies()
    }

    return (
        <Box
            width={'95%'} display={"flex"} justifyContent={'space-evenly'} alignItems={'center'} marginTop={2}>
            <h2 style={{ marginRight: 5 }}>{pharmacy.pharmacy_id}</h2>
            <DashboardTextField editInfo={editInfo} register={register} name={'pname'} errors={errors} />
            <DashboardTextField editInfo={editInfo} register={register} name={'plocation_url'} errors={errors} />
            <DashboardTextField editInfo={editInfo} register={register} name={'pimage_url'} errors={errors} />
            <DashboardTextField editInfo={editInfo} register={register} name={'open_time'} errors={errors} type="time" />
            <DashboardTextField editInfo={editInfo} register={register} name={'close_time'} errors={errors} type="time" />
            <DashboardTextField editInfo={editInfo} register={register} name={'fopen_time'} errors={errors} type="time" />
            <DashboardTextField editInfo={editInfo} register={register} name={'fclose_time'} errors={errors} type="time" />
            <DashboardTextField editInfo={editInfo} register={register} name={'phone_number'} errors={errors} />
            {editInfo ?
                <>
                    <IconButton onClick={handleSubmit(confirmEditInfo)}>
                        <img src={Confirm} alt="Edit" width={40} />
                    </IconButton>
                    <IconButton onClick={() => { reset(); setEditInfo(false) }}>
                        <img src={Cancel} alt="Edit" width={40} />
                    </IconButton>
                </>
                : <>
                    <IconButton onClick={() => { setEditInfo(true) }}>
                        <img src={Edit} alt="Edit" width={40} />
                    </IconButton>
                    <IconButton onClick={() => { deletePharmacy() }}>
                        <img src={Remove} alt="Remove" width={30} />
                    </IconButton>
                </>
            }
        </Box>
    )
}

export default Pharmacy