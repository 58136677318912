import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";

const CustomTextField = ({ name, type = 'text', register, errors, autoFocus, helperText, label }) => {

    const [showPassword, setShowPassword] = useState(false)

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <TextField
            fullWidth
            size='small'
            sx={{
                ".MuiFormHelperText-root": {
                    marginLeft: 0,
                },
                ".MuiInputBase-root": {
                    backgroundColor: 'white',
                    borderRadius: 0,
                },
                '&:hover fieldset': {
                    borderColor: 'white !important',
                },
                '&:focus-within fieldset': {
                    border: '0px !important',
                },
                'fieldset': {
                    border: '0px !important',
                    borderRadius: 0,
                },
                input: { color: '#B491CB' },
                marginBottom: 1
            }}
            type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
            helperText={helperText}
            {...register(name, {
                required: `${label} is required`,
                minLength: name === 'password' && 8,
            })}
            required
            error={!!errors[name]}
            autoFocus={autoFocus}
            InputProps={{
                endAdornment:
                    type === 'password' ? (
                        <InputAdornment position="end">
                            <IconButton onClick={togglePasswordVisibility} edge="end">
                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                        </InputAdornment>
                    ) : null,
            }}
        />
    );
};

export default CustomTextField;
