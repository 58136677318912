import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../App';
import { useForm } from 'react-hook-form';
import { request } from '../API/Request';

const ScheduleButton = ({
  startTime,
  endTime,
  patientName,
  selected,
  appointment,
  setSelectedAppointment,
  getAppointments,
  setData,
}) => {
  const { t } = useTranslation();
  const { role, xs } = useContext(AppContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [doctorBookDialog, setDoctorBookDialog] = useState(false);
  let period = 'AM';

  function subtract12Hours(timeString) {
    const time = timeString.split(':');
    let hours = parseInt(time[0]);
    const minutes = time[1];

    if (hours >= 12) {
      if (hours > 12) {
        hours -= 12;
      }
      period = 'PM';
    }
    const formattedHours = hours.toString().padStart(2, '0');
    return `${formattedHours}:${minutes}`;
  }
  const patientClick = () => {
    setSelectedAppointment(appointment);
  };
  const doctorClick = () => {
    setDoctorBookDialog(true);
  };
  const doctorBook = async (data) => {
    await request('add-booking', 'POST', {
      booking_date: appointment.date,
      appointment_id: appointment.appointment_id,
      booking_start_time: appointment.start_time,
      booking_end_time: appointment.end_time,
      patient_id: data.phone_number,
      booking_status: 'Accepted',
      description: data.description,
      phone_number: data.phone_number,
      guest: true,
    });
    await request('update-appointment', 'PUT', {
      appointment_id: appointment.appointment_id,
      booking_status: true,
      patient_name: data.patient_name,
    });
    reset();
    setDoctorBookDialog(false);
  };
  const deleteAppointment = async () => {
    const response = await request(
      `delete-appointment/${appointment.appointment_id}`,
      'DELETE'
    );
    if (response.success) {
      setDoctorBookDialog(false);
      getAppointments();
    }
  };

  return (
    <>
      <Dialog
        sx={{
          '.MuiPaper-root': {
            backgroundColor: '#F7F7F7',
            borderRadius: 5,
            width: 500,
          },
        }}
        open={doctorBookDialog}
        onClose={() => {
          setDoctorBookDialog(false);
          reset();
        }}
      >
        <DialogTitle
          display={'flex'}
          justifyContent={'center'}
          textAlign={'center'}
          color={'#36454F'}
          margin={2}
          fontSize={26}
          fontWeight={'bold'}
        >
          Book an Appointment
          <br /> for Your Patient
        </DialogTitle>
        <DialogContent
          sx={{
            borderBottom: 2,
            borderColor: '#B2BEB5',
          }}
        >
          <Grid container alignItems={'center'}>
            <Grid item xs={12} sm={6}>
              <h3
                style={{
                  color: '#36454F',
                  margin: 0,
                  textAlign: !xs && 'center',
                  marginBottom: !xs && 12,
                  marginTop: !xs && 0,
                }}
              >
                Patient Name
              </h3>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              display={'flex'}
              justifyContent={!xs && 'center'}
            >
              <TextField
                size='small'
                sx={{
                  '& fieldset': {
                    border: '2px solid #B2BEB5',
                    borderRadius: 0,
                  },
                  '&:focus-within fieldset': {
                    borderColor: '#B2BEB5 !important',
                  },
                  '&:hover fieldset': {
                    borderColor: '#B2BEB5 !important',
                  },
                  '& .MuiFormHelperText-root': {
                    color: 'black',
                  },
                  marginBottom: 1,
                  marginLeft: 1,
                }}
                {...register('patient_name', {
                  required: 'Patient Name is required',
                })}
                error={!!errors.patient_name}
              />
            </Grid>
          </Grid>
          <Grid container alignItems={'center'}>
            <Grid item xs={12} sm={6}>
              <h3
                style={{
                  color: '#36454F',
                  margin: 0,
                  textAlign: !xs && 'center',
                  marginBottom: !xs && 12,
                  marginTop: !xs && 12,
                }}
              >
                Phone Number
              </h3>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              display={'flex'}
              justifyContent={!xs && 'center'}
            >
              <TextField
                size='small'
                sx={{
                  '& fieldset': {
                    border: '2px solid #B2BEB5',
                    borderRadius: 0,
                  },
                  '&:focus-within fieldset': {
                    borderColor: '#B2BEB5 !important',
                  },
                  '&:hover fieldset': {
                    borderColor: '#B2BEB5 !important',
                  },
                  '& .MuiFormHelperText-root': {
                    color: 'black',
                  },
                  marginBottom: 1,
                  marginLeft: 1,
                }}
                {...register('phone_number', {
                  required: 'Phone Number is required',
                })}
                error={!!errors.phone_number}
              />
            </Grid>
          </Grid>
          <Grid container alignItems={'center'}>
            <Grid item xs={12} sm={6}>
              <h3
                style={{
                  color: '#36454F',
                  margin: 0,
                  textAlign: !xs && 'center',
                  marginBottom: !xs && 12,
                  marginTop: !xs && 12,
                }}
              >
                Description
              </h3>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              paddingInlineStart={1}
              display={'flex'}
              justifyContent={!xs && 'center'}
            >
              <TextareaAutosize
                {...register('description')}
                style={{
                  resize: 'none',
                  '&:focused': {
                    borderColor: '#B2BEB5 !important',
                  },
                  '&:hover': {
                    borderColor: '#B2BEB5 !important',
                  },
                  border: '2px solid #B2BEB5',
                  borderRadius: 0,
                  backgroundColor: 'transparent',
                  width: 186,
                  height: 100,
                  fontSize: 20,
                  padding: 10,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button
            onClick={() => {
              deleteAppointment();
            }}
            sx={{ color: '#B491CB', textDecoration: 'underline' }}
          >
            Delete Appointment
          </Button>
          <Box>
            <Button
              disableElevation
              sx={{
                marginInlineEnd: 1,
                borderWidth: 2,
                borderRadius: 2,
                backgroundColor: '#B491CB',
                fontWeight: 'bold',
                '&:hover': {
                  borderWidth: 2,
                  borderRadius: 2,
                  backgroundColor: '#B491CB',
                  fontWeight: 'bold',
                },
              }}
              onClick={() => {
                setDoctorBookDialog(false);
                reset();
              }}
              color='error'
              variant='contained'
            >
              {t('Cancel')}
            </Button>
            <Button
              disableElevation
              sx={{ borderRadius: 2, border: '1px solid #838383' }}
              onClick={handleSubmit(doctorBook)}
              color='success'
              variant='contained'
            >
              {t('Book')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Button
        disableRipple
        onClick={() => {
          if (!!patientName) {
            setData(
              appointment?.description,
              appointment?.patient_name,
              appointment?.tab_name,
              appointment?.phone_number
            );
          } else role !== 'doctor' ? patientClick() : doctorClick();
        }}
        disabled={!!!startTime}
        sx={{
          width: '100%',
          height: '100%',
          borderRadius: 0,
          textAlign: 'center',
          fontSize: xs ? 16 : 12,
          padding: xs ? '8px 12px' : '2px 4px',
          fontWeight: 'bold',
          color: selected ? 'white' : '#7C5295',
          backgroundColor: selected && '#7C5295',
          '&:hover': {
            backgroundColor: selected && '#7C5295',
          },
        }}
      >
        {startTime && `${subtract12Hours(startTime)} ${t('To')}`}{' '}
        {!xs && <br />}{' '}
        {endTime ? `${subtract12Hours(endTime)} ${t(period)}` : '—'}
        {patientName && (
          <>
            <br />
            {patientName}
          </>
        )}
      </Button>
    </>
  );
};

export default ScheduleButton;
