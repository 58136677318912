import { Avatar, Box, Button, Card, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { request } from '../API/Request';
import { AppContext } from '../App';
import Hero from '../assets/mainbackground.png';
import MedicalAssisting from '../assets/medical_assisting.png';
import Meds from '../assets/meds2.jpg';
import Footer from '../components/Footer';
import SearchSection from '../components/SearchSection';
import ServiceCard from '../components/ServiceCard';

const Home = () => {

    const navigate = useNavigate()
    const { xs, sm, role, currentUser, setLoading, profilePicture } = useContext(AppContext)
    const searchSectionRef = useRef(null);
    const { t, i18n } = useTranslation()
    const textDirection = i18n.language === 'ar' ? 'rtl' : 'ltr'
    const [clinics, setClinics] = useState([])
    const [insurances, setInsurances] = useState([])

    const getClinicsAndHospitals = async () => {
        setLoading(true)
        const response = await request(`get-clinics_and_hospitals/${currentUser?.doctor_id}`, 'GET')
        setClinics(response)
        setLoading(false)
    }
    const getInsurances = async () => {
        setLoading(true)
        const response = await request(`get-insurances/${currentUser?.doctor_id}`, 'GET')
        setInsurances(response)
        setLoading(false)
    }
    useEffect(() => {
        document.title = 'Weqayah | Home'
        if (currentUser?.doctor_id) {
            getClinicsAndHospitals()
            getInsurances()
        }
    }, [currentUser])

    const scrollToSearchSection = () => {
        if (searchSectionRef.current) {
            searchSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    if (role === 'doctor') {
        return (
            <>
                <Box dir={textDirection} sx={{
                    backgroundImage: `linear-gradient(rgba(180, 145, 203, 0.98), rgba(180, 145, 203, 0.8)),url(${MedicalAssisting})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }} width={'100%'} height={'30vh'} color={'text.secondary'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <h1 style={{ fontSize: sm ? 50 : xs ? 35 : 25, textAlign: 'center' }}>{t('The human body contains approximately 100 trillion cells')}</h1>
                </Box>
                <Grid container padding={5} columnSpacing={2} rowSpacing={2} dir={textDirection}>
                    <Grid item xs={12} md={6}>
                        <Card sx={{ height: 300, padding: 2, backgroundColor: '#f3edff', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', borderRadius: 5 }}>
                            <Typography style={{ fontSize: 25, fontWeight: 'bold', color: '#B491CB' }}>{currentUser?.first_name} {currentUser?.last_name}</Typography>
                            <Avatar src={profilePicture} sx={{ border: 3, width: 80, height: 80 }} />
                            <Box style={{ color: '#74658e' }}><strong>Doctor ID:</strong> D{currentUser?.doctor_id}</Box>
                            <Box style={{ color: '#74658e' }}><strong>Speciality:</strong> {currentUser?.speciality}</Box>
                            <Box style={{ color: '#74658e' }}><strong>Insurances:</strong> {insurances?.map((insurance, index) => index === insurances?.length - 1 ? insurance?.insurance_name : `${insurance?.insurance_name},`)}</Box>
                            <Box style={{ color: '#74658e' }}><strong>Referred Balance:</strong> {currentUser?.balance}JD</Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card sx={{ height: 300, padding: 2, backgroundColor: '#f3edff', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', borderRadius: 5 }}>
                            <Typography style={{ fontSize: 25, fontWeight: 'bold', color: '#B491CB' }}>Additional Information</Typography>
                            <Box style={{ color: '#74658e' }}><strong>Address:</strong> {currentUser?.location_text}</Box>
                            <Box style={{ color: '#74658e' }}><strong>Clinic Name:</strong> {currentUser?.clinic_name}</Box>
                            <Box style={{ color: '#74658e' }}><strong>Other Clinics:</strong> {clinics?.map((clinic, index) => index === clinics?.length - 1 ? clinic?.ch_name : `${clinic?.ch_name},`)}</Box>
                            <Box style={{ color: '#74658e' }}><strong>Bio:</strong> {currentUser?.biography}</Box>
                        </Card>
                    </Grid>
                </Grid>
                <Footer />
            </>
        )
    }
    return (
        <>
            <Box sx={{
                backgroundImage: `linear-gradient(rgba(96, 54, 120, 0.65), rgba(49, 20, 66, 0.8)),url(${Hero})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                textAlign: 'center',
            }} width={'100%'} height={sm ? '85vh' : '95vh'} color={'text.secondary'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                <h1 style={{ fontSize: sm ? 62 : xs ? 50 : 35, marginBottom: 10 }}>{t('Your Health is our Priority')}</h1>
                <p style={{ width: xs ? '50%' : '90%', marginBottom: 50 }}>
                    Eliminating barriers between doctors and patients, our system equips healthcare providers with all-encompassing tools, enabling them to deliver the best care possible, seamlessly connecting expertise and needs for optimal patient outcomes
                </p>
                <Button onClick={() => { scrollToSearchSection() }} sx={{
                    '&:hover': {
                        backgroundColor: '#B491CBB2',
                        border: '1px solid #B491CBB2',
                        transition: '1s'
                    },
                    transition: '1s'
                }} variant='outlined' size='large'>Search Weqayah</Button>
            </Box>
            <Box textAlign={'center'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} paddingTop={5} paddingBottom={10}>
                <h1 style={{ fontSize: 50 }}>Services We Offer</h1>
                <p style={{ maxWidth: xs ? '70%' : '80%', marginBottom: 50 }}>Our diverse services cater to streamline healthcare, offering innovative tools empowering professionals for exceptional care and seamless patient-doctor connections.</p>
                <Grid container columnSpacing={4} rowSpacing={4} width={xs ? '80%' : '90%'}>
                    <Grid item md={4}>
                        <ServiceCard title={'Find Your Ideal Doctor'} content={'We\'ve created a user-friendly and intelligent system that helps you discover the perfect doctor for your specific needs. Whether you require a family physician, a specialist, or a dentist, our platform will match you with the most suitable healthcare professional. You can browse through a diverse range of profiles, read patient reviews, and check their availability, making it easier than ever to book an appointment.'} />
                    </Grid>
                    <Grid item md={4}>
                        <ServiceCard title={'Locate Nearby Pharmacies'} content={'In urgent situations, our platform swiftly locates nearby open pharmacies through advanced geolocation, offering a seamless solution for last-minute prescriptions. Our service prioritizes user well-being, providing prompt access to essential medications, ensuring peace of mind during critical moments.'} />
                    </Grid>
                    <Grid item md={4}>
                        <ServiceCard title={'Electronic Medical Records (EMR)'} content={'We recognize the importance of streamlining healthcare processes. For doctors, our system provides a secure and user-friendly way to manage electronic medical records. By integrating this feature, healthcare professionals can easily access and update patient records ensuring that patient information is always up-to-date and readily available.'} />
                    </Grid>
                </Grid>
            </Box>
            <div ref={searchSectionRef} />
            <SearchSection />
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} paddingY={10} paddingX={5}>
                <Box sx={{
                    position: 'relative',
                    width: xs ? '82%' : '95%',
                    height: 300,
                    borderRadius: 5,
                    overflow: 'hidden',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundImage: `linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)),url(${Meds})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        zIndex: -1,
                        transformOrigin: 'center',
                        transition: 'transform 0.3s ease-in-out',
                    },
                    '&:hover::before': {
                        transform: 'scale(1.1)',
                    },
                }} width={'82%'} height={300} color={'text.secondary'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    <h1 style={{ fontSize: 30, textAlign: 'center' }}>Find Pharmacies Anywhere You Want!</h1>
                    <Button onClick={() => { navigate('findPharmacies') }} sx={{
                        '&:hover': {
                            backgroundColor: '#B491CBB2',
                            border: '1px solid #B491CBB2',
                            transition: '1s'
                        },
                        transition: '1s',
                    }} variant='outlined' size='large'>Search</Button>
                </Box>
            </Box>
            <Footer />
        </>
    )
}

export default Home