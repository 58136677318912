import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Clear from '../assets/clear2.svg';

const DeleteConfirmation = ({
  open,
  setOpen,
  text,
  onClick,
  recordId,
  buttonLabel = 'Delete',
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      sx={{
        '.MuiPaper-root': {
          backgroundColor: '#F7F7F7',
          borderRadius: 5,
          width: 600,
        },
      }}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          color: '#36454F',
        }}
      >
        <IconButton>
          <img src={Clear} alt='Delete' width={35} />
        </IconButton>
        <h4 style={{ margin: 0 }}>Are You Sure?</h4>
      </DialogTitle>
      <DialogContent
        sx={{
          borderTop: 2,
          borderBottom: 2,
          borderColor: '#B2BEB5',
          color: '#808080',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <p style={{ marginTop: 30 }}>{text}</p>
      </DialogContent>
      <DialogActions sx={{ paddingX: 2 }}>
        <Button
          sx={{
            borderWidth: 2,
            borderRadius: 2,
            backgroundColor: '#B491CB',
            fontWeight: 'bold',
            '&:hover': {
              borderWidth: 2,
              borderRadius: 2,
              backgroundColor: '#B491CB',
              fontWeight: 'bold',
            },
          }}
          onClick={() => {
            setOpen(false);
          }}
          color='success'
          variant='contained'
        >
          {t('Cancel')}
        </Button>
        <Button
          sx={{
            borderWidth: 2,
            borderRadius: 2,
            borderColor: '#EE4B2B',
            color: '#EE4B2B',
            fontWeight: 'bold',
            '&:hover': {
              borderWidth: 2,
              borderRadius: 2,
              borderColor: '#EE4B2B',
              color: '#EE4B2B',
              fontWeight: 'bold',
            },
          }}
          onClick={() => {
            setOpen(false);
            recordId ? onClick(recordId) : onClick();
          }}
          variant='outlined'
        >
          {t(buttonLabel)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmation;
