import { TextField } from "@mui/material"

const InventoryTextField = ({ placeholder, register, name, errors, autoFocus, defaultVlaue = '' }) => {
    return (
        <TextField InputProps={{
            style: { background: 'white' },
        }} sx={{
            '&:focus-within fieldset': {
                borderColor: 'black !important',
            },
            '& .MuiFormHelperText-root': {
                color: 'black',
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'black',
            },
            marginX: 1
        }} placeholder={placeholder} defaultValue={defaultVlaue} {...register(name, { required: true })} error={!!errors[name]} autoFocus={autoFocus} />
    )
}

export default InventoryTextField