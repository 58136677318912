import { createTheme } from "@mui/material"

const Theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#fff',
        },
        secondary: {
            main: '#42159D',
        },
        text: {
            primary: '#000000',
            secondary: '#ffffff',
        },
        background: {
            // paper: '#B491CBB2',
            paper: '#B491CB',
        },
        error: {
            main: '#f44336',
        },
        divider: '#000000',
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
    },
})

export default Theme