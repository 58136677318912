import { Box } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../App";
import Facebook from '../assets/facebook.svg';
import Instagram from '../assets/instagram.svg';
import LinkedIn from '../assets/linkedin.svg';
import Twitter from '../assets/twitter.svg';
import YouTube from '../assets/youtube.svg';
import SocialIcon from "./SocialIcon";

const Footer = () => {

    const { xs, md } = useContext(AppContext)

    return (
        <Box color={'white'} backgroundColor={'background.paper'} textAlign={'center'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
            <h1>Weqayah</h1>
            <p style={{ width: md ? '50%' : '80%' }}>
                At Weqayah, we're more than just a healthcare platform; we're a healthcare community dedicated to improving the way patients and doctors connect. Our mission is to simplify the healthcare journey and empower patients to make informed decisions about their health.
            </p>
            <Box width={xs ? 350 : 280} display={'flex'} justifyContent={'space-between'} borderRadius={10} paddingY={0.8} paddingX={0.8}>
                <SocialIcon icon={Twitter} to="" social="Twitter" />
                <SocialIcon icon={LinkedIn} to="https://www.linkedin.com/company/skyfall-enterprisesa/" social="LinkedIn" />
                <SocialIcon icon={YouTube} to="" social="YouTube" />
                <SocialIcon icon={Facebook} to="https://www.facebook.com/profile.php?id=100084458424509" social="Facebook" />
                <SocialIcon icon={Instagram} to="https://www.instagram.com/skyfallenterprises/" social="Instagram" />
            </Box>
            <p style={{ fontSize: 20 }}>Made By Skyfall Enterprises</p>
        </Box>
    )
}

export default Footer