import { Card, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { useState } from "react"

const SearchCard = ({ image, searchBy, example, register, name, defaultValue }) => {

    const [speciality, setSpeciality] = useState('')
    const specialities = ['Dentist', 'General']

    return (
        <Card sx={{
            '&:hover': {
                boxShadow: '0 0 20px 0 rgba(255, 255, 255, 0.5)',
                transition: '0.5s'
            },
            transition: '0.5s', backgroundColor: 'white', height: 300, display: "flex", flexDirection: "column", justifyContent: 'end', alignItems: 'center', borderRadius: 5, paddingY: 5
        }}>
            <img src={image} alt="" width={150} />
            <h2>Search by {searchBy}</h2>
            {name === 'speciality' ? <FormControl sx={{ width: 220 }}>
                <InputLabel style={{ color: 'black' }} id="demo-simple-select-label">{searchBy}</InputLabel>
                <Select
                    {...register(name)}
                    color="secondary"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={speciality}
                    label={searchBy}
                    onChange={(event) => { setSpeciality(event.target.value) }}
                >
                    {specialities.map((speciality, index) => <MenuItem key={index} value={speciality}>{speciality}</MenuItem>)}
                </Select>
            </FormControl> : <TextField
                sx={{
                    '&:focus-within fieldset': {
                        borderColor: 'black !important',
                    },
                }}
                placeholder={example}
                defaultValue={defaultValue}
                {...register(name)}
            />}
        </Card>
    )
}

export default SearchCard
