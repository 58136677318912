import { Box, FormControl, IconButton, InputLabel, MenuItem, Select } from "@mui/material"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { request } from "../API/Request"
import Cancel from '../assets/cancel.svg'
import Confirm from '../assets/confirm.svg'
import Edit from '../assets/edit.svg'
import Remove from '../assets/remove.svg'
import DashboardTextField from "./DashboardTextField"

const Surgery = ({ surgery, getSurgeries }) => {

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        values: surgery
    })
    const [editInfo, setEditInfo] = useState(false)

    const confirmEditInfo = async (data) => {
        await request('update-surgery', 'PUT',
            {
                "surgery_id": data.surgery_id,
                "surgery_short": data.surgery_short,
                "en_desc": data.en_desc,
                "ar_desc": data.ar_desc,
                "speciality": data.speciality,
                "color": data.color,
                "jaw": data.jaw == 'yes' ? true : false,
            }
        )
        getSurgeries()
        setEditInfo(false)
    }
    const deleteSurgery = async () => {
        await request(`delete-surgery/${surgery.surgery_id}`, 'DELETE')
        getSurgeries()
    }

    return (
        <Box
            width={'95%'} display={"flex"} justifyContent={'space-evenly'} alignItems={'center'} marginTop={2}>
            <h2 style={{ marginRight: 5 }}>{surgery.surgery_id}</h2>
            <DashboardTextField editInfo={editInfo} register={register} name={'surgery_short'} errors={errors} />
            <DashboardTextField editInfo={editInfo} register={register} name={'en_desc'} errors={errors} />
            <DashboardTextField editInfo={editInfo} register={register} name={'ar_desc'} errors={errors} />
            <DashboardTextField editInfo={editInfo} register={register} name={'speciality'} errors={errors} />
            <DashboardTextField editInfo={editInfo} register={register} name={'color'} errors={errors} />
            <FormControl sx={{ '.Mui-focused': { color: 'black !important' } }}>
                <InputLabel sx={{ color: 'black' }} id="demo-simple-select-label">Jaw</InputLabel>
                <Select
                    inputProps={
                        { readOnly: !editInfo }
                    }
                    size="small"
                    sx={{ '& fieldset': { borderColor: !!errors.jaw ? '#f44336 !important' : 'black !important' }, width: 200 }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Jaw"
                    defaultValue={surgery?.jaw == true ? 'yes' : 'no'}
                    {...register('jaw', { required: true })}
                    error={!!errors.jaw}
                >
                    <MenuItem value={'yes'}>Yes</MenuItem>
                    <MenuItem value={'no'}>No</MenuItem>
                </Select>
            </FormControl>
            {editInfo ?
                <>
                    <IconButton onClick={handleSubmit(confirmEditInfo)}>
                        <img src={Confirm} alt="Edit" width={40} />
                    </IconButton>
                    <IconButton onClick={() => { reset(); setEditInfo(false) }}>
                        <img src={Cancel} alt="Edit" width={40} />
                    </IconButton>
                </>
                : <>
                    <IconButton onClick={() => { setEditInfo(true) }}>
                        <img src={Edit} alt="Edit" width={40} />
                    </IconButton>
                    <IconButton onClick={() => { deleteSurgery() }}>
                        <img src={Remove} alt="Remove" width={30} />
                    </IconButton>
                </>
            }
        </Box>
    )
}

export default Surgery