import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import { useContext, useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { request } from "../../API/Request";
import { AppContext } from "../../App";
import CustomTextField from "../../components/CustomTextField";

const PatientSignup = () => {

    const navigate = useNavigate()
    const { xs, setLoading, setIsSigned, setRole, setCurrentUser } = useContext(AppContext)
    const { register, handleSubmit, formState: { errors }, setError } = useForm()
    const [phoneNumber, setPhoneNumber] = useState('')

    useEffect(() => {
        document.title = 'Weqayah | Patient Sign-up'
    }, [])

    const signup = async (data) => {
        if (!matchIsValidTel(data.phone_number)) {
            setError('phone_number')
        } else {
            setLoading(true)
            const response = await request('add-patient', 'POST',
                {
                    "balance": 0,
                    "password": data.password,
                    "age": data.age,
                    "gender": data.gender,
                    "phone_number": data.phone_number,
                    "email": data.email,
                    "first_name": data.first_name,
                    "last_name": data.last_name,
                    "city": data.city,
                    "insurance": data.insurance
                }
            )
            if (response.success) {
                setIsSigned(true)
                setRole('patient')
                setCurrentUser(response.data)
                localStorage.setItem('token', JSON.stringify(response.token))
                if (JSON.parse(localStorage.getItem('redirectURL'))) {
                    navigate(`/${JSON.parse(localStorage.getItem('redirectURL'))}`, { replace: true })
                    localStorage.removeItem('redirectURL')
                }
            } else {
                setError('email', { message: response.errorMessage })
            }
            setLoading(false)
        }
    }

    return (
        <Box width={xs ? '98.2vw' : '100vw'} height={xs ? '96.5vh' : '100vh'} padding={xs ? 2 : 0}>
            <Box backgroundColor={'#e2e2e5'} width={'100%'} height={'100%'} display={"flex"} alignItems={"center"} justifyContent={"center"} paddingY={xs ? 0 : 30}>
                <Grid container width={xs ? 900 : 300} display={"flex"} alignItems={'center'}>
                    <Grid item xs={12} sm={6} height={200} display={"flex"} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} paddingX={xs ? 2 : 0} textAlign={!xs && 'center'}>
                        <Box display={"flex"} flexDirection={"column"} alignItems={xs ? 'start' : 'center'} justifyContent={'space-evenly'} height={'100%'} width={'100%'}>
                            <Typography sx={{ color: '#B491CB', fontSize: 30, fontWeight: 900, fontFamily: `'Inter UI', sans-serif` }}>Weqayah</Typography>
                            <Typography sx={{ color: 'black', fontSize: 22 }}>Find the doctors that suits you and book your appointment online.</Typography>
                            <Box display={"flex"} alignItems={'start'}>
                                <Button disableRipple onClick={() => { navigate('/login') }} sx={{ fontSize: xs && 20, color: '#B491CB', justifyContent: 'flex-start', padding: 0, textAlign: 'start', "&:hover": { backgroundColor: 'transparent' } }}>Sign-in</Button>
                                <Typography fontSize={xs && 20} marginX={1}>or</Typography>
                                <Button disableRipple onClick={() => { navigate('/doctorSignup') }} sx={{ fontSize: xs && 20, color: '#B491CB', justifyContent: 'flex-start', padding: 0, "&:hover": { backgroundColor: 'transparent' } }}>Request Doctor Sign-up</Button>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} display={"flex"} flexDirection={'column'} justifyContent={'space-evenly'} backgroundColor={'#B491CB'} color={'white'} padding={4}>
                        <h2 style={{ textAlign: 'center', margin: 0, marginBottom: 25 }} >Patient Sign-up</h2>
                        <form onSubmit={handleSubmit(signup)} style={{ display: "flex", flexDirection: 'column' }}>
                            <label style={{ fontSize: 14 }}>First Name</label>
                            <CustomTextField name={'first_name'} label={'First Name'} register={register} errors={errors} autoFocus={true} />
                            <label style={{ fontSize: 14 }}>Last Name</label>
                            <CustomTextField name={'last_name'} label={'Last Name'} register={register} errors={errors} />
                            <label style={{ fontSize: 14 }}>Email</label>
                            <CustomTextField name={'email'} label={'Email'} type="email" register={register} errors={errors} />
                            <label style={{ fontSize: 14 }}>Password</label>
                            <CustomTextField name={'password'} label={'Password'} type="password" helperText={'Password must be at least 8 characters'} register={register} errors={errors} />
                            <label style={{ fontSize: 14 }}>Age</label>
                            <CustomTextField name={'age'} label={'Age'} register={register} errors={errors} />
                            <FormControl sx={{ marginLeft: 0.3 }}>
                                <FormLabel>
                                    <label style={{ fontSize: 14 }}>Gender</label>
                                </FormLabel>
                                <RadioGroup row>
                                    <FormControlLabel value="male" control={<Radio required size="small" {...register('gender', { required: 'Gender is required' })} />} label="Male" />
                                    <FormControlLabel value="female" control={<Radio required size="small" {...register('gender', { required: 'Gender is required' })} />} label="Female" />
                                </RadioGroup>
                            </FormControl>
                            <label style={{ fontSize: 14 }}>Phone Number</label>
                            <MuiTelInput
                                size="small"
                                sx={{
                                    marginBottom: 1,
                                    ".MuiInputAdornment-root": {
                                        marginLeft: -1,
                                    },
                                    ".MuiInputBase-root": {
                                        color: '#B491CB',
                                        backgroundColor: 'white',
                                    },
                                    "& fieldset": {
                                        borderColor: "white",
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "white !important",
                                        borderWidth: 0,
                                    },
                                    "&:focus-within fieldset": {
                                        borderWidth: "0px !important",
                                    }
                                }} {...register('phone_number', { validate: () => !phoneNumber ? 'Phone Number is required' : phoneNumber?.length <= 9 ? 'Please enter a valid phone number' : true })} error={!!errors.phone_number} defaultCountry="JO" preferredCountries={['JO']} excludedCountries={['IL']} flagSize="medium" placeholder="Select Your Country" value={phoneNumber} onChange={(newPhone, country) => { setPhoneNumber(newPhone) }} />
                            {errors.phone_number && <Typography fontSize={14} marginBottom={1} color={'error.main'}>{errors.phone_number?.message}</Typography>}
                            <label style={{ fontSize: 14 }}>City</label>
                            <CustomTextField name={'city'} label={'City'} register={register} errors={errors} />
                            <label style={{ fontSize: 14 }}>Insurance</label>
                            <CustomTextField name={'insurance'} label={'Insurance'} register={register} errors={errors} />
                            <Button sx={{ height: 40, marginTop: 2 }} type="submit" variant="contained">Sign-up</Button>
                        </form>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default PatientSignup