import { Grid } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { request } from "../API/Request"
import { AppContext } from "../App"
import DashboardCard from "../components/DashboardCard"

const AdminDashboard = () => {

    const { setLoading } = useContext(AppContext)
    const [doctors, setDoctors] = useState(0)
    const [patients, setPatients] = useState(0)
    const [pharmacies, setPharmacies] = useState(0)
    const [surgeries, setSurgeries] = useState(0)

    const getCounts = async () => {
        setLoading(true)
        const doctorsNumber = await request('get-doctors-count', 'GET')
        const patientsNumber = await request('get-patients-count', 'GET')
        const pharmaciesNumber = await request('get-pharmacies-count', 'GET')
        const surgeriesNumber = await request('get-surgeries-count', 'GET')
        setDoctors(doctorsNumber)
        setPatients(patientsNumber)
        setPharmacies(pharmaciesNumber)
        setSurgeries(surgeriesNumber)
        setLoading(false)
    }
    useEffect(() => {
        getCounts()
    }, [])

    return (
        <Grid container rowGap={2} backgroundColor={'background.paper'} textAlign={'center'} paddingTop={5} paddingBottom={10}>
            <Grid item xl={3} md={6} xs={12} display={"flex"} justifyContent={"center"}>
                <DashboardCard title={'Doctors'} number={doctors} />
            </Grid>
            <Grid item xl={3} md={6} xs={12} display={"flex"} justifyContent={"center"}>
                <DashboardCard title={'Patients'} number={patients} />
            </Grid>
            <Grid item xl={3} md={6} xs={12} display={"flex"} justifyContent={"center"}>
                <DashboardCard title={'Pharmacies'} number={pharmacies} />
            </Grid>
            <Grid item xl={3} md={6} xs={12} display={"flex"} justifyContent={"center"}>
                <DashboardCard title={'Surgeries'} number={surgeries} />
            </Grid>
        </Grid>
    )
}

export default AdminDashboard