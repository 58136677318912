import { Box, Button, Divider, IconButton, TextField } from "@mui/material"
import { Fragment, useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Add from '../assets/add.svg'
import Patient from "../components/Patient"
import { request } from "../API/Request"
import { AppContext } from "../App"

const Patients = () => {

    const { setLoading } = useContext(AppContext)
    const { register, handleSubmit, formState: { errors }, reset, setError } = useForm()
    const [patients, setPatients] = useState([])
    const [limit, setLimit] = useState(1)
    const [searchText, setSearchText] = useState('')

    const getPatients = async () => {
        setLoading(true)
        const response = await request(`get-patients-for-admin/${limit}`, 'GET')
        setPatients(response.patients)
        setLoading(false)
    }
    useEffect(() => {
        document.title = 'Admin | Patients'
        getPatients()
    }, [limit])

    const addPatient = async (data) => {
        setLoading(true)
        const response = await request('add-patient', 'POST',
            {
                "phone_number": data.phone_number,
                "email": data.email,
                "password": data.password,
                "age": data.age,
                "gender": data.gender,
                "first_name": data.first_name,
                "last_name": data.last_name,
                "city": data.city,
                "insurance": data.insurance,
                "balance": data.balance
            }
        )
        if (response.success) {
            getPatients()
            reset()
        } else {
            setError('email', { message: response.errorMessage })
        }
        setLoading(false)
    }
    const filterPatients = (patient) => {
        return patient.first_name.toLowerCase().includes(searchText.toLowerCase()) || patient.last_name.toLowerCase().includes(searchText.toLowerCase())
    };
    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    return (
        <Box backgroundColor={'#F7F7F7'} textAlign={'center'} display={"flex"} flexDirection={"column"} alignItems={"center"} paddingBottom={5}>
            <Box backgroundColor={'background.paper'} width={'100%'} display={"flex"} flexDirection={'column'} alignItems={'center'} paddingY={5} marginBottom={5}>
                <h1 style={{ color: "white" }}>Patients</h1>
                <TextField onChange={handleSearchChange} sx={{ backgroundColor: '#F7F7F7', borderRadius: 2, input: { "&::placeholder": { color: "#993cd6", fontWeight: 'bold' }, color: '#B491CB' }, 'fieldset': { borderWidth: 2, borderColor: '#7C5295' }, '&:focus-within fieldset': { borderWidth: 2, borderColor: '#7C5295 !important' }, '&:hover fieldset': { borderWidth: 2, borderColor: '#7C5295 !important' }, '.MuiInputBase-root': { borderRadius: 2 } }} placeholder="Search Patients..." />
            </Box>
            <form onSubmit={handleSubmit(addPatient)} style={{ width: '95%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.first_name ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.first_name ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('first_name', { required: true })} error={!!errors.first_name} placeholder={'First Name'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.last_name ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.last_name ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('last_name', { required: true })} error={!!errors.last_name} placeholder={'Last Name'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.email ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.email ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('email', { required: true })} error={!!errors.email} placeholder={'Email'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.password ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.password ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('password', { required: true })} error={!!errors.password} placeholder={'Password'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.gender ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.gender ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('gender', { required: true })} error={!!errors.gender} placeholder={'Gender'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.age ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.age ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('age', { required: true })} error={!!errors.age} placeholder={'Age'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.phone_number ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.phone_number ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('phone_number', { required: true })} error={!!errors.phone_number} placeholder={'Phone Number'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.city ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.city ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('city', { required: true })} error={!!errors.city} placeholder={'City'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.insurance ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.insurance ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('insurance', { required: true })} error={!!errors.insurance} placeholder={'Insurance'} />
                <TextField sx={{
                    '&:focus-within fieldset': {
                        border: !!errors.balance ? '2px solid #f44336 !important' : '2px solid black !important',
                    },
                    '& fieldset': {
                        border: !!errors.balance ? '1px solid #f44336 !important' : '1px solid black !important',
                    },
                }} size="small" type='text' {...register('balance', { required: true })} error={!!errors.balance} placeholder={'Balance'} />
                <IconButton type="submit">
                    <img src={Add} alt="Add" width={30} />
                </IconButton>
            </form>
            {errors?.email?.message && <p style={{ marginTop: 20 }}>{errors.email?.message}</p>}
            {patients?.filter(filterPatients)?.map((patient, index) => {
                return <Fragment key={index}>
                    <Divider sx={{ width: '95%', marginTop: 2 }} />
                    <Patient patient={patient} getPatients={getPatients} />
                </Fragment>
            })}
            <Divider sx={{ width: '95%', marginY: 2 }} />
            <Button sx={{ backgroundColor: '#B491CB', boxShadow: 'none', color: 'white', '&:hover': { backgroundColor: '#b583d6', boxShadow: 'none' }, fontWeight: 'bold' }} onClick={() => { setLimit(limit + 1) }} variant="contained">Load More</Button>
        </Box>
    )
}

export default Patients