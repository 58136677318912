import { Box, Button } from "@mui/material"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { request } from "../API/Request"
import { AppContext } from "../App"

const PatientBookingCard = ({ time, status, clinic_name, clinic_location, date, expired, appointment_id, booking_id, getBookings }) => {

    const { setLoading, sm } = useContext(AppContext)
    const { t } = useTranslation()

    const cancelBooking = async () => {
        setLoading(true)
        await request('update-booking', 'PUT', {
            "booking_id": booking_id,
            "booking_status": 'Cancelled',
        })
        await request('update-appointment', 'PUT', {
            "appointment_id": appointment_id,
            "booking_status": false,
            "patient_name": '',
        })
        setLoading(false)
        getBookings()
    }

    return (
        <Box
            width={'90%'}
            display={"flex"}
            justifyContent={'space-between'}
            alignItems={"center"}
            border={2}
            borderColor={'background.paper'}
            borderRadius={5}
            paddingX={1}
            marginBottom={2}
            flexWrap="wrap"
            paddingY={1}
        >
            <h3 style={{ borderInlineEnd: '2px solid', width: '100%', flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 5, paddingRight: 5 }}>{clinic_name}</h3>
            <h3 style={{ borderInlineEnd: '2px solid', width: '100%', flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 5, paddingRight: 5 }}>{clinic_location}</h3>
            <h3 style={{ borderInlineEnd: '2px solid', width: '100%', flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 5, paddingRight: 5 }}>{time?.slice(0, 5)}</h3>
            <h3 style={{ borderInlineEnd: '2px solid', width: '100%', flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 5, paddingRight: 5 }}>{date?.slice(0, 10)}</h3>
            <h3 style={{ flex: '1', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 5, paddingRight: 5 }}>{status}</h3>
            {!expired && <Button onClick={() => { cancelBooking() }} sx={{ width: '60%', flex: '1', marginTop: '10px' }} color="error" variant="contained">{t('Cancel booking')}</Button>}
        </Box>
    )
}

export default PatientBookingCard