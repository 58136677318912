import { Box, Button, Card, Typography } from "@mui/material"
import { MuiOtpInput } from "mui-one-time-password-input"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { request } from "../../API/Request"
import { AppContext } from "../../App"

const OtpPage = () => {

    const [otp, setOtp] = useState(useLocation().state.otp);
    const email = useLocation().state.email
    const navigate = useNavigate()
    const { xs, setLoading } = useContext(AppContext)
    const { t } = useTranslation()
    const [PIN, setPIN] = useState('')
    const [PINError, setPINError] = useState('')
    const [counter, setCounter] = useState(60)

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCounter(counter => (counter > 0 ? counter - 1 : 0));
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const sendRecoveryEmail = async () => {
        const otp = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
        setLoading(true)
        const response = await request('forgot-password', 'POST', { "email": email, "otp": otp })
        if (response.success) {
            setCounter(60)
            setOtp(otp)
        } else {
            setPINError('Something went wrong')
        }
        setLoading(false)
    }
    const handlePINChange = (newValue) => {
        setPIN(newValue)
        if (newValue.length === 4) {
            if (parseInt(newValue) === otp) {
                navigate('/setPassword', { replace: true, state: { "email": email } })
            } else {
                setPINError(t('The entered PIN is not correct'))
                setPIN('')
                document.getElementsByClassName('MuiInputBase-input')[0].focus()
            }
        }
    }
    const validateChar = (value) => {
        return value >= '0' && value <= '9'
    }

    return (
        <Box width={'100vw'} height={'100vh'} display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Card sx={{ width: xs ? 500 : '100%', height: xs ? 'auto' : '100%', display: "flex", flexDirection: 'column', alignItems: 'center', paddingTop: !xs && 60, paddingBottom: 3, paddingX: 2 }} elevation={8}>
                <h2>Please Enter OTP Sent to Your Email</h2>
                <MuiOtpInput sx={{
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: '3px black solid !important'
                    }
                }} TextFieldsProps={{ placeholder: '—', style: { caretColor: 'transparent' } }} value={PIN} onChange={handlePINChange} validateChar={validateChar} />
                {PINError && <Typography fontSize={18} color={'error.main'} marginTop={3}>{PINError}</Typography>}
                <Box display={"flex"} marginTop={2}>
                    <p>Didn't recieve code?</p>
                    {!counter ? <Button onClick={() => { sendRecoveryEmail() }}>Resend OTP</Button> : <p style={{ marginLeft: 5 }}>Resend OTP in {counter}s</p>}
                </Box>
            </Card>
        </Box>
    )
}

export default OtpPage