import { Box, Button, Card, TextField, Typography } from "@mui/material"
import { request } from "../../API/Request"
import { AppContext } from "../../App"
import { useContext } from "react"
import { useForm } from "react-hook-form"
import { useLocation, useNavigate } from "react-router-dom"

const SetPassword = () => {

    const navigate = useNavigate()
    const email = useLocation().state.email
    const { xs, setLoading } = useContext(AppContext)
    const { register, handleSubmit, formState: { errors } } = useForm()

    const resetPassword = async (data) => {
        setLoading(true)
        const response = await request('change-password', 'PUT', { "email": email, "password": data.password })
        if (response.success) {
            navigate('/login', { replace: true })
        }
        setLoading(false)
    }

    return (
        <Box width={'100vw'} height={'100vh'} display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Card sx={{ width: xs ? 500 : '100%', height: xs ? 'auto' : '100%', display: "flex", flexDirection: 'column', alignItems: 'center', paddingTop: !xs && 60, paddingBottom: 3, paddingX: 2 }} elevation={8}>
                <h2>Please Enter New Password</h2>
                <TextField sx={{
                    '&:focus-within fieldset': {
                        borderColor: 'black !important',
                    },
                    '& .MuiFormHelperText-root': {
                        color: 'black',
                    },
                    marginBottom: 2
                }} {...register('password', { required: 'Password is required' })} error={!!errors.password} />
                {errors.password && <Typography color={'error.main'} marginBottom={2}>{errors.password?.message}</Typography>}
                <Button onClick={handleSubmit(resetPassword)} color="secondary" variant="contained">Set Password</Button>
            </Card>
        </Box>
    )
}

export default SetPassword