import { Box, Button, Grid, Typography } from "@mui/material"
import { useContext, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { downloadRequest, request } from "../../API/Request"
import { AppContext } from "../../App"
import CustomTextField from "../../components/CustomTextField"

const Login = () => {

    const navigate = useNavigate()
    const { xs, setCurrentUser, setRole, setIsSigned, setLoading, setProfilePicture } = useContext(AppContext)
    const { register, handleSubmit, formState: { errors }, setError } = useForm()

    useEffect(() => {
        document.title = 'Weqayah | Sign-in'
    }, [])

    const login = async (data) => {
        setLoading(true)
        const response = await request('login', 'POST', data)
        if (response.success) {
            if (response.token) {
                localStorage.setItem('token', JSON.stringify(response.token))
            }
            if (response.role === 'admin') {
                navigate('/adminDashboard', { replace: true })
            }
            setCurrentUser(response.data)
            setRole(response.role)
            setIsSigned(true)
            if (response.data?.doctor_id) {
                const profilePicture = await downloadRequest(`get-profile-picture/${response.data.doctor_id}`, 'GET', true);
                if (!profilePicture?.error) {
                    setProfilePicture(window.URL.createObjectURL(profilePicture));
                }
            }
            if (JSON.parse(localStorage.getItem('redirectURL'))) {
                navigate(`/${JSON.parse(localStorage.getItem('redirectURL'))}`, { replace: true })
                localStorage.removeItem('redirectURL')
            }
        } else {
            setError('email')
            setError('password', { message: response.errorMessage })
        }
        setLoading(false)
    }

    return (
        <Box width={xs ? '98.2vw' : '100vw'} height={xs ? '96.5vh' : '100vh'} padding={xs ? 2 : 0}>
            <Box backgroundColor={'#e2e2e5'} width={'100%'} height={'100%'} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <form onSubmit={handleSubmit(login)}>
                    <Grid container width={xs ? 650 : 320} display={"flex"} alignItems={'center'}>
                        <Grid item xs={12} sm={6} height={280} backgroundColor={'#ffffff'} display={"flex"} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} paddingX={6} paddingY={4}>
                            <Box display={"flex"} flexDirection={"column"} alignItems={'start'} justifyContent={'space-evenly'} height={'100%'} width={'100%'}>
                                <Typography sx={{ color: '#B491CB', fontSize: 50, fontWeight: 900, fontFamily: `'Inter UI', sans-serif` }}>Sign-in</Typography>
                                <Typography sx={{ color: '#999', fontSize: 14 }}>Sign into your Weqayah account</Typography>
                                <Box display={"flex"} flexDirection={'column'} alignItems={'start'}>
                                    <Button disableRipple onClick={() => { navigate('/doctorSignup') }} sx={{ color: '#B491CB', justifyContent: 'flex-start', padding: 0, "&:hover": { backgroundColor: 'transparent' } }}>Request Doctor Sign-up</Button>
                                    <Button disableRipple onClick={() => { navigate('/patientSignup') }} sx={{ color: '#B491CB', justifyContent: 'flex-start', padding: 0, textAlign: 'start', "&:hover": { backgroundColor: 'transparent' } }}>Patient Sign-up</Button>
                                    <Button disableRipple onClick={() => { navigate('/') }} sx={{ color: '#B491CB', justifyContent: 'flex-start', padding: 0, textAlign: 'start', "&:hover": { backgroundColor: 'transparent' } }}>Home</Button>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} display={"flex"} flexDirection={'column'} justifyContent={'space-evenly'} backgroundColor={'#B491CB'} color={'white'} padding={4} height={320} boxShadow={'0px 0px 40px 16px rgba(0,0,0,0.22)'}>
                            <Box display={"flex"} flexDirection={'column'}>
                                <label style={{ fontSize: 14 }}>Email</label>
                                <CustomTextField name={'email'} label={'Email'} type="email" register={register} errors={errors} autoFocus={true} />
                            </Box>
                            <Box display={"flex"} flexDirection={'column'}>
                                <label style={{ fontSize: 14 }}>Password</label>
                                <CustomTextField name={'password'} label={'Password'} type="password" register={register} errors={errors} />
                            </Box>
                            <Button disableRipple sx={{ fontWeight: 'bold', fontSize: 20, "&:hover": { backgroundColor: 'transparent' }, marginTop: 2 }} type="submit">Sign-in</Button>
                            {/* <Button onClick={() => { navigate('/forgotPassword') }} disableRipple sx={{ "&:hover": { backgroundColor: 'transparent' } }}>Forget Password?</Button> */}
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Box>
    )
}

export default Login