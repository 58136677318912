import { TextField } from "@mui/material"

const ProfileTextField = ({ editInfo, name, type = 'text', register, errors, label }) => {

    return (
        <TextField inputProps={
            { readOnly: !editInfo, }
        } sx={{
            '&:focus-within fieldset': {
                border: (!!errors[name] && editInfo) ? '2px solid #f44336 !important' : '2px solid #d0b9fd !important',
            },
            '& fieldset': {
                border: editInfo ? !!errors[name] ? '1px solid #f44336 !important' : '1px solid #d0b9fd !important' : '2px solid #d0b9fd !important',
            },
            marginBottom: 1.5
        }} size="small" fullWidth type={type} {...register(name, { required: `${label} is required` })} error={!!errors[name] && editInfo} placeholder={label} />
    )
}

export default ProfileTextField