import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  ThemeProvider,
  useMediaQuery,
} from '@mui/material';
import { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { downloadRequest, request } from './API/Request';
import NavigationBar from './components/NavigationBar';
import ProtectedRoute from './components/ProtectedRoute';
import SideMenu from './components/SideMenu';
import './i18n/confg';
import AboutUs from './pages/AboutUs';
import AdminDashboard from './pages/AdminDashboard';
import DoctorBookings from './pages/DoctorBookings';
import DoctorInfo from './pages/DoctorInfo';
import Doctors from './pages/Doctors';
import File from './pages/File';
import Files from './pages/Files';
import FindDoctor from './pages/FindDoctor';
import FindPharmacies from './pages/FindPharmacies';
import Home from './pages/Home';
import PatientBookings from './pages/PatientBookings';
import Patients from './pages/Patients';
import Pharmacies from './pages/Pharmacies';
import Profile from './pages/Profile';
import ForgotPassword from './services/ForgotPassword/ForgotPassword';
import OtpPage from './services/ForgotPassword/OtpPage';
import SetPassword from './services/ForgotPassword/SetPassword';
import Loader from './services/Loader/Loader';
import Login from './services/Login/Login';
import DoctorSignup from './services/Signup/DoctorSignup';
import PatientSignup from './services/Signup/PatientSignup';
import Theme from './services/Theme';
import Inventory from './pages/Inventory';
import Surgeries from './pages/Surgeries';

export const AppContext = createContext();

function App() {
  const xs = useMediaQuery('(min-width:600px)');
  const sm = useMediaQuery('(min-width:900px)');
  const md = useMediaQuery('(min-width:1200px)');
  const lg = useMediaQuery('(min-width:1500px)');

  const [openMenu, setOpenMenu] = useState(false);
  const [isSigned, setIsSigned] = useState(false);
  const [role, setRole] = useState('');
  const [currentUser, setCurrentUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [sessionDialog, setSessionDialog] = useState(false);
  const [profilePicture, setProfilePicture] = useState('');
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const checkUser = async () => {
    const response = await request('check-user', 'POST');
    if (response.success) {
      setCurrentUser(response.data);
      setIsSigned(true);
      setRole(response.role);
      if (response.role === 'admin') {
        navigate('/adminDashboard', { replace: true });
      }
      if (response.data?.doctor_id) {
        const profilePicture = await downloadRequest(
          `get-profile-picture/${response.data.doctor_id}`,
          'GET',
          true
        );
        if (!profilePicture?.error) {
          setProfilePicture(window.URL.createObjectURL(profilePicture));
        }
      }
    } else if (response.errorMessage === 'Your session has been expired') {
      setSessionDialog(true);
      setRole('guest');
      localStorage.removeItem('token');
    } else {
      setRole('guest');
    }
    setLoading(false);
  };
  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('language')) ?? 'en');
    checkUser();
  }, []);

  return (
    <AppContext.Provider
      value={{
        xs,
        sm,
        md,
        lg,
        openMenu,
        setOpenMenu,
        isSigned,
        setIsSigned,
        role,
        setRole,
        currentUser,
        setCurrentUser,
        loading,
        setLoading,
        profilePicture,
        setProfilePicture,
        checkUser,
      }}
    >
      <ThemeProvider theme={Theme}>
        <Dialog
          sx={{ textAlign: 'center' }}
          open={sessionDialog}
          onClose={() => {
            setSessionDialog(false);
          }}
        >
          <DialogContent>
            <h2>Your session has been expired</h2>
            <h2>Please login</h2>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button
              onClick={() => {
                navigate('/login');
                setSessionDialog(false);
              }}
              sx={{ marginY: 2, width: 300 }}
              color='secondary'
              variant='contained'
            >
              Sign-in
            </Button>
          </DialogActions>
        </Dialog>
        {loading && <Loader />}
        <NavigationBar />
        <SideMenu />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/aboutUs' element={<AboutUs />} />
          <Route path='/findDoctor' element={<FindDoctor />} />
          <Route path='/findDoctor/:page' element={<FindDoctor />} />
          {/* <Route path='/findPharmacies' element={<FindPharmacies />} /> */}
          <Route path='/doctorInfo' element={<DoctorInfo />} />
          <Route
            path='/patientBookings'
            element={
              <ProtectedRoute path={'/patientBookings'}>
                <PatientBookings />
              </ProtectedRoute>
            }
          />
          <Route
            path='/doctorBookings'
            element={
              <ProtectedRoute path={'/doctorBookings'}>
                <DoctorBookings />
              </ProtectedRoute>
            }
          />
          <Route
            path='/files'
            element={
              <ProtectedRoute path={'/files'}>
                <Files />
              </ProtectedRoute>
            }
          />
          <Route
            path='/files/file'
            element={
              <ProtectedRoute path={'/files/file'}>
                <File />
              </ProtectedRoute>
            }
          />
          <Route
            path='/inventory'
            element={
              <ProtectedRoute path={'/inventory'}>
                <Inventory />
              </ProtectedRoute>
            }
          />
          <Route
            path='/profile'
            element={
              <ProtectedRoute path={'/profile'}>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path='/patientSignup'
            element={
              <ProtectedRoute path={'/patientSignup'}>
                <PatientSignup />
              </ProtectedRoute>
            }
          />
          <Route
            path='/doctorSignup'
            element={
              <ProtectedRoute path={'/doctorSignup'}>
                <DoctorSignup />
              </ProtectedRoute>
            }
          />
          <Route
            path='/login'
            element={
              <ProtectedRoute path={'/login'}>
                <Login />
              </ProtectedRoute>
            }
          />
          <Route
            path='/forgotPassword'
            element={
              <ProtectedRoute path={'/forgotPassword'}>
                <ForgotPassword />
              </ProtectedRoute>
            }
          />
          <Route
            path='/otpPage'
            element={
              <ProtectedRoute path={'/otpPage'}>
                <OtpPage />
              </ProtectedRoute>
            }
          />
          <Route
            path='/setPassword'
            element={
              <ProtectedRoute path={'/setPassword'}>
                <SetPassword />
              </ProtectedRoute>
            }
          />
          <Route
            path='/adminDashboard'
            element={
              <ProtectedRoute path={'/adminDashboard'}>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path='/adminDashboard/doctors'
            element={
              <ProtectedRoute path={'/adminDashboard/doctors'}>
                <Doctors />
              </ProtectedRoute>
            }
          />
          <Route
            path='/adminDashboard/patients'
            element={
              <ProtectedRoute path={'/adminDashboard/patients'}>
                <Patients />
              </ProtectedRoute>
            }
          />
          <Route
            path='/adminDashboard/pharmacies'
            element={
              <ProtectedRoute path={'/adminDashboard/pharmacies'}>
                <Pharmacies />
              </ProtectedRoute>
            }
          />
          <Route
            path='/adminDashboard/surgeries'
            element={
              <ProtectedRoute path={'/adminDashboard/surgeries'}>
                <Surgeries />
              </ProtectedRoute>
            }
          />
        </Routes>
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default App;
