import { Box, IconButton } from "@mui/material"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { request } from "../API/Request"
import Cancel from '../assets/cancel.svg'
import Confirm from '../assets/confirm.svg'
import Edit from '../assets/edit.svg'
import Remove from '../assets/remove.svg'
import DashboardTextField from "./DashboardTextField"

const Patient = ({ patient, getPatients }) => {

    const { register, handleSubmit, formState: { errors }, reset, setError } = useForm({
        values: patient
    })
    const [editInfo, setEditInfo] = useState(false)

    const confirmEditInfo = async (data) => {
        const response = await request('update-patient-for-admin', 'PUT',
            {
                "patient_id": data.patient_id,
                "phone_number": data.phone_number,
                "email": data.email,
                "password": data.password,
                "age": data.age,
                "gender": data.gender,
                "first_name": data.first_name,
                "last_name": data.last_name,
                "city": data.city,
                "insurance": data.insurance,
                "balance": data.balance
            }
        )
        if (response.success) {
            getPatients()
            setEditInfo(false)
        } else {
            setError('email', { message: response.errorMessage })
        }
    }
    const deletePatient = async () => {
        await request(`delete-patient/${patient.patient_id}`, 'DELETE')
        getPatients()
    }

    return (
        <>
            <Box width={'95%'} display={"flex"} justifyContent={'space-evenly'} alignItems={'center'} marginTop={2}>
                <h2 style={{ marginRight: 5 }}>{patient.patient_id}</h2>
                <DashboardTextField editInfo={editInfo} register={register} name={'first_name'} errors={errors} />
                <DashboardTextField editInfo={editInfo} register={register} name={'last_name'} errors={errors} />
                <DashboardTextField editInfo={editInfo} register={register} name={'email'} errors={errors} />
                <DashboardTextField editInfo={editInfo} register={register} name={'gender'} errors={errors} />
                <DashboardTextField editInfo={editInfo} register={register} name={'age'} errors={errors} />
                <DashboardTextField editInfo={editInfo} register={register} name={'phone_number'} errors={errors} />
                <DashboardTextField editInfo={editInfo} register={register} name={'city'} errors={errors} />
                <DashboardTextField editInfo={editInfo} register={register} name={'insurance'} errors={errors} />
                <DashboardTextField editInfo={editInfo} register={register} name={'balance'} errors={errors} />
                {editInfo ?
                    <>
                        <IconButton onClick={handleSubmit(confirmEditInfo)}>
                            <img src={Confirm} alt="Edit" width={40} />
                        </IconButton>
                        <IconButton onClick={() => { reset(); setEditInfo(false) }}>
                            <img src={Cancel} alt="Edit" width={40} />
                        </IconButton>
                    </>
                    : <>
                        <IconButton onClick={() => { setEditInfo(true) }}>
                            <img src={Edit} alt="Edit" width={40} />
                        </IconButton>
                        <IconButton onClick={() => { deletePatient() }}>
                            <img src={Remove} alt="Remove" width={30} />
                        </IconButton>
                    </>
                }
            </Box>
            {errors.email?.message && <p style={{ marginTop: -15 }}>{errors.email?.message}</p>}
        </>
    )
}

export default Patient