import { Box, Pagination } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { downloadRequest, request } from "../API/Request";
import { AppContext } from "../App";
import Footer from "../components/Footer";
import ResultCard from "../components/ResultCard";
import SearchSection from "../components/SearchSection";

const FindDoctor = () => {
    const { setLoading, md } = useContext(AppContext);
    const resultSectionRef = useRef(null);
    const [doctors, setDoctors] = useState([]);
    const [page, setPage] = useState(1);
    const [results, setResults] = useState(0);
    const [doctorsProfilePictures, setDoctorsProfilePictures] = useState(['', '', '', '', '', '', '', '', '', '']);
    const { i18n } = useTranslation();
    const textDirection = i18n.language === 'ar' ? 'rtl' : 'ltr';
    const location = useLocation();
    const navigate = useNavigate();

    const setProfilePicture = (index, pictureUrl) => {
        setDoctorsProfilePictures((prevPictures) => {
            const updatedProfilePictures = [...prevPictures];
            updatedProfilePictures[index] = pictureUrl;
            return updatedProfilePictures;
        });
    };

    const scrollToTop = () => {
        resultSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const getDoctors = async (pageNumber) => {
        try {
            setLoading(true);
            const response = await request(`get-doctors?page=${pageNumber}`, 'GET');
            setDoctors(response.doctors);
            setResults(response.totalCount);
            const picturePromises = response.doctors.map(async (element, index) => {
                const profilePicture = await downloadRequest(`get-profile-picture/${element.doctor_id}`, 'GET', true);
                if (!profilePicture.error) {
                    setProfilePicture(index, window.URL.createObjectURL(profilePicture));
                }
            });
            await Promise.all(picturePromises);
        } catch (error) {
            console.error('Error fetching doctors:', error);
        } finally {
            setLoading(false);
            scrollToTop();
        }
    };

    const searchDoctors = async (data) => {
        setLoading(true);
        const response = await request('search-doctors', 'POST', {
            fullName: data.fullName,
            insurance: data.insurance,
            speciality: data.speciality,
            city: data.city,
            page: page
        });
        setDoctors(response.doctors);
        setResults(response.totalCount);
        setLoading(false);
        scrollToTop();
    };

    useEffect(() => {
        document.title = 'Weqayah | Find a Doctor';
        const query = new URLSearchParams(location.search);
        setPage(query.get('page') ? parseInt(query.get('page')) : 1)
        const fullName = query.get("fullName");
        const insurance = query.get("insurance");
        const speciality = query.get("speciality");
        const city = query.get("city");

        if (fullName || insurance || speciality || city) {
            const data = { fullName, insurance, speciality, city };
            searchDoctors(data);
        } else {
            getDoctors(page);
        }
    }, [location.search, page]);

    const handleChange = (_, value) => {
        setPage(value);
        navigate(`?page=${value}`, { replace: true });
    };

    return (
        <>
            <SearchSection setDoctors={setDoctors} />
            <div ref={resultSectionRef} />
            <Box textAlign={'center'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} paddingBottom={10} paddingTop={2} dir={textDirection}>
                <Box width={md ? '70%' : '80%'} display={"flex"} marginBottom={2}>
                    <h2>{results} {results === 1 ? 'Result' : 'Results'}</h2>
                </Box>
                {doctors?.map((doctor, index) => (
                    <ResultCard key={index} doctor_id={doctor.doctor_id} profilePicture={doctorsProfilePictures[index]} name={doctor.first_name + ' ' + doctor.last_name} city={doctor.city} fees={doctor.fees} speciality={doctor.speciality} />
                ))}
                <Pagination page={page} count={Math.ceil(results / 10)} sx={{ '.Mui-selected': { backgroundColor: '#B491CB' } }} onChange={handleChange} />
            </Box>
            <Footer />
        </>
    );
};

export default FindDoctor;
