import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TextareaAutosize,
  Typography,
  checkboxClasses,
} from '@mui/material';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { request } from '../API/Request';
import { AppContext } from '../App';
import ScheduleButton from './ScheduleButton';

const ScheduleColumn = ({
  day,
  dayToShow,
  last,
  tabs,
  tabIndex,
  selectedAppointment,
  setSelectedAppointment,
  setData,
  reRender,
  scheduleMode,
}) => {
  const { t, i18n } = useTranslation();
  const { xs, sm, setLoading, role } = useContext(AppContext);
  const [addDialog, setAddDialog] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [applyToAll, setApplyToAll] = useState(true);
  const [appointments, setAppointments] = useState([]);
  const [showAllAppointments, setShowAllAppointments] = useState(
    scheduleMode || false
  );

  useEffect(() => {
    if (scheduleMode) setShowAllAppointments(true);
    else setShowAllAppointments(false);
  }, [scheduleMode]);

  const getAppointments = async () => {
    const response = await request(
      `get-appointments/${day}/${tabs[tabIndex]?.tab_id}`,
      'GET'
    );
    if (response.success) {
      setAppointments(response.data);
    }
  };
  const getAvailableAppointments = async () => {
    const response = await request(
      `get-available-appointments/${day}/${tabs[tabIndex]?.tab_id}`,
      'GET'
    );
    if (response.success) {
      setAppointments(response.data);
    }
  };
  useEffect(() => {
    if (role === 'doctor') getAppointments();
    else getAvailableAppointments();
  }, [day, role, tabIndex, reRender]);

  const addAppointment = async () => {
    if (startTime && endTime) {
      setLoading(true);
      await request('add-appointment', 'POST', {
        date: day,
        tab_id: tabs[tabIndex]?.tab_id,
        patient_name: '',
        booking_status: false,
        applyToAll: applyToAll,
        start_time: startTime,
        end_time: endTime,
      });
      setLoading(false);
      getAppointments();
      setAddDialog(false);
    }
  };
  if (appointments.length < 5) {
    const emptySlotsCount = 5 - appointments.length;
    for (let i = 0; i < emptySlotsCount; i++) {
      appointments.push({ patient_name: '', start_time: null, end_time: null });
    }
  }

  return (
    <>
      <Dialog
        sx={{
          '.MuiPaper-root': {
            backgroundColor: '#F7F7F7',
            borderRadius: 5,
            color: '#36454F',
          },
        }}
        open={addDialog}
        onClose={() => {
          setAddDialog(false);
        }}
      >
        <DialogTitle textAlign={'center'}>
          <h4 style={{ margin: 0 }}>Add Appointment</h4>
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderBottom: 2,
            borderColor: '#B2BEB5',
          }}
        >
          <Box
            width={'100%'}
            display={'flex'}
            flexDirection={xs ? 'row' : 'column'}
            justifyContent={'space-evenly'}
          >
            <Box
              display={'flex'}
              alignItems={'center'}
              flexDirection={'column'}
            >
              <h4>Start Time</h4>
              <TextField
                sx={{
                  width: 150,
                  '&:focus-within fieldset': {
                    borderColor: 'black !important',
                  },
                }}
                type='time'
                onChange={(newTime) => {
                  setStartTime(newTime.target.value);
                }}
              />
            </Box>
            <Box
              display={'flex'}
              alignItems={'center'}
              flexDirection={'column'}
            >
              <h4>End Time</h4>
              <TextField
                sx={{
                  width: 150,
                  '&:focus-within fieldset': {
                    borderColor: 'black !important',
                  },
                }}
                type='time'
                onChange={(newTime) => {
                  setEndTime(newTime.target.value);
                }}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ marginX: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                size='small'
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: '#36454f',
                  },
                }}
                checked={applyToAll}
                onChange={(event) => {
                  setApplyToAll(event.target.checked);
                }}
              />
            }
            label={`Apply to All "days"`}
          />
          <Button
            sx={{
              borderWidth: 2,
              borderRadius: 2,
              backgroundColor: '#B491CB',
              color: 'white',
              fontWeight: 'bold',
              '&:hover': {
                borderWidth: 2,
                borderRadius: 2,
                backgroundColor: '#B491CB',
                fontWeight: 'bold',
              },
            }}
            onClick={() => {
              setAddDialog(false);
            }}
            variant='contained'
          >
            {t('Cancel')}
          </Button>
          <Button
            sx={{
              borderWidth: 2,
              borderRadius: 2,
              backgroundColor: '#228B22',
              fontWeight: 'bold',
              color: 'white',
              '&:hover': {
                borderWidth: 2,
                borderRadius: 2,
                backgroundColor: '#228B22',
                fontWeight: 'bold',
              },
            }}
            onClick={() => {
              addAppointment();
            }}
            variant='contained'
          >
            {t('Add')}
          </Button>
        </DialogActions>
      </Dialog>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                height: 80,
                textAlign: 'center',
                border: 'none',
                fontSize: xs ? 20 : 16,
                fontWeight: 'bold',
                position: 'relative',
                '&:after': {
                  content: "''",
                  position: 'absolute',
                  height: last ? '0%' : '50%',
                  width: 2,
                  right: i18n.language === 'en' && 0,
                  left: i18n.language === 'ar' && 0,
                  top: 25,
                  backgroundColor: 'black',
                },
              }}
            >
              {t(dayToShow)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {appointments
            ?.slice(0, showAllAppointments ? appointments?.length : 5)
            .map((appointment, index) => {
              return (
                <Fragment key={index}>
                  <TableRow>
                    <TableCell
                      sx={{
                        height: 100,
                        padding: 0,
                        border: '4px solid #B491CB',
                        borderInlineEnd: 'none',
                        borderInlineStart: 'none',
                        position: 'relative',
                        '&:after': {
                          content: "''",
                          position: 'absolute',
                          height: last ? '0%' : '50%',
                          width: 2,
                          right: i18n.language === 'en' && 0,
                          left: i18n.language === 'ar' && 0,
                          top: 25,
                          backgroundColor: 'black',
                        },
                      }}
                    >
                      <ScheduleButton
                        startTime={appointment.start_time}
                        endTime={appointment.end_time}
                        type={appointment.type}
                        patientName={appointment.patient_name}
                        selected={selectedAppointment === appointment}
                        appointment={appointment}
                        setSelectedAppointment={setSelectedAppointment}
                        getAppointments={getAppointments}
                        setData={setData}
                      />
                    </TableCell>
                  </TableRow>
                </Fragment>
              );
            })}
          <TableRow>
            <TableCell
              sx={{
                border: 'none',
                display: 'flex',
                flexDirection: sm ? 'row' : 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {!scheduleMode && (
                <Button
                  disabled={appointments?.length <= 5}
                  sx={{
                    width: sm && !scheduleMode ? '49%' : '100%',
                    height: '100%',
                    marginBottom: !sm && 1,
                    fontSize: !xs && 10,
                    backgroundColor: '#B491CB',
                    boxShadow: 'none',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#b583d6',
                      boxShadow: 'none',
                    },
                  }}
                  onClick={() => setShowAllAppointments(!showAllAppointments)}
                  variant='contained'
                >
                  {showAllAppointments ? 'Show Less' : 'Show All'}
                </Button>
              )}
              {role === 'doctor' && !scheduleMode && (
                <Button
                  sx={{
                    width: sm ? '49%' : '100%',
                    height: '100%',
                    backgroundColor: '#008000',
                    '&:hover': { backgroundColor: '#008000' },
                  }}
                  onClick={() => {
                    setAddDialog(true);
                  }}
                  color='error'
                  variant='contained'
                >
                  Add
                </Button>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

const ScheduleTable = ({
  pageIndex,
  tabIndex,
  tabs,
  bookDialog,
  setBookDialog,
  doctor_id,
  setData,
  reRender,
  scheduleMode,
  customDate,
}) => {
  const { currentUser, setLoading, xs, sm } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const navigator = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [selectedAppointment, setSelectedAppointment] = useState({});
  const [description, setDescription] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorDialog, setErrorDialog] = useState(false);
  const [alertDialog, setAlertDialog] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  const getDayLabel = (index) => {
    let currentDate;
    if (customDate) currentDate = new Date(customDate);
    else currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + index);
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return currentDate.toLocaleDateString(undefined, options);
  };
  const getDayLabel2 = (index) => {
    if (index === 0 && !customDate) {
      return 'Today';
    } else if (index === 1 && !customDate) {
      return 'Tomorrow';
    } else {
      let currentDate;
      if (customDate) currentDate = new Date(customDate);
      else currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + index);
      const options = { weekday: 'long', day: 'numeric', month: 'numeric' };
      const arabicLocale = 'ar-JO';
      const englishLocale = 'en-UK';
      return currentDate.toLocaleDateString(
        i18n.language === 'ar' ? arabicLocale : englishLocale,
        options
      );
    }
  };
  const patientBook = async (data) => {
    setLoading(true);
    const response = await request('add-booking', 'POST', {
      booking_date: selectedAppointment.date,
      appointment_id: selectedAppointment.appointment_id,
      booking_start_time: selectedAppointment.start_time,
      booking_end_time: selectedAppointment.end_time,
      patient_id: currentUser?.patient_id ? currentUser?.patient_id : null,
      booking_status: 'Pending',
      description: description,
      doctor_id: doctor_id,
      guest: currentUser?.patient_id ? false : true,
      phone_number: currentUser?.phone_number || data?.phone_number,
    });
    if (response.success) {
      await request('update-appointment', 'PUT', {
        appointment_id: selectedAppointment.appointment_id,
        booking_status: true,
        patient_name:
          data?.patient_name ||
          currentUser?.first_name + ' ' + currentUser?.last_name,
        phone_number: currentUser?.phone_number || data?.phone_number,
      });
    } else {
      setErrorDialog(true);
      setErrorMessage(response.errorMessage);
    }
    setBookDialog(false);
    setAlertDialog(true);
    setLoading(false);
  };

  return (
    <>
      <Dialog
        sx={{
          '.MuiPaper-root': {
            backgroundColor: '#F7F7F7',
            borderRadius: 5,
          },
        }}
        open={alertDialog}
        onClose={() => {
          setAlertDialog(false);
          window.location.reload();
        }}
      >
        <DialogTitle
          sx={{
            borderBottom: 1,
            borderColor: '#B2BEB5',
            color: '#36454F',
            fontSize: 24,
            fontWeight: 'bold',
          }}
        >
          {currentUser.patient_id
            ? 'Please Wait Until the Booking is Confirmed'
            : 'Please wait for a confirmation call'}
        </DialogTitle>
        <DialogActions>
          <Button
            disableElevation
            sx={{ borderRadius: 2, border: '1px solid #838383' }}
            onClick={() => {
              setAlertDialog(false);
              window.location.reload();
            }}
            color='success'
            variant='contained'
          >
            {t('Ok')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={errorDialog}
        onClose={() => {
          setErrorDialog(false);
        }}
      >
        <DialogTitle>{errorMessage}</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setErrorDialog(false);
            }}
            color='error'
            variant='contained'
          >
            {t('Ok')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        sx={{
          '.MuiPaper-root': {
            backgroundColor: '#F7F7F7',
            borderRadius: 5,
          },
        }}
        open={bookDialog}
        onClose={() => {
          setBookDialog(false);
        }}
      >
        <DialogTitle
          textAlign={'center'}
          color={'#36454F'}
          margin={2}
          fontSize={26}
          fontWeight={'bold'}
        >
          {selectedAppointment.appointment_id ? (
            currentUser.patient_id ? (
              <>
                Confirm Booking
                <h4 style={{ margin: 0, fontSize: 20 }}>
                  {`(${selectedAppointment?.date?.slice(
                    0,
                    10
                  )}, ${selectedAppointment?.start_time?.slice(
                    0,
                    5
                  )} To ${selectedAppointment?.end_time?.slice(0, 5)})`}
                </h4>
              </>
            ) : (
              <Box display={'flex'} flexDirection={'column'}>
                <Button
                  disableElevation
                  sx={{
                    color: '#36454F',
                    backgroundColor: '#E2E2E2',
                    border: '2px solid rgba(0, 0, 0, 0.1098)',
                    borderRadius: 3,
                    '&:hover': {
                      backgroundColor: '#E2E2E2',
                    },
                  }}
                  onClick={() => {
                    localStorage.setItem(
                      'redirectURL',
                      JSON.stringify(
                        `${window.location.pathname.slice(1)}?${
                          window.location.href.split('?')[1]
                        }`
                      )
                    );
                    navigator('/login');
                  }}
                  variant='contained'
                >
                  {t('Sign-in')}
                </Button>
                <h4 style={{ textAlign: 'center', margin: 20 }}>or</h4>
                <h4 style={{ textAlign: 'center', margin: 0 }}>
                  Book an appointment as a guest
                </h4>
                <h5 style={{ margin: 0 }}>{`(${selectedAppointment?.date?.slice(
                  0,
                  10
                )}, ${selectedAppointment?.start_time?.slice(
                  0,
                  5
                )} To ${selectedAppointment?.end_time?.slice(0, 5)})`}</h5>
              </Box>
            )
          ) : (
            'Please Select Appointment to Book'
          )}
        </DialogTitle>
        {selectedAppointment.appointment_id &&
          (currentUser.patient_id ? (
            <DialogContent
              sx={{
                borderBottom: 2,
                borderColor: '#B2BEB5',
              }}
            >
              <Grid container alignItems={'center'}>
                <Grid item xs={12} sm={6}>
                  <h3
                    style={{
                      color: '#36454F',
                      margin: 0,
                      textAlign: !xs && 'center',
                      marginBottom: !xs && 12,
                      marginTop: !xs && 12,
                    }}
                  >
                    Description
                  </h3>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  paddingInlineStart={1}
                  display={'flex'}
                  justifyContent={!xs && 'center'}
                >
                  <TextareaAutosize
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    style={{
                      resize: 'none',
                      '&:focused': {
                        borderColor: '#B2BEB5 !important',
                      },
                      '&:hover': {
                        borderColor: '#B2BEB5 !important',
                      },
                      border: '2px solid #B2BEB5',
                      borderRadius: 0,
                      backgroundColor: 'transparent',
                      width: 250,
                      height: 100,
                      fontSize: 20,
                      padding: 10,
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          ) : (
            <DialogContent
              sx={{
                borderBottom: 2,
                borderColor: '#B2BEB5',
              }}
            >
              <Grid container alignItems={'center'}>
                <Grid item xs={12} sm={6}>
                  <h3
                    style={{
                      color: '#36454F',
                      margin: 0,
                      textAlign: !xs && 'center',
                      marginBottom: !xs && 12,
                      marginTop: !xs && 0,
                    }}
                  >
                    Patient Name
                  </h3>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  display={'flex'}
                  justifyContent={!xs && 'center'}
                >
                  <TextField
                    size='small'
                    sx={{
                      '& fieldset': {
                        border: '2px solid #B2BEB5',
                        borderRadius: 0,
                      },
                      '&:focus-within fieldset': {
                        borderColor: '#B2BEB5 !important',
                      },
                      '&:hover fieldset': {
                        borderColor: '#B2BEB5 !important',
                      },
                      '& .MuiFormHelperText-root': {
                        color: 'black',
                      },
                      marginBottom: 1,
                      marginLeft: 1,
                    }}
                    {...register('patient_name', {
                      required: 'Patient Name is required',
                    })}
                    error={!!errors.patient_name}
                  />
                </Grid>
              </Grid>
              {errors.patient_name && (
                <Typography marginBottom={1} color={'error.main'}>
                  {errors.patient_name?.message}
                </Typography>
              )}
              <Grid container alignItems={'center'}>
                <Grid item xs={12} sm={6}>
                  <h3
                    style={{
                      color: '#36454F',
                      margin: 0,
                      textAlign: !xs && 'center',
                      marginBottom: !xs && 12,
                      marginTop: !xs && 12,
                    }}
                  >
                    Phone Number
                  </h3>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  display={'flex'}
                  justifyContent={!xs && 'center'}
                >
                  <TextField
                    size='small'
                    sx={{
                      '& fieldset': {
                        border: '2px solid #B2BEB5',
                        borderRadius: 0,
                      },
                      '&:focus-within fieldset': {
                        borderColor: '#B2BEB5 !important',
                      },
                      '&:hover fieldset': {
                        borderColor: '#B2BEB5 !important',
                      },
                      '& .MuiFormHelperText-root': {
                        color: 'black',
                      },
                      marginBottom: 1,
                      marginLeft: 1,
                    }}
                    {...register('phone_number', {
                      required: 'Phone Number is required',
                    })}
                    error={!!errors.phone_number}
                  />
                </Grid>
              </Grid>
              {/* <MuiTelInput
                sx={{
                  marginBottom: 1,
                  '&:focus-within fieldset': {
                    borderColor: 'black !important',
                  },
                }}
                {...register('phone_number', {
                  validate: () =>
                    !phoneNumber
                      ? 'Phone Number is required'
                      : phoneNumber?.length <= 9
                      ? 'Please enter a valid phone number'
                      : true,
                })}
                error={!!errors.phone_number}
                defaultCountry='JO'
                preferredCountries={['JO']}
                excludedCountries={['IL']}
                flagSize='medium'
                placeholder='Select Your Country'
                value={phoneNumber}
                onChange={(newPhone, country) => {
                  setPhoneNumber(newPhone);
                }}
              /> */}
              {errors.phone_number && (
                <Typography marginBottom={1} color={'error.main'}>
                  {errors.phone_number?.message}
                </Typography>
              )}
              <Grid container alignItems={'center'}>
                <Grid item xs={12} sm={6}>
                  <h3
                    style={{
                      color: '#36454F',
                      margin: 0,
                      textAlign: !xs && 'center',
                      marginBottom: !xs && 12,
                      marginTop: !xs && 12,
                    }}
                  >
                    Description
                  </h3>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  paddingInlineStart={1}
                  display={'flex'}
                  justifyContent={!xs && 'center'}
                >
                  <TextareaAutosize
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    style={{
                      resize: 'none',
                      '&:focused': {
                        borderColor: '#B2BEB5 !important',
                      },
                      '&:hover': {
                        borderColor: '#B2BEB5 !important',
                      },
                      border: '2px solid #B2BEB5',
                      borderRadius: 0,
                      backgroundColor: 'transparent',
                      width: 186,
                      height: 100,
                      fontSize: 20,
                      padding: 10,
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          ))}
        {selectedAppointment.appointment_id ? (
          currentUser.patient_id ? (
            <DialogActions>
              <Button
                disableElevation
                sx={{
                  borderWidth: 2,
                  borderRadius: 2,
                  backgroundColor: '#B491CB',
                  fontWeight: 'bold',
                  '&:hover': {
                    borderWidth: 2,
                    borderRadius: 2,
                    backgroundColor: '#B491CB',
                    fontWeight: 'bold',
                  },
                }}
                onClick={() => {
                  setBookDialog(false);
                }}
                color='error'
                variant='contained'
              >
                {t('Cancel')}
              </Button>
              <Button
                disableElevation
                sx={{ borderRadius: 2, border: '1px solid #838383' }}
                onClick={() => {
                  patientBook();
                }}
                color='success'
                variant='contained'
              >
                {t('Book')}
              </Button>
            </DialogActions>
          ) : (
            <DialogActions>
              <Button
                disableElevation
                sx={{
                  borderWidth: 2,
                  borderRadius: 2,
                  backgroundColor: '#B491CB',
                  fontWeight: 'bold',
                  '&:hover': {
                    borderWidth: 2,
                    borderRadius: 2,
                    backgroundColor: '#B491CB',
                    fontWeight: 'bold',
                  },
                }}
                onClick={() => {
                  setBookDialog(false);
                }}
                color='error'
                variant='contained'
              >
                {t('Cancel')}
              </Button>
              <Button
                disableElevation
                sx={{ borderRadius: 2, border: '1px solid #838383' }}
                onClick={handleSubmit(patientBook)}
                color='success'
                variant='contained'
              >
                {t('Book')}
              </Button>
            </DialogActions>
          )
        ) : (
          <DialogActions
            sx={{
              borderTop: 2,
              borderColor: '#B2BEB5',
            }}
          >
            <Button
              sx={{ borderRadius: 2, border: '1px solid #838383' }}
              onClick={() => {
                setBookDialog(false);
              }}
              color='success'
              variant='contained'
            >
              {t('Ok')}
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Box display={'flex'} width={'100%'}>
        <ScheduleColumn
          scheduleMode={scheduleMode}
          reRender={reRender}
          day={getDayLabel(0 + pageIndex)}
          dayToShow={getDayLabel2(0 + pageIndex)}
          tabIndex={tabIndex}
          tabs={tabs}
          selectedAppointment={selectedAppointment}
          setSelectedAppointment={setSelectedAppointment}
          setData={setData}
        />
        <ScheduleColumn
          scheduleMode={scheduleMode}
          reRender={reRender}
          day={getDayLabel(1 + pageIndex)}
          dayToShow={getDayLabel2(1 + pageIndex)}
          tabIndex={tabIndex}
          tabs={tabs}
          selectedAppointment={selectedAppointment}
          setSelectedAppointment={setSelectedAppointment}
          setData={setData}
        />
        <ScheduleColumn
          scheduleMode={scheduleMode}
          reRender={reRender}
          day={getDayLabel(2 + pageIndex)}
          dayToShow={getDayLabel2(2 + pageIndex)}
          tabIndex={tabIndex}
          tabs={tabs}
          selectedAppointment={selectedAppointment}
          setSelectedAppointment={setSelectedAppointment}
          setData={setData}
          last={!xs}
        />
        {xs && (
          <ScheduleColumn
            scheduleMode={scheduleMode}
            reRender={reRender}
            day={getDayLabel(3 + pageIndex)}
            dayToShow={getDayLabel2(3 + pageIndex)}
            tabIndex={tabIndex}
            tabs={tabs}
            selectedAppointment={selectedAppointment}
            setSelectedAppointment={setSelectedAppointment}
            setData={setData}
            last={!sm}
          />
        )}
        {sm && (
          <ScheduleColumn
            scheduleMode={scheduleMode}
            reRender={reRender}
            day={getDayLabel(4 + pageIndex)}
            dayToShow={getDayLabel2(4 + pageIndex)}
            tabIndex={tabIndex}
            tabs={tabs}
            selectedAppointment={selectedAppointment}
            setSelectedAppointment={setSelectedAppointment}
            setData={setData}
            last={true}
          />
        )}
      </Box>
    </>
  );
};

export default ScheduleTable;
