import {
  alpha,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Switch,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { request } from '../API/Request';
import { AppContext } from '../App';
import NextVisitForm from '../components/NextVisitForm';
import PlanRecordForm from '../components/PlanRecordForm';
import RecordForm from '../components/RecordForm';
import DeleteConfirmation from '../components/DeleteConfirmation';

const File = () => {
  const { file_id, file_name } = useLocation().state;
  const { t, i18n } = useTranslation();
  const textDirection = i18n.language === 'ar' ? 'rtl' : 'ltr';
  const { xs, sm } = useContext(AppContext);
  const [records, setRecords] = useState([]);
  const [planData, setPlanData] = useState({});
  const [recordType, setRecordType] = useState(1);
  const [totalRemainingPrice, setTotalRemainingPrice] = useState(0);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [summaryOutside, setSummaryOutside] = useState(false);

  const getPlanData = async () => {
    const response = await request(
      `get-plan-record-for-historical/${file_id}`,
      'GET'
    );
    if (response.data?.patient_name) {
      response.data.birth_date = response.data.birth_date?.slice(0, 10);
    }
    setPlanData(response.data);
  };
  const getRecords = async () => {
    const response = await request(`get-records/${file_id}`, 'GET');
    const totalRemainingPrice = response.data.reduce(
      (total, record) => total + parseInt(record.price_remaining),
      0
    );
    setTotalRemainingPrice(totalRemainingPrice);
    setRecords(response.data);
  };
  useEffect(() => {
    document.title = `Weqayah | ${file_name}`;
    getRecords();
    getPlanData();
  }, []);

  const deleteFile = async () => {
    const response = await request(`delete-file/${file_id}`, 'DELETE');
    if (response.success) {
      window.history.back();
    }
  };

  return (
    <>
      <DeleteConfirmation
        open={deleteDialog}
        setOpen={setDeleteDialog}
        onClick={deleteFile}
        text={
          'Deleting will permanently remove this file from the system. This cannot be undone!'
        }
      />
      <Box
        position={'relative'}
        backgroundColor={'background.paper'}
        paddingY={5}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Button
          color='secondary'
          onClick={() => setDeleteDialog(true)}
          sx={{
            marginBottom: !sm && 2,
            width: 200,
            height: 50,
            backgroundColor: 'white',
            color: 'black',
            '&:hover': {
              backgroundColor: 'white',
              color: 'black',
              boxShadow: 'none',
            },
          }}
        >
          <h2>Delete File</h2>
        </Button>
        <h1 style={{ color: 'white', fontSize: 40, textAlign: 'center' }}>
          Select Record Type
        </h1>
        <Box
          width={!xs ? 200 : 650}
          display={'flex'}
          flexDirection={!sm && 'column'}
          justifyContent={'space-around'}
          alignItems={'center'}
        >
          <Button
            color='secondary'
            onClick={() => setRecordType(1)}
            sx={{
              marginBottom: !sm && 2,
              width: 200,
              height: 50,
              backgroundColor: 'white',
              color: 'black',
              '&:hover': {
                backgroundColor: 'white',
                color: 'black',
                boxShadow: 'none',
              },
            }}
          >
            <h2>Plan</h2>
          </Button>
          <Button
            color='secondary'
            onClick={() => setRecordType(2)}
            sx={{
              marginBottom: !sm && 2,
              width: 200,
              height: 50,
              backgroundColor: 'white',
              color: 'black',
              '&:hover': {
                backgroundColor: 'white',
                color: 'black',
                boxShadow: 'none',
              },
            }}
          >
            <h2>Next Visit</h2>
          </Button>
          <Button
            color='secondary'
            onClick={() => setRecordType(3)}
            sx={{
              width: 200,
              height: 50,
              backgroundColor: 'white',
              color: 'black',
              '&:hover': {
                backgroundColor: 'white',
                color: 'black',
                boxShadow: 'none',
              },
            }}
          >
            <h2>Historical</h2>
          </Button>
        </Box>
      </Box>
      {recordType === 1 ? (
        <>
          <PlanRecordForm file_id={file_id} />
        </>
      ) : recordType === 2 ? (
        <>
          {planData?.patient_name ? (
            <NextVisitForm
              file_id={file_id}
              medical_history={planData?.medical_history}
            />
          ) : (
            <NextVisitForm file_id={file_id} />
          )}
        </>
      ) : (
        <>
          <Box
            display={'flex'}
            flexDirection={!xs ? 'column' : 'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            paddingX={3}
            paddingTop={7}
            dir={textDirection}
          >
            <h2 style={{ color: 'red', textAlign: 'center' }}>
              {t('Total price remaining')}: {totalRemainingPrice} JD
            </h2>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    sx={{
                      '& .MuiSwitch-switchBase': {
                        color: '#b270dd',
                      },
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        color: '#b270dd',
                      },
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                        {
                          backgroundColor: '#b270dd',
                        },
                    }}
                    checked={summaryOutside}
                    onChange={() => {
                      setSummaryOutside(!summaryOutside);
                    }}
                  />
                }
                label='Show Summaries'
              />
            </FormGroup>
          </Box>
          {planData?.patient_name ? (
            <RecordForm
              summaryOutside={summaryOutside}
              planData={planData}
              file_id={file_id}
              getRecords={getRecords}
            />
          ) : (
            <RecordForm
              summaryOutside={summaryOutside}
              file_id={file_id}
              getRecords={getRecords}
            />
          )}
          <Box
            display={'flex'}
            flexDirection={'column'}
            padding={3}
            dir={textDirection}
          >
            <h2>{t('Other patient records')}</h2>
            {records
              .slice()
              .reverse()
              .map((record, index) => {
                return (
                  <RecordForm
                    key={index}
                    file_id={file_id}
                    summaryOutside={summaryOutside}
                    record={record}
                    getRecords={getRecords}
                    index={records.length - 1 - index + 1}
                  />
                );
              })}
          </Box>
        </>
      )}
    </>
  );
};

export default File;
